/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from "vuex";
import { AccountStatus } from "./types";

export const mutations: MutationTree<AccountStatus> = {
  ACCOUNT_STATUS(state, payload: string) {
    state.accountStatus = payload;
  },

  RESET_ACCOUNT_STATUS(state) {
    state.accountStatus = "active";
  },
};
