/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";

import { RegisterNylas } from "./types";
import { instanceLoggedAPI } from "../axiosConfig";

export default class NylasAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getAccessToken(): Promise<unknown> {
    return this.axiosInstance.get("/vendors/nylas");
  }

  async registerNylas(data: RegisterNylas): Promise<unknown> {
    return this.axiosInstance.post("/vendors/nylas", data);
  }

  async createEvent(data: any): Promise<any> {
    return this.axiosInstance.post("/vendors/nylas/events", data);
  }

  async getLinkStatus(): Promise<any> {
    return this.axiosInstance.get("/vendors/nylas/connections/microsoft_teams");
  }

  async getLinkUrl(): Promise<any> {
    return this.axiosInstance.post(
      "/vendors/nylas/connections/microsoft_teams"
    );
  }

  async saveTeamsInfo(data: any): Promise<any> {
    return this.axiosInstance.put(
      "/vendors/nylas/connections/microsoft_teams",
      data
    );
  }
}

export const nylasAPI = new NylasAPIService();
