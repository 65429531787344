import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-5" }
const _hoisted_2 = { class: "text-center font-semibold text-lg py-3" }
const _hoisted_3 = { class: "flex flex-col w-full justify-between items-center mt-4" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "w-full flex flex-row justify-end items-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.modalTitle), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.job_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.job_id) = $event)),
            options: _ctx.jobs,
            label: "name",
            placeholder: "Select a job",
            reduce: (job) => job.id
          }, null, 8, ["modelValue", "options", "reduce"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            onOnClick: _ctx.onSubmit,
            label: !_ctx.isLoading ? 'Add to this job' : 'Adding to job',
            variant: "contained",
            disabled: !_ctx.job_id || _ctx.isLoading
          }, null, 8, ["onOnClick", "label", "disabled"])
        ])
      ])
    ], 32)
  ]))
}