
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import { useState } from "@/composables/useState";
import * as yup from "yup";
import { createToastAxelerate } from "../../utils/toast";
import DropDown from "@/components/selectDropdown/index.vue";
import Divider from "@/components/divider/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import { useForm, useField } from "vee-validate";

const CloseJob = defineComponent({
  name: "CloseJob",
  emits: ["reload", "close"],
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    DropDown,
    Divider,
  },
  setup(props, context) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const closeJobReason = [
      {
        id: "completed",
        name: "Completed",
      },
      {
        id: "on_hold_by_client",
        name: "On hold by client",
      },
      {
        id: "changed_in_scope",
        name: "Changed in scope",
      },
    ];
    const schema = yup.object({
      reason: yup.string().label("Reason...").required(),
    });
    const fieldErrors = ref([]);
    const { value: reason } = useField("reason");

    const { handleSubmit, meta } = useForm({
      validationSchema: schema,
      initialValues: {
        reason: "",
      },
    });

    const onSubmit = handleSubmit((values) => {
      const job = {
        job_status: values.reason,
      };
      closeJob(job);
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    function closeModal() {
      context.emit("close");
    }

    function closeJob(data: any) {
      setIsLoading(true);
      JobsAPI.deactivateJob(props.id, data)
        .then(async () => {
          createToastAxelerate(
            "Deactivate Job",
            "The job has been deactivate successfully",
            "success"
          );
          context.emit("reload");
          context.emit("close");
          setIsLoading(true);
        })
        .catch((err) => {
          isLoading.value = false;
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Add candidates", errors[0], "danger");
        });
    }

    return {
      data,
      setData,
      isLoading,
      closeJobReason,
      reason,
      meta,
      onSubmit,
      fieldErrors,
      closeModal,
    };
  },
});

export default CloseJob;
