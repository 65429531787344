/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { TimeLineInt } from "./types";
import { TimeLineAPI } from "../../services/timeline/index";

export const actions: ActionTree<TimeLineInt, RootState> = {
  getTimeLine({ commit }, candidateId) {
    return new Promise((resolve, reject) => {
      TimeLineAPI.getTimeLine(candidateId)
        .then((res: any) => {
          resolve(false);
          commit("TIMELINE_FULLFILED", res.data);
        })
        .catch((err) => {
          reject(true);
          commit("TIMELINE_REJECTED", err);
        });
    });
  },
};
