<template>
  <div class="flex flex-row space-x-5 w-full">
    <div class="w-1/2 bg-white shadow rounded-2xl p-5">
      <PersonalDetails />
    </div>
    <div class="w-1/2 bg-white shadow rounded-2xl px-5">
      <LastSubscription />
    </div>
  </div>
  <div class="flex flex-row space-x-5 w-full">
    <div class="w-1/2 bg-white shadow rounded-2xl p-5">
      <ChangePasswordForm />
    </div>
    <div class="w-1/2 bg-white shadow rounded-2xl h-1/2 p-5">
      <CompanyDetails />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ChangePasswordForm from "../changePassword/index.vue";
import PersonalDetails from "../personalDetails/index.vue";
import CompanyDetails from "../companyDetails/index.vue";
import LastSubscription from "../lastSubscription/index.vue";

export default defineComponent({
  name: "PrincipalUserSettings",
  components: {
    ChangePasswordForm,
    PersonalDetails,
    LastSubscription,
    CompanyDetails,
  },
});
</script>
