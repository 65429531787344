<template>
  <button
    class="space-x-3 mx-2 my-3"
    type="button"
    @click="handleChange(value)"
  >
    <svg
      v-if="type === 'na'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      :style="checked ? { fill: '#d3d3d3' } : {}"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <svg
      v-if="type === 'thumbs-down'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      :style="checked ? { fill: '#FFAC66' } : {}"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
      />
    </svg>
    <svg
      v-if="type === 'thumbs-up'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      :style="checked ? { fill: '#B1F68E' } : {}"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
      />
    </svg>
    <svg
      v-if="type === 'star'"
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="14"
        :fill="checked ? '#339801' : '#ffff'"
        stroke="black"
        stroke-width="2"
      />
      <path
        d="M14.3079 4.65797C14.5645 4.0432 15.4355 4.0432 15.6921 4.65797L17.8937 9.93186C17.9962 10.1775 18.2214 10.3505 18.4852 10.3862L24.2876 11.1715C24.9378 11.2595 25.1695 12.081 24.6612 12.4958L20.1809 16.1518C19.9594 16.3326 19.86 16.6235 19.9244 16.902L21.3568 23.0919C21.5119 23.762 20.7583 24.27 20.1953 23.8748L15.4309 20.5309C15.1723 20.3494 14.8277 20.3494 14.5691 20.5309L9.8047 23.8749C9.24167 24.27 8.48807 23.7621 8.64315 23.0919L10.0755 16.902C10.1399 16.6235 10.0404 16.3326 9.81895 16.1518L5.3388 12.4958C4.83048 12.081 5.06222 11.2595 5.7124 11.1715L11.5148 10.3862C11.7786 10.3505 12.0038 10.1775 12.1063 9.93186L14.3079 4.65797Z"
        fill="white"
        stroke="black"
        stroke-width="2"
        stroke-miterlimit="3.3292"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
import { useField } from "vee-validate";
import { defineComponent, toRefs, watchEffect } from "vue";

const CustomRenderRadio = defineComponent({
  props: {
    modelValue: {
      type: null,
    },
    name: String,
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "thumbs-down",
    },
  },
  setup(props) {
    const { name, value } = toRefs(props);
    const {
      checked,
      handleChange,
      setTouched,
      value: val,
    } = useField(name, (value) => !!value, {
      type: "radio",
      checkedValue: value,
    });
    watchEffect(() => {
      if (!checked.value) {
        setTouched(false);
      }
      setTouched(true);
    });
    return {
      checked,
      val,
      handleChange,
    };
  },
});

export default CustomRenderRadio;
</script>
