<template>
  <div class="flex flex-col text-sm border-primary">
    <input
      :id="value"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="$emit('update:modelValue', $event.target.value)"
      class="hidden"
    />
    <label
      :for="value"
      class="flex items-center cursor-pointer"
      :class="fontSize"
    >
      <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey" />
      {{ label }}
    </label>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "RadioButton",
  inheritAttrs: false,
  props: {
    label: { type: String, default: "", required: true },
    modelValue: { default: "", required: true },
    value: { type: String, default: undefined, required: true },
    fontSize: { type: String, default: "text-base" },
  },
  setup(props) {
    const isChecked = computed(() => props.modelValue === props.value);
    return {
      isChecked,
    };
  },
});
</script>

<style scoped>
input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
  background-color: #d9dbe9;
}
input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}
input[type="radio"]:checked + label span {
  background-color: white;
  box-shadow: 0px 0px 0px 4px #8908f9 inset;
}
input[type="radio"]:checked + label {
  color: black;
}
</style>
