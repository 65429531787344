import { MutationTree } from "vuex";
import { JobState } from "./types";

export const mutations: MutationTree<JobState> = {
  WATCH_TITLE_FULLFILED(state, payload: string) {
    state.title = payload;
  },
  RESET_TITLE(state) {
    state.title = "";
  },
  FORM_FULLFILED(state, payload: []) {
    state.data = payload;
  },
};
