
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
const ConfirmAccount = defineComponent({
  name: "ConfirmAccount",
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
});

export default ConfirmAccount;
