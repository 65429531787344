<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        ref="modal-backdrop"
        class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
        v-if="showModal"
      >
        <div
          class="flex items-start justify-center min-h-screen pt-24 text-center"
        >
          <transition
            enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              class="relative bg-white rounded-lg text-left shadow-xl p-5"
              :class="`${size}`"
              role="dialog"
              ref="modal"
              aria-modal="true"
              v-show="showModal"
              aria-labelledby="modal-headline"
            >
              <button @click="closeModal" class="absolute top-4 right-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <slot>I'm empty inside</slot>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import useClickOutside from "../../composables/useClickOutside";
const props = {
  show: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "lg:w-1/3 w-full ",
  },
};
export default defineComponent({
  name: "ReusableModal",
  emits: ["close"],
  props,
  setup(props, context) {
    const showModal = ref(false);
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();
    function closeModal() {
      context.emit("close");
    }
    onClickOutside(modal, () => {
      if (showModal.value === true) {
        closeModal();
      }
    });
    watch(
      () => props.show,
      (show) => {
        showModal.value = show;
      }
    );
    return {
      closeModal,
      showModal,
      modal,
    };
  },
});
</script>
