/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { EvaluationsInterface } from "./types";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";

export const actions: ActionTree<EvaluationsInterface, RootState> = {
  getAllEvaluations({ commit }, obj) {
    return new Promise((resolve, reject) => {
      TalentEvaluationAPI.getAllTemplates(obj.filters, obj.page)
        .then((data: any) => {
          resolve(false);
          commit("EVALUATIONS_FULLFILED", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("EVALUATIONS_REJECTED", err);
        });
    });
  },
};
