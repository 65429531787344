
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getInitials, buildColorStep } from "../../utils/functions";
import { JobsAPI } from "../../services/jobs/index";
import { createToastAxelerate } from "../../utils/toast";
const CardJobCandidate = defineComponent({
  name: "CardJobCandidate",
  emits: ["getCandidates", "reloadCandidates"],
  props: {
    candidates: {
      default: () => [],
      type: Array,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const jobId = route.query.id;
    const addToJob = (id: string) => {
      JobsAPI.postCandidateToJob(jobId, id)
        .then(async () => {
          await context.emit("reloadCandidates");
          await context.emit("getCandidates");
          createToastAxelerate(
            "Add candidate",
            "The candidate has been added to job",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Add candidates", errors[0], "danger");
        });
    };
    return { buildColorStep, getInitials, route, addToJob };
  },
});

export default CardJobCandidate;
