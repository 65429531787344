import { Module } from "vuex";
import { RootState } from "../types";
import { AccountStatus } from "./types";
import { mutations } from "./mutations";

const state = {
  error: "",
  accountStatus: "active",
} as AccountStatus;

export const accountStatus: Module<AccountStatus, RootState> = {
  state,
  mutations,
};
