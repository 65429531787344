export const labelsTitle = [
  "Use common job titles for searchability",
  "Advertise for just one job eg: ‘Nurse’, not ‘nurses’",
  "No general opportunities or events",
];

export const labelsLocation = [
  "Use a specific, full location to attract the most candidates",
];

export const labelsCompany = [
  "Include the industry and function to boost the job’s visibility on some job boards",
];

export const labelsDetails = [
  "Include as many details as possible to boost the job’s performance on some job boards",
];
export const labelSkills = [
  "Add skills to better identify the characteristics of each job",
];

export const labelSalary = [
  "Adding the salary here will improve performance on some job boards. You can also include the salary in the job description",
];

export const labelSalaryType = ["By checking this "];
