
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import { useState } from "../../composables/useState";

const PdfView = defineComponent({
  components: {
    VuePdfEmbed,
  },
  props: {
    file: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup() {
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const pdfRef = ref<InstanceType<typeof VuePdfEmbed>>();
    const [count, setCount] = useState(1);

    const handleDocumentRender = () => {
      setIsLoading(false);
      setCount(pdfRef.value?.pageCount);
    };
    const handlePasswordRequest = (callback: any, retry: any) => {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    };
    return {
      isLoading,
      setIsLoading,
      page,
      setPage,
      handleDocumentRender,
      handlePasswordRequest,
      pdfRef,
      count,
      setCount,
    };
  },
});

export default PdfView;
