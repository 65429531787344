
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, computed, onMounted } from "vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import InviteMember from "../inviteAccountMember/index.vue";
import MembersList from "../memebersList/index.vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Button from "../button/index.vue";
//import VPagination from "@hennge/vue3-pagination";
import Paginator from "@/components/paginator/index.vue";
import { headerMembers } from "../../constants/accountMember";
import "../../styles/pagination.css";

const AccountMemberSection = defineComponent({
  name: "AccountMemberSection",
  components: {
    ReusableModal,
    InviteMember,
    MembersList,
    Loading,
    Button,
    //VPagination,
    Paginator,
  },
  setup() {
    const showModal = ref(false);
    const store = useStore();
    const page = ref(1);
    const membersData = computed(() => store.state.accountMembers.data);
    const companyName = computed(() => store.state.profile.data.company?.name);
    const isLoading = ref(true);
    onMounted(() => getMembers());

    const getMembers = (page = 1) => {
      store
        .dispatch("getAccountMembers", page)
        .then((res) => {
          isLoading.value = res;
        })
        .catch((err) => {
          isLoading.value = err;
        });
    };
    const updateHandler = (e: any) => {
      getMembers(e);
    };
    return {
      showModal,
      membersData,
      isLoading,
      getMembers,
      companyName,
      updateHandler,
      page,
      headerMembers,
    };
  },
});

export default AccountMemberSection;
