
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { getInitials, buildColorStep } from "../../utils/functions";
const LastCandidates = defineComponent({
  name: "LastCandidates",
  props: {
    candidates: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();
    const openJob = (item: any) => {
      router.push(`/jobs/${item.job_id}/job-candidates/${item.id}/Timeline`);
    };
    return { getInitials, buildColorStep, router, openJob };
  },
});

export default LastCandidates;
