
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  computed,
  defineComponent,
  ref,
  watchEffect,
  watch,
  onMounted,
} from 'vue';
import MenuEditUser from '../menuEditUser/index.vue';
import { getInitials, numberWithCommas } from '../../utils/functions';
import FilesSection from '../filesSection/index.vue';
import Chips from '../chips/index.vue';
import DropDownStage from '../dropdownChangeStage/index.vue';
import { JobsAPI } from '../../services/jobs/index';
import TimeLineSection from '../timeLineSection/index.vue';
import { createToastAxelerate } from '../../utils/toast';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import MenuShare from '../menuShare/index.vue';
import ReusableModal from '../reusableModal/index.vue';
import WarningModal from '../warningModal/index.vue';
import { useState } from '../../composables/useState';
import EvaluationsAnswerForm from '../evaluationAnswersForm/index.vue';
import { validStages, validStagesChallenge } from '../../constants/candidates';
import ChallengeTab from '../challengeTab/index.vue';
import Loader from '../loader/index.vue';
import AddCandidateInJob from '../addCandidateInJob/index.vue';
import ModalRejectCandidate from '@/components/modalRejectCandidate/index.vue';

const CandidateInfo = defineComponent({
  name: 'CandidateInfo',
  emits: [
    'openEdit',
    'reloadOne',
    'reloadCandidates',
    'copy',
    'goToPublic',
    'resetSelected',
    'setLoadingStage',
    'deleteCandidate',
    'rejectCandidate',
  ],
  components: {
    MenuEditUser,
    FilesSection,
    Chips,
    TimeLineSection,
    DropDownStage,
    MenuShare,
    ReusableModal,
    EvaluationsAnswerForm,
    WarningModal,
    ChallengeTab,
    Loader,
    AddCandidateInJob,
    ModalRejectCandidate,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    candidateId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const path = route.path;
    const [showModal, setShowModal] = useState(false);
    const showModalCopy = ref(false);
    const isLoading = ref(false);
    const [showRejected, setShowRejected] = useState(false);
    const currentStage = computed(
      () => store.state.candidateInfo?.data?.current_stage
    );
    const stages: any = computed(() => props.userInfo.job_candidate_stages);
    const stageId = stages.value.find(
      (stages: any) => stages.job_stage.stage.name === 'Rejected'
    ).id;

    const changeCurrentStage = (id: string) => {
      isLoading.value = true;
      JobsAPI.updateCandidateStage(id)
        .then(async () => {
          await context.emit('resetSelected');
          store.dispatch('getJobState', route.params.id);
          createToastAxelerate(
            'Stage Candidate',
            'The candidate has been moved to stage successfully .',
            'success'
          );
          isLoading.value = false;
          await context.emit('reloadCandidates');
        })
        .catch((err) => {
          isLoading.value = false;
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            'Stage Candidate',
            errors ? errors[0] : 'An error has ocurred.',
            'danger'
          );
        });
    };
    const openEdit = async () => {
      await context.emit('openEdit');
    };

    const rejectCandidate = async () => {
      setShowRejected(true);
    };
    const copyCandidate = async () => {
      showModalCopy.value = true;
      // await context.emit("copyCandidate");
    };
    const deleteCandidate = async () => {
      await context.emit('deleteCandidate');
    };
    const goToPublic = async () => {
      await context.emit('goToPublic');
    };
    const reloadOne = async () => {
      await context.emit('reloadOne');
    };
    const reloadCandidates = async () => {
      store.dispatch('getJobState', route.params.id);
      await context.emit('resetSelected');
      await context.emit('reloadCandidates');
    };
    const buildOverYears = (year: any, isCurrent: any) => {
      if (isCurrent) {
        return `(Now)`;
      }
      const formatYear = new Date().getFullYear();
      return `(Over ${formatYear - year} Years)`;
    };
    const buildNow = (year: any, isCurrent: any) => {
      if (isCurrent) {
        return `Now`;
      }
      return `${year}`;
    };
    const tabs = ref(['Timeline', 'Profile', 'Files']);
    const activeTab = ref<any>('Timeline');
    const buildConvertions = (mxn: string, usd: string) => {
      return `Salary expectation: $${numberWithCommas(
        parseInt(mxn, 10)
      )} MXN (${numberWithCommas(parseInt(usd, 10))} USD)`;
    };
    const buildConvertionsCurrent = (mxn: string, usd: string) => {
      return `Current salary: $${numberWithCommas(
        parseInt(mxn, 10)
      )} MXN (${numberWithCommas(parseInt(usd, 10))} USD)`;
    };
    const validateLastStage = (name: string, changeId: string) => {
      if (name === 'Hiring') {
        setShowModal(true);
      } else {
        changeCurrentStage(changeId);
      }
    };

    const setLoadingStage = async () => {
      isLoading.value = !isLoading.value;
    };

    const changeCandidateTab = (index: number) => {
      activeTab.value = tabs.value[index];
      router.replace({ params: { tab: activeTab.value } });
    };
    //Hide tabs
    watchEffect(() => {
      if (props?.userInfo?.id) {
        activeTab.value = route.params.tab;
      }
      if (validStages.includes(currentStage.value)) {
        if (validStagesChallenge.includes(currentStage.value)) {
          tabs.value = [
            'Timeline',
            'Profile',
            'Files',
            'Talent evaluation',
            'Challenge',
          ];
        } else {
          tabs.value = ['Timeline', 'Profile', 'Files', 'Talent evaluation'];
        }
      } else {
        tabs.value = ['Timeline', 'Profile', 'Files'];
      }
    });
    const renderDropDown = (stage: string) => {
      switch (stage) {
        case 'Hiring':
          return false;
        case 'Rejected':
          return true;
        default:
          return true;
      }
    };

    onMounted(() => {
      if (props?.userInfo?.id) {
        activeTab.value = route.params.tab;
      }
    });

    watch(
      () => route.params.job_candidate_id,
      () => {
        if (!route.params.tab) {
          router.replace({ params: { tab: 'Timeline' } });
        }
      }
    );

    return {
      buildConvertions,
      buildConvertionsCurrent,
      openEdit,
      copyCandidate,
      getInitials,
      activeTab,
      tabs,
      buildOverYears,
      reloadCandidates,
      buildNow,
      changeCurrentStage,
      numberWithCommas,
      goToPublic,
      setShowModal,
      showModal,
      validateLastStage,
      path,
      renderDropDown,
      reloadOne,
      isLoading,
      setLoadingStage,
      changeCandidateTab,
      showModalCopy,
      deleteCandidate,
      showRejected,
      setShowRejected,
      stageId,
      rejectCandidate,
      currentStage,
    };
  },
});

export default CandidateInfo;
