import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid lg:grid-cols-6 md:grid-cols-2 grid-cols-1 w-full lg:gap-6 gap-1 md:gap-3 pt-5 space-y-2 md:space-y-0 lg:space-y-0" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "flex w-full" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "font-medium text-gray-500" }
const _hoisted_6 = { class: "text-2xl font-semibold text-gray-600 ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildData, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${i}-card-count`,
        class: "bg-white shadow-lg rounded-lg py-4 px-3 space-y-2"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(item.value), 1)
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}