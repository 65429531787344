
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref, watchEffect } from "vue";
import ReusableModal from "../reusableModal/index.vue";
import FilesForm from "../filesForm/index.vue";
import PdfView from "../pdfViewer/index.vue";
import { useStore } from "vuex";
import { useState } from "../../composables/useState";
import Button from "../button/index.vue";
import ResourceViewer from "@/components/resourceViewer/index.vue";

const FilesSection = defineComponent({
  name: "FilesSection",
  components: {
    ReusableModal,
    FilesForm,
    PdfView,
    Button,
    ResourceViewer,
  },
  setup() {
    const showModal = ref(false);
    const [file, setFile] = useState("");
    const [documents, setDocuments] = useState("");
    const store = useStore();
    const candidateId = computed(
      () => store.state.candidateInfo.data?.candidate?.id
    );
    watchEffect(() => {
      if (candidateId.value) {
        setFile(store.state.candidateInfo.data?.candidate.resume?.url);
        setDocuments(
          store.state.candidateInfo.data?.candidate.candidate_documents
        );
      }
    });

    const getFileExtension = (file: any) => {
      if (!file) return "";
      let auxFileName = file.substring(file.lastIndexOf("/") + 1);
      return auxFileName.split(".").pop();
    };

    return {
      showModal,
      candidateId,
      file,
      documents,
      setDocuments,
      getFileExtension,
    };
  },
});
export default FilesSection;
