
import { defineComponent, ref } from "vue";
import EmailChips from "../emailsChips/index.vue";
import { MembersAPI } from "../../services/accountMembers/index";
import { createToastAxelerate } from "../../utils/toast";
import Button from "../button/index.vue";
import CheckBoxGroup from "../checkboxGroup/index.vue";
import { roles } from "../../constants/members";

const InviteMember = defineComponent({
  name: "InviteMember",
  emits: ["closeModal", "reloadMembers"],
  components: {
    EmailChips,
    CheckBoxGroup,
    Button,
  },
  setup(props, context) {
    const emails = ref([]);
    const checkValues = ref([]);
    const handleSubmit = () => {
      const buildPayload = {
        account_members: emails.value,
        roles: ["sourcing_admin"],
      };
      MembersAPI.sendInvite(buildPayload)
        .then(() => {
          createToastAxelerate(
            "Invite Members",
            "The invitation has been send.",
            "success"
          );
          context.emit("closeModal");
          context.emit("reloadMembers");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Invite Members",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    };
    return { emails, handleSubmit, roles, checkValues };
  },
});

export default InviteMember;
