import { MutationTree } from "vuex";
import { CandidatesInt } from "./types";

export const mutations: MutationTree<CandidatesInt> = {
  CANDIDATES_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  RESET_CANDIDATES(state) {
    state.data = [];
  },
  CANDIDATES_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
