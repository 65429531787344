
import { defineComponent, onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Header from "../components/header/index.vue";
import Button from "../components/button/index.vue";
import CardsCount from "../components/cardsCountHome/index.vue";
import CardSuggestions from "../components/cardsSuggestions/index.vue";
import LastCandidates from "../components/lastCandidates/index.vue";
import Loading from "vue-loading-overlay";
import NotificationList from "../components/notificationList/index.vue";
import { NotificationsAPI } from "../services/notifications/index";
import SkeletonNotifications from "../components/skeleton-notifications/index.vue";
import ReusableModal from "@/components/reusableModal/index.vue";
import ModalUpgradePlan from "@/components/modalUpgradePlan/index.vue";

export default defineComponent({
  name: "Home",
  components: {
    Header,
    Button,
    CardsCount,
    CardSuggestions,
    LastCandidates,
    Loading,
    NotificationList,
    SkeletonNotifications,
    ReusableModal,
    ModalUpgradePlan,
  },
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const analytics = computed(() => store.state.analytics.data);
    const userRes = computed(() => store.state.profile.data);
    const router = useRouter();
    const notifications = ref([]);
    const showModal = ref(false);
    const hascompanyPlan = computed(
      () => store.state.profile.data?.company_plan_resources
    );
    const canAddJobs = computed(
      () => store.state.profile.data.company_plan_resources?.can_add_jobs
    );

    onMounted(async () => {
      isLoading.value = true;
      let data = (await NotificationsAPI.dashboardNotifications()) as any;
      notifications.value = data.data.notifications;
      await store.dispatch("getAnalytics");
      isLoading.value = false;
    });

    const createNewJob = () => {
      if (canAddJobs.value || !hascompanyPlan.value) {
        router.push("/create-job");
      } else {
        showModal.value = true;
      }
    };
    return {
      router,
      userRes,
      analytics,
      isLoading,
      notifications,
      canAddJobs,
      createNewJob,
      showModal,
    };
  },
});
