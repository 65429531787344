/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";
export default class ChallengesAPIService {
  private axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }
  async getAllChallenges(filters?: any, page = 1): Promise<unknown> {
    return this.axiosInstance.get(
      `/code_signal/challenges?page=${page}&${makeFilter(filters)}`
    );
  }
  async sendChallengeToCandidate(
    id: string,
    jobCandidateId: string
  ): Promise<unknown> {
    return this.axiosInstance.post(
      `/jobs/${id}/job_candidates/${jobCandidateId}/job_candidate_challenges/send_challenge`
    );
  }
  async resendChallengeToCandidate(id: string): Promise<unknown> {
    return this.axiosInstance.post(`/code_signal/${id}/resend`);
  }
  async getJobChallenges(id: string): Promise<unknown> {
    return this.axiosInstance.get(`/jobs/${id}/job_challenges`)
  }
  async assignChallengeToJob(id: string, payload: any): Promise<unknown> {
    return this.axiosInstance.post(`/jobs/${id}/job_challenges`, payload);
  }
  async updateChallengeToJob(
    id: string,
    payload: any,
    challengeId: string
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${id}/job_challenges/${challengeId}`,
      payload
    );
  }
  async updateChallengeStatus(
    id: string,
    jobCandidateId: string,
    jobChallengeId: string,
    payload: any
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${id}/job_candidates/${jobCandidateId}/job_candidate_challenges/${jobChallengeId}`,
      payload
    );
  }
}
export const ChallengesAPI = new ChallengesAPIService();