/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";

export default class TimeLineAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getTimeLine(candidateId: string): Promise<unknown> {
    return this.axiosInstance.get(`/job_candidate_events/${candidateId}`);
  }

  async getCandidateMeetings(
    jobId: string,
    jobCandidateId: string
  ): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${jobId}/job_candidates/${jobCandidateId}/meeting_urls`
    );
  }

  async createCandidateMeetings(
    jobId: string,
    jobCandidateId: string,
    payload: any
  ): Promise<unknown> {
    return this.axiosInstance.post(
      `/jobs/${jobId}/job_candidates/${jobCandidateId}/meeting_urls`,
      payload
    );
  }

  async getCandidateMeeting(
    jobId: string,
    jobCandidateId: string,
    meetingId: string
  ): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${jobId}/job_candidates/${jobCandidateId}/meeting_urls/${meetingId}`
    );
  }

  async updateCandidateMeeting(
    jobId: string,
    jobCandidateId: string,
    meetingId: string,
    payload: any
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${jobId}/job_candidates/${jobCandidateId}/meeting_urls/${meetingId}`,
      payload
    );
  }

  async deleteCandidateMeeting(
    jobId: string,
    jobCandidateId: string,
    meetingId: string
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${jobId}/job_candidates/${jobCandidateId}/meeting_urls/${meetingId}/deactivate`
    );
  }

  async reSendCandidateMeeting(
    jobId: string,
    jobCandidateId: string,
    meetingId: string
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${jobId}/job_candidates/${jobCandidateId}/meeting_urls/${meetingId}/resend`
    );
  }
}
export const TimeLineAPI = new TimeLineAPIService();
