
import { defineComponent, ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";

const NavBar = defineComponent({
  name: "NavBar",
  setup() {
    const isMenuOpen = ref(false);
    const isMenuOpenMobile = ref(false);
    const store = useStore();

    const profile = store.state.profile?.data;
    const roles = computed(() => store.state.profile.data?.roles);
    const notifications = computed(
      () => store.state.profile.data.unread_notifications_count
    );
    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    const closeMenuMobile = () => {
      isMenuOpen.value = false;
    };

    const changePlatform = () => {
      window.location.replace(process.env.VUE_APP_WORKFORCE_SOLUTIONS_URL);
    };

    onBeforeMount(() => {
      /* if (profile?.first_name) {
        let intercomScript = document.createElement("script");
        intercomScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

        document.head.appendChild(intercomScript);

        (window as any).Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          name: `${profile.first_name} ${profile.last_name}`,
          email: profile.email,
          company: {
            id: profile.company.id,
            name: profile.company.name,
            plan: profile.company.last_subscription?.nickname
              ? profile.company.last_subscription.nickname
              : "",
            website: `${profile.company.company_products[0]} ${
              profile.company.company_products[1] || ""
            }`,
            industry: profile.company.country,
          },
          user_hash: profile.intercom_hash,
        });
      } */
    });

    return {
      isMenuOpen,
      isMenuOpenMobile,
      roles,
      closeMenu,
      closeMenuMobile,
      changePlatform,
      profile,
      notifications,
    };
  },
});
export default NavBar;
