/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { JobCandidateInt } from "./types";
import { JobsAPI } from "../../services/jobs/index";

export const actions: ActionTree<JobCandidateInt, RootState> = {
  getJobCandidatesState({ commit }, data) {
    return new Promise((resolve, reject) => {
      JobsAPI.getJobsCandidates(data.jobId, data.filters)
        .then((data: any) => {
          resolve(false);
          commit("JOB_CANDIDATES_FULLFILED", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("JOB_CANDIDATES_REJECTED", err);
        });
    });
  },
};
