
import { defineComponent } from "vue";

const ScoreBar = defineComponent({
  name: "ScoreBar",
  props: {
    excellent: {
      type: Number,
      default: 0,
    },
    good: {
      type: Number,
      default: 0,
    },
    bad: {
      type: Number,
      default: 0,
    },
  },
});
export default ScoreBar;
