/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export const instanceAuthAPI = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: "application/vnd.axelerate.v1+json",
    "X-Platform": "",
    "Content-Type": "application/json",
  },
});

export const instanceLoggedAPI = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: "application/vnd.axelerate.v1+json",
    "X-Platform": "",
    "Content-Type": "application/json",
  },
});

instanceLoggedAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      cookies.remove("token", "/", ".axelerate.io");
      localStorage.removeItem("token");
      window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
    }
    return Promise.reject(error);
  }
);

instanceLoggedAPI.interceptors.request.use(
  (config: any) => {
    const token = cookies.get("token");
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const instanceFormAPI = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: "application/vnd.axelerate.v1+json",
    "X-Platform": "",
    "Content-Type": "multipart/form-data",
  },
});

instanceFormAPI.interceptors.request.use(
  (config: any) => {
    const token = cookies.get("token");
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
