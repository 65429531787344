import { AxiosInstance } from "axios";
import { instanceAuthAPI, instanceLoggedAPI } from "../axiosConfig";
import { Login, Logout } from "./types";
export default class AuthAPIService {
  private axiosInstance: AxiosInstance;
  private loggedInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceAuthAPI;
    this.loggedInstance = instanceLoggedAPI;
  }

  async login(user: Partial<Login>): Promise<unknown> {
    return this.axiosInstance.post("/sessions", user);
  }

  async logout(token: Partial<Logout>): Promise<unknown> {
    return this.loggedInstance.post("/logout", token);
  }
}
export const AuthAPI = new AuthAPIService();
