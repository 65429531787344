<template>
  <div
    class="
      min-h-screen
      flex flex-col
      items-center
      justify-center
      rounded
      bg-background
    "
  >
    <div
      class="
        flex flex-col
        bg-white
        lg:shadow-md
        px-4
        sm:px-6
        md:px-8
        lg:px-10
        py-8
        rounded-lg
        w-full
        max-w-md
      "
    >
      <img
        src="../../assets/Axelerate_logo.svg"
        alt="login logo"
        class="w-5/12 lg:pb-5"
      />
      <div class="font-bold self-start text-xl sm:text-2xl text-gray">
        Complete your account
      </div>
      <div class="mt-10">
        <div id="test">
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            :initialValues="userInfo"
          >
            <div
              v-for="(item, index) in firstFieldValues"
              class="flex flex-col mb-6"
              :key="index"
            >
              <div class="relative">
                <TextField
                  v-model="formValues[item.name]"
                  :label="item.label"
                  :typeInput="item.type"
                  :disabled="item.disabled"
                  :name="item.name"
                />
              </div>
            </div>
            <div class="flex flex-col mb-6">
              <div class="relative z-0 w-full mb-2">
                <div class="relative">
                  <PasswordField
                    v-model="formValues.password"
                    label="Password"
                    name="password"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="
                flex
                items-center
                justify-center
                focus:outline-none
                text-white text-sm
                sm:text-base
                bg-primary
                rounded-full
                py-2
                w-full
                transition
                duration-150
                ease-in
                h-14
              "
            >
              Complete Account
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";
import { ref, reactive, defineComponent, onMounted } from "vue";
import TextField from "../../components/textField/index.vue";
import PasswordField from "../../components/passwordField/index.vue";
import { MembersAPI } from "../../services/accountMembers/index";
import { createToastAxelerate } from "@/utils/toast";

const CompleteAccount = defineComponent({
  name: "CompleteAccount",
  components: {
    Form,
    TextField,
    PasswordField,
  },
  setup: () => {
    const currentStep = ref(0);
    const formValues = reactive({});
    const router = useRouter();
    const route = useRoute();
    const userInfo = ref({});
    onMounted(() => {
      MembersAPI.getCompleteAccount(route.query.token).then((res) => {
        userInfo.value = res.data;
      });
    });
    const firstFieldValues = [
      {
        name: "first_name",
        label: "First name",
        type: "text",
        disabled: false,
      },
      { name: "last_name", label: "Last name", type: "text", disabled: false },
      { name: "email", label: "Email", type: "email", disabled: true },
    ];
    const schema = yup.object({
      first_name: yup.string().required().max(50).min(3).label("First name"),
      last_name: yup.string().required().max(50).min(3).label("Last name"),
      email: yup.string().required().email(),
      password: yup
        .string()
        .required()
        .matches(
          /[0-9A-Za-z]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?][0-9a-zA-Z]*$/,
          "Must contain at least one uppercase, special character and number"
        ),
    });
    const onSubmit = (values) => {
      const objRequest = {
        user: {
          ...values,
          token: route.query.token,
          password_confirmation: values.password,
        },
      };
      MembersAPI.uploadAccount(userInfo.value.id, objRequest)
        .then(() => {
          createToastAxelerate(
            "Account completed",
            "The user has been activated.",
            "success",
            window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL)
          );
        })
        .catch(() => {
          createToastAxelerate(
            "Account completed",
            "An error has ocurred",
            "danger"
          );
        });
    };
    return {
      currentStep,
      firstFieldValues,
      schema,
      formValues,
      onSubmit,
      userInfo,
    };
  },
});
export default CompleteAccount;
</script>
