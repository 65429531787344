import { Module } from "vuex";
import { RootState } from "../types";
import { IEmailComposerState } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state: IEmailComposerState = {} as IEmailComposerState;

export const emailComposer: Module<IEmailComposerState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
