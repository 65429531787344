
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import ModalUpgradePlan from "@/components/modalUpgradePlan/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import { useStore } from "vuex";

const CardSuggestions = defineComponent({
  name: "CardSuggestions",
  components: {
    ModalUpgradePlan,
    ReusableModal,
  },
  setup() {
    const store = useStore();
    const showModalUpgrade = ref(false);
    const hascompanyPlan = computed(
      () => store.state.profile.data?.company_plan_resources
    ); //true
    const canAddJobs = computed(
      () => store.state.profile.data?.company_plan_resources?.can_add_jobs
    );
    const router = useRouter();

    function editProfile() {
      window.location.replace(process.env.VUE_APP_ACCOUNT_JOBBOARD);
    }

    function inviteAccountMember() {
      window.location.replace(process.env.VUE_APP_ACCOUNT_MEMBERS);
    }

    function addNewJob() {
      if (canAddJobs.value || !hascompanyPlan.value) {
        router.push("/create-job");
      } else {
        showModalUpgrade.value = true;
      }
    }

    function sendToHelpCenter() {
      window.open("http://support.axelerate.io", "_blank");
    }

    return {
      router,
      editProfile,
      inviteAccountMember,
      showModalUpgrade,
      addNewJob,
      sendToHelpCenter,
    };
  },
});

export default CardSuggestions;
