import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table-auto bg-white w-full text-center shadow-sm rounded text-sm" }
const _hoisted_2 = { class: "p-3" }
const _hoisted_3 = { class: "rounded bg-gray-100 px-2 py-1 capitalize" }
const _hoisted_4 = { class: "p-3" }
const _hoisted_5 = { class: "rounded bg-gray-100 px-2 py-1 text-xs" }
const _hoisted_6 = { class: "p-3" }
const _hoisted_7 = { class: "px-2 py-1 rounded bg-black text-white uppercase" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
          return (_openBlock(), _createElementBlock("th", {
            key: header.key,
            class: "border-b-2 border-gray-100 p-4 font-semibold text-gray-500"
          }, _toDisplayString(header.label), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (datum, i) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: `${i + 1}-item-table`,
          class: _normalizeClass(i !== _ctx.data.length - 1 && 'border-b-2 border-gray-100')
        }, [
          _createElementVNode("td", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getService(datum.service)), 1)
          ]),
          _createElementVNode("td", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(datum.date), 1)
          ]),
          _createElementVNode("td", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getAmount(datum.amount)) + " " + _toDisplayString(datum.currency), 1)
          ]),
          _createElementVNode("td", {
            class: _normalizeClass(["font-bold", 
            !datum.invoice ? 'text-gray-400 cursor-not-allowed' : 'text-primary'
          ])
          }, [
            _createElementVNode("a", {
              href: datum.invoice,
              target: "_blank"
            }, "View invoice", 8, _hoisted_8)
          ], 2)
        ], 2))
      }), 128))
    ])
  ]))
}