import { ActionTree } from "vuex";
import { RootState } from "../types";
import { StripesInt } from "./types";
import { StripesAPI } from "../../services/stripes/index";

export const actions: ActionTree<StripesInt, RootState> = {
  getStripes({ commit }) {
    return new Promise((resolve, reject) => {
      StripesAPI.getStripes()
        .then((res: any) => {
          resolve(false);
          commit("STRIPES_FULLFILED", res.data);
        })
        .catch((err) => {
          reject(true);
          commit("STRIPES_REJECTED", err);
        });
    });
  },
};
