
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
  watchEffect,
  watch,
} from "vue";
import { useStore } from "vuex";
import Header from "../../components/header/index.vue";
import {
  getInitials,
  buildColorStep,
  isLater,
  isEmptyOrNull,
} from "../../utils/functions";
import RadioButton from "../../components/radioButton/index.vue";
import SearchInput from "../../components/searchInput/index.vue";
import { checkStage, appDateValues } from "../../constants/candidates";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { removeBlankProperties } from "@/utils/params";
import { JobsAPI } from "../../services/jobs/index";
import SwitchButton from "@/components/switchButton/index.vue";
import Paginator from "@/components/paginator/index.vue";
import "../../styles/pagination.css";
import { useState } from "../../composables/useState";
import Button from "../../components/button/index.vue";
import AddCandidatesForm from "../../components/addCandidateForm/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import SimpleDatePicker from "../../components/simpleDatePicker/index.vue";
import SkillCheckbox from "../../components/skillCheckbox/index.vue";
import { format } from "date-fns";
import CardCandidateDetail from "../../components/cardCandidateDetail/index.vue";
import { SkillsAPI } from "../../services/skills/index";
import ModalUpgradePlan from "@/components/modalUpgradePlan/index.vue";
import { useRouter } from "vue-router";
import RangeSlider from "@/components/sliderRange/index.vue";
import { CandidatesAPI } from "@/services/candidates/index";
import { createToastAxelerate } from "@/utils/toast";
import { optionSearch } from "@/constants/candidates";
import CheckBoxGroup from "../../components/checkboxGroup/index.vue";

const Candidates = defineComponent({
  name: "Candidates",
  components: {
    Header,
    RadioButton,
    SearchInput,
    Loading,
    ModalUpgradePlan,
    Paginator,
    Button,
    AddCandidatesForm,
    ReusableModal,
    SimpleDatePicker,
    CardCandidateDetail,
    SkillCheckbox,
    SwitchButton,
    RangeSlider,
    CheckBoxGroup,
  },
  setup() {
    const radioJob = ref("");
    const radioAppDate = ref("");
    const radioStage = ref("");
    const inputValue = ref("");
    const router = useRouter();
    const store = useStore();
    const candidates = computed(() => store.state.candidates.data);
    const isLoading = ref(false);
    const jobList = ref([]);
    const restrict_attributes = ref([]);
    const [page, setPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [noSkills, setNoSkills] = useState(false);
    const [noSalary, setNoSalary] = useState(false);
    const startDate = ref("");
    const endDate = ref("");
    const [message, setMessage] = useState("");
    const skillsList = ref([]);
    const rangeSalary = ref([0, 0]);
    const rangeValue = ref([2, 30]);
    const dataFiltered = ref(false);
    const selectedSkills = ref([]);
    const showModalUpgrade = ref(false);
    const locationSelected = ref("");
    const [locations, setLocations] = useState([]);
    const userId = computed(() => store.state?.profile.data.id);
    const hascompanyPlan = computed(
      () => store.state.profile.data?.company_plan_resources
    );
    const canAddCandidates = computed(
      () => store.state.profile.data?.company_plan_resources?.can_add_candidates
    );
    const expand = reactive({
      jobs: false,
      stage: false,
      consideration: false,
      applicationDate: false,
      skills: false,
      range: false,
    });
    const filters = ref({});

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
        by_stage_name: radioStage.value,
        by_job: radioJob.value,
        location: locationSelected.value,
      });
    });
    const getCandidates = (newPage = 1) => {
      setPage(newPage);
      store
        .dispatch("getCandidatesIndex", {
          page: page.value,
          filters: {
            search: Object.values(filters.value).join("%26"),
            from_date: isEmptyOrNull(startDate.value, "object")
              ? format(new Date(startDate.value), "yyyy/MM/dd")
              : "",
            to_date: isEmptyOrNull(endDate.value, "object")
              ? format(new Date(endDate.value), "yyyy/MM/dd")
              : "",
            skills: noSkills.value ? "no_skills" : selectedSkills.value,
            salary_range: getSalaryrange(),
            restrict_attributes: restrict_attributes.value,
          },
        })
        .then((res: any) => {
          isLoading.value = res;
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const getSkills = () => {
      SkillsAPI.getSkills().then((res: any) => {
        let skillResponse = res.data.skills.map((obj: any) => obj.name);
        skillsList.value = skillResponse;
      });
    };

    const updateRange = (e: any) => {
      rangeSalary.value = [];
      e.map((el: any) => {
        el = el * 1000;
        if (el <= 0) el = 1;
        rangeSalary.value.push(el);
      });
      dataFiltered.value = true;
    };

    const getSalaryrange = () => {
      let filteredSalary: any = "";
      if (noSalary.value) {
        filteredSalary = "no_salary";
      } else if (!noSalary.value && dataFiltered.value) {
        filteredSalary = rangeSalary.value;
      } else {
        filteredSalary = "";
      }
      return filteredSalary;
    };

    onMounted(async () => {
      isLoading.value = true;
      JobsAPI.getJobList().then((res: any) => {
        jobList.value = res.data;
      });
      await getCandidates();
      await getSkills();
      await getLocations();
    });

    const getLocations = () => {
      CandidatesAPI.getCandidateLocations(userId.value)
        .then((response: any) => {
          setLocations(response.data);
        })
        .catch(() => {
          createToastAxelerate("Get locations", "", "danger");
        });
    };

    const applyFilters = () => {
      if (isLater(startDate.value, endDate.value)) {
        setMessage("The end date must be grater than start date");
        return null;
      } else {
        setMessage("");
        setPage(1);
        isLoading.value = true;
        store
          .dispatch("getCandidatesIndex", {
            page: page.value,
            filters: removeBlankProperties({
              search: Object.values(filters.value).join("%26"),
              from_date: isEmptyOrNull(startDate.value, "object")
                ? format(new Date(startDate.value), "yyyy/MM/dd")
                : "",
              to_date: isEmptyOrNull(endDate.value, "object")
                ? format(new Date(endDate.value), "yyyy/MM/dd")
                : "",
              skills: noSkills.value ? "no_skills" : selectedSkills.value,
              salary_range: getSalaryrange(),
              restrict_attributes: restrict_attributes.value,
            }),
          })
          .then((res: any) => {
            isLoading.value = res;
          })
          .catch(() => {
            isLoading.value = false;
          });
      }
    };

    const clearFilters = () => {
      radioStage.value = "";
      inputValue.value = "";
      radioJob.value = "";
      startDate.value = "";
      rangeValue.value = [10, 10];
      dataFiltered.value = false;
      locationSelected.value = "";
      restrict_attributes.value = [];
      setNoSalary(false);
      endDate.value = "";
      setTimeout(() => {
        applyFilters();
      }, 200);
    };

    const updateHandler = (e: any) => {
      getCandidates(e);
      isLoading.value = true;
    };

    const createCandidate = () => {
      if (canAddCandidates.value || !hascompanyPlan.value) {
        router.push(`/create-candidate`);
      } else {
        showModalUpgrade.value = true;
      }
    };

    watch(locationSelected, (newVal) => {
      applyFilters();
    });

    return {
      applyFilters,
      updateHandler,
      buildColorStep,
      getInitials,
      clearFilters,
      page,
      setPage,
      radioJob,
      radioStage,
      checkStage,
      expand,
      radioAppDate,
      appDateValues,
      inputValue,
      isLoading,
      candidates,
      filters,
      jobList,
      setShowModal,
      showModal,
      getCandidates,
      startDate,
      endDate,
      message,
      skillsList,
      selectedSkills,
      getSkills,
      showModalUpgrade,
      createCandidate,
      canAddCandidates,
      noSkills,
      setNoSkills,
      rangeValue,
      rangeSalary,
      dataFiltered,
      updateRange,
      noSalary,
      setNoSalary,
      locationSelected,
      locations,
      setLocations,
      optionSearch,
      restrict_attributes,
    };
  },
});
export default Candidates;
