import { Module } from "vuex";
import { RootState } from "../types";
import { SubscriptionsInt } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  error: "",
  subscriptions: [],
  payments: [],
} as SubscriptionsInt;

export const subscriptions: Module<SubscriptionsInt, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
