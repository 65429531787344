
import { defineComponent } from "vue";
import { getInitials } from "../../utils/functions";
import MenuMember from "../menuMember/index.vue";
import { MembersAPI } from "../../services/accountMembers/index";
import { createToastAxelerate } from "../../utils/toast";

const MembersList = defineComponent({
  name: "MembersList",
  emits: ["reloadMembers"],
  components: {
    MenuMember,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const deleteMember = (id: string) => {
      MembersAPI.deleteMember(id)
        .then(() => {
          context.emit("reloadMembers");
          createToastAxelerate(
            "Account Members",
            "The member has been removed. ",
            "success"
          );
        })
        .catch(() => {
          createToastAxelerate(
            "Account Members",
            "An error has ocurred. ",
            "danger"
          );
        });
    };
    return { getInitials, deleteMember };
  },
});

export default MembersList;
