
import { defineComponent, ref } from "vue";
import Slider from "@vueform/slider";
export default defineComponent({
  name: "RangeSlider",
  components: {
    Slider,
  },
  props: {
    rangeValuedata: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const rangeValue = ref({
      value: props.rangeValuedata,
      format: function (value: number) {
        return `$${Math.round(value)}k`;
      },
    });

    return { rangeValue };
  },
});
