import { Module } from "vuex";
import { RootState } from "../types";
import { answersInt } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  score: { moderate: 0, strong: 0, not_a_fit: 0 },
  title: "",
  isAnswered: false,
} as answersInt;

export const answers: Module<answersInt, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
