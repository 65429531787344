
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref } from "vue";
import Button from "../button/index.vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import CategoryAnswerArrayField from "../categoryAnswersFieldArray/index.vue";
import Loader from "@/components/loader/index.vue";
import ScoreBar from "../progressBarScore/index.vue";

const EvaluationsAnswer = defineComponent({
  name: "EvaluationsAnswer",
  components: {
    CategoryAnswerArrayField,
    Button,
    ScoreBar,
    Loader,
  },
  props: {
    evaluation: {
      type: Object,
      default: () => Object,
    },
  },
  setup(props) {
    const score = ref({}); // props.evaluation.global_score;
    const title = ref(""); //props.evaluation.evaluation_title;
    const isAnswered = ref(true);
    const loading = ref(false);
    const schema = yup.object({
      evaluation_categories: yup.array().of(
        yup.object().shape({
          name: yup.string().nullable().label("Category title"),
          evaluation_answers: yup.array().of(
            yup.object().shape({
              question_content: yup.string().nullable().label("Question"),
              answer_value: yup
                .string()
                .required("required")
                .label("Answer")
                .nullable(),
              question_id: yup.string().nullable().label("Id"),
            })
          ),
        })
      ),
    });
    const { handleSubmit, values, meta, errors, setValues } = useForm({
      validationSchema: schema,
    });

    async function getAnswerTalent() {
      await setValues({ evaluation_categories: [] });
      const cleanArray = props.evaluation.evaluation_categories?.map(
        (item: any) => {
          delete item.global_score;
          delete item.id;
          item &&
            item?.evaluation_answers.map((ans: any) => {
              return ans && ans.answer_value === null
                ? (ans.answer_value = "na")
                : ans.answer_value;
            });
          return item;
        }
      );
      await setValues({
        evaluation_categories: cleanArray,
      });
      loading.value = false;
    }

    onMounted(() => {
      score.value = props.evaluation.global_score;
      title.value = props.evaluation.evaluation_title;
      getAnswerTalent();
    });

    return {
      score,
      title,
      isAnswered,
      loading,
      getAnswerTalent,
    };
  },
});
export default EvaluationsAnswer;
