/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";

import { emailsAPI } from "@/services/emails";
import { RootState } from "../types";
import { IEmailComposerState } from "./types";

export const actions: ActionTree<IEmailComposerState, RootState> = {
  saveEmailTimelineEvent({ commit }, obj) {
    return new Promise((resolve, reject) => {
      emailsAPI
        .saveEmailTimelineEvent(obj)
        .then((res: any) => {
          resolve(false);
        })
        .catch(() => {
          reject(true);
        });
    });
  },
};
