/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI, instanceFormAPI } from "../axiosConfig";

export default class SkillsAPIService {
  private axiosInstance: AxiosInstance;
  private instanceForm: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.instanceForm = instanceFormAPI;
  }

  async getSkills(): Promise<unknown> {
    return this.axiosInstance.get(`/skills`);
  }
}
export const SkillsAPI = new SkillsAPIService();
