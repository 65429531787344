<template>
  <div class="flex flex-row space-x-3 justify-between">
    <div class="flex flex-row">
      <span class="text-lg font-bold py-3">Personal details</span>
      <div class="pl-1 pt-1">
        <Tooltip message="you could change your info." />
      </div>
    </div>
    <button
      type="button"
      @click="showModal = !showModal"
      class="text-sm font-bold text-primary"
    >
      Edit
    </button>
  </div>
  <div class="pt-3 flex flex-row">
    <div
      class="
        m-1
        mr-2
        w-12
        h-12
        relative
        flex
        justify-center
        items-center
        rounded-full
        bg-primary
        text-xl text-white
        uppercase
      "
    >
      {{
        getInitials(
          `${userRes && userRes.first_name} ${userRes && userRes.last_name}`
        )
      }}
    </div>
    <div class="pl-3 flex flex-col space-y-2">
      <span class="text-xl font-bold">{{
        `${userRes && userRes.first_name} ${userRes && userRes.last_name}`
      }}</span>
      <span class="text-base font-light text-gray-400">{{
        userRes && userRes.email
      }}</span>
    </div>
  </div>
  <div>
    <ReusableModal :show="showModal" @close="showModal = false">
      <form @submit="onSubmit">
        <div class="flex flex-col mb-6">
          <span class="text-xl font-bold py-3">Edit personal details</span>
        </div>
        <div
          v-for="(item, index) in userFields"
          :key="index"
          class="flex flex-col mb-6"
        >
          <div class="relative">
            <TextField
              v-model="item.model"
              :label="item.label"
              :typeInput="item.type"
              :name="item.name"
            />
          </div>
        </div>
        <div class="flex justify-center">
          <Button
            variant="contained"
            type="submit"
            label="Update information"
            :disabled="!meta.dirty || !meta.valid || !meta.touched"
          />
        </div>
      </form>
    </ReusableModal>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useForm, useField } from "vee-validate";
import { useStore } from "vuex";
import Tooltip from "../helpTooltip/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import TextField from "../../components/textField/index.vue";
import * as yup from "yup";
import { getInitials } from "../../utils/functions";
import { userAPI } from "../../services/user/index";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";

const PersonalDetails = defineComponent({
  name: "PersonalDetails",
  components: {
    Tooltip,
    ReusableModal,
    TextField,
    Button,
  },
  setup() {
    const store = useStore();
    const userRes = computed(() => store.state.profile.data);
    const showModal = ref(false);
    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
    });

    const { value: firstName } = useField("first_name");
    const { value: lastName } = useField("last_name");
    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        first_name: userRes.value?.first_name,
        last_name: userRes.value?.last_name,
      },
    });

    const userFields = ref([
      {
        name: "first_name",
        model: firstName,
        label: "First name*",
        type: "text",
      },
      { name: "last_name", model: lastName, label: "Last name*", type: "text" },
    ]);
    const nextUpdate = async () => {
      await store.dispatch("getProfile");
      showModal.value = false;
    };
    const onSubmit = handleSubmit((values) => {
      const data = {
        user: {
          ...values,
        },
      };
      userAPI.updateUser(data, userRes.value?.id).then(() => {
        createToastAxelerate(
          "Personal Info",
          "The info has been updated.",
          "success",
          () => nextUpdate()
        );
      });
    });
    return {
      showModal,
      userFields,
      onSubmit,
      meta,
      userRes,
      getInitials,
    };
  },
});

export default PersonalDetails;
</script>

<style src="./styles.css" scoped></style>
