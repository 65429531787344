/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { CandidatesInt } from "./types";
import { CandidatesAPI } from "../../services/candidates/index";

export const actions: ActionTree<CandidatesInt, RootState> = {
  getCandidatesIndex({ commit }, obj) {
    return new Promise((resolve, reject) => {
      CandidatesAPI.getCandidatesIndex(obj.filters, obj.page)
        .then((data: any) => {
          resolve(false);
          commit("CANDIDATES_FULLFILED", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("CANDIDATES_REJECTED", err);
        });
    });
  },
};
