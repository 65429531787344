
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import BillingTable from "../customTableBilling/index.vue";
import NextBilling from "../nextBilling/index.vue";
import PaymentMethods from "../paymentMethod/index.vue";
import { buildBillingTable } from "../../utils/subscriptions";
import { headerBilling as header } from "../../constants/billing";
import { useState } from "@/composables/useState";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const BillingSection = defineComponent({
  name: "BillingSection",
  components: {
    BillingTable,
    NextBilling,
    PaymentMethods,
    Loading,
  },
  setup() {
    const store = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const subscriptions = computed(() => store.state.stripes.stripes);

    onMounted(() => {
      setIsLoading(true);
      store
        .dispatch("getStripes")
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    });
    return { header, subscriptions, buildBillingTable, isLoading };
  },
});
export default BillingSection;
