
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref, onBeforeMount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ReusableModal from '../../components/reusableModal/index.vue';
import { getInitials, timeAgo } from '../../utils/functions';
import Header from '../../components/header/index.vue';
import AddCandidatesForm from '../../components/addCandidateForm/index.vue';
import DeleteCandidate from '../../components/deleteCandidateAlert/index.vue';
import Button from '../../components/button/index.vue';
import { useState } from '../../composables/useState';
import AddCandidatesInJob from '../../components/addCandidatesToJob/index.vue';
import StageBar from '../../components/stageBar/index.vue';
import JobCandidateCard from '../../components/jobCandidateCard/index.vue';
import SimpleSelect from '../../components/simpleSelect/index.vue';
import { selectStages } from '../../constants/candidates';
import SearchInput from '../../components/searchInput/index.vue';
import Skeleton from '../../components/skeleton/index.vue';
import { createToastAxelerate } from '../../utils/toast';
import { JobsAPI } from '@/services/jobs';
import AddTemplateToJob from '../../components/addTemplateToJob/index.vue';
import AddChallengeToJob from '../../components/addChallengeToJob/index.vue';
import JobMembersSection from '@/components/jobMembersSection/index.vue';
import ModalUpgradePlan from '@/components/modalUpgradePlan/index.vue';

const JobBrowser = defineComponent({
	name: 'JobBrowser',
	emits: ['close', 'closeModal', 'getCandidates'],
	components: {
		Header,
		ReusableModal,
		AddCandidatesForm,
		DeleteCandidate,
		Loading,
		Button,
		AddCandidatesInJob,
		StageBar,
		JobCandidateCard,
		SimpleSelect,
		SearchInput,
		Skeleton,
		AddTemplateToJob,
		AddChallengeToJob,
		JobMembersSection,
		ModalUpgradePlan,
	},
	setup() {
		const formRef = ref<InstanceType<typeof AddCandidatesForm>>();
		// const editRef = ref<InstanceType<typeof EditCandidateForm>>();
		const [showChallenge, setShowChallenge] = useState(false);
		const store = useStore();
		const jobStore = computed(() => store.state.addCanidates.data);
		const userInfo = computed(() => store.state.candidateInfo.data);
		const candidates = computed(
			() => store.state.jobCandidates.data.job_candidates
		);
		const showModalUpgrade = ref(false);
		const hascompanyPlan = computed(
			() => store.state.profile.data?.company_plan_resources
		);
		const canAddCandidates = computed(
			() =>
				store.state.profile.data?.company_plan_resources
					?.can_add_candidates
		);
		const router = useRouter();
		const route = useRoute();
		const stage = ref('');
		const [selected, setSelected] = useState('');
		const isLoading = ref(true);
		const jobId = route.params.id;
		const showModal = ref(false);
		const [showModalEval, setShowModalEval] = useState(false);
		const showDelete = ref(false);
		const toggleInfo = ref(false);
		const selectedUser: any = ref(0);
		const isMenuOpen = ref(false);
		const isMenuOpenChallenge = ref(false);
		const [setShowModal] = useState(false);
		const showOption = ref('');
		const [showSearch, setShowSearch] = useState(false);
		const activeTab: any = ref(route.params.stage);
		const resetSelected = () => (selectedUser.value = 0);
		const isLoadingCandidates = ref(false);
		const loadingChallenge = ref(false);
		const inputValue = ref('');
		const [loadingTemplate, setLoadingTemplate] = useState(false);
		const jobIdPath = ref(route.params.id);
		const getData = async () => {
			isLoadingCandidates.value = true;
			const data = {
				jobId,
				filters: {
					search: inputValue.value,
					by_stage_name: stage.value,
				},
			};
			const getJob = store.dispatch('getJobState', jobId);
			const getCandidates = store.dispatch('getJobCandidatesState', data);
			return await Promise.all([getJob, getCandidates])
				.then((res: any) => {
					isLoading.value = res[0];
					isLoadingCandidates.value = false;
				})
				.catch((err) => {
					isLoading.value = err[0];
					isLoadingCandidates.value = false;
				});
		};

		onBeforeMount(async () => {
			store.commit('RESET_CANDIDATE');
			store.commit('RESET_JOB_CANDIDATES');
			await getData();
		});
		const getFromForm = async () => {
			isLoading.value = true;
			showOption.value = '';
			resetSelected();
			const data = { jobId, filters: { by_stage_name: activeTab.value } };
			const getJob = store.dispatch('getJobState', jobId);
			const getCandidates = store.dispatch('getJobCandidatesState', data);
			return await Promise.all([getJob, getCandidates])
				.then((res: any) => {
					isLoading.value = res[0];
				})
				.catch((err) => {
					isLoading.value = err[0];
				});
		};

		const reloadAllCandidatesStage = () => {
			toggleInfo.value = true;
			isLoading.value = true;
			const data = { jobId, filters: {} };
			showOption.value = '';
			store.dispatch('getJobCandidatesState', data).then((res) => {
				isLoading.value = res;
				store.dispatch('getJobState', route.params.id);
			});
		};

		const reloadCandidate = () => {
			toggleInfo.value = true;
			isLoading.value = true;
			const buildParams = {
				jobId,
				userId: userInfo.value?.id,
			};
			const data = { jobId, filters: {} };
			showOption.value = '';
			store.dispatch('getJobCandidatesState', data);
			store
				.dispatch('getCandidateState', buildParams)
				.then((res) => {
					showOption.value = 'info';
					isLoading.value = res;
				})
				.catch((err) => {
					isLoading.value = err;
				});
		};

		const addAction = (id: any) => {
			router.push({
				name: 'JobCandidateShow',
				params: { job_candidate_id: id },
			});
			selectedUser.value = id;
		};

		const removeEvaluation = () => {
			closeMenu();
			JobsAPI.removeJobEvaluation(jobStore.value.id)
				.then((response) => {
					createToastAxelerate(
						'Remove evaluation',
						"Job's evaluations has been removed",
						'success'
					);
					updateJobInfo();
				})
				.catch((err) => {
					const {
						response: {
							data: { errors },
						},
					} = err;
					createToastAxelerate(
						'Remove evaluation',
						errors
							? errors[0]
							: `Job's evaluations can't be removed`,
						'danger'
					);
				});
		};

		const updateEvaluation = () => {
			closeMenu();
			if (jobStore.value?.has_evaluation) {
				JobsAPI.removeJobEvaluation(jobStore.value.id)
					.then((response) => {
						setShowModalEval(true);
						setLoadingTemplate(true);
					})
					.catch((err) => {
						const {
							response: {
								data: { errors },
							},
						} = err;
						createToastAxelerate(
							'Update evaluation',
							"Job's evaluations can't be updated",
							'danger'
						);
					});
			} else {
				setShowModalEval(true);
			}
		};

		const openTemplate = (id: string) => {
			setShowModal(true);
		};

		const closeInForm = async () => {
			await formRef?.value?.resetFormOutside();
			showModal.value = false;
		};

		const closeEdit = () => {
			showOption.value = 'info';
		};

		const openEdit = () => {
			showOption.value = 'edit';
		};

		const openDelete = () => {
			showDelete.value = true;
		};

		const closeMenu = () => {
			isMenuOpen.value = false;
			isMenuOpenChallenge.value = false;
		};

		const changeStage = (index: string) => {
			activeTab.value = index;
			router.push({
				name: 'JobCandidates',
				params: {
					id: jobId,
					stage: index,
				},
			});
			const data = { jobId, filters: {} };
			store.dispatch('getJobCandidatesState', data);
		};

		const handleCandidateClick = (id: string) => {
			router.push({
				name: 'JobCandidateShow',
				params: { job_candidate_id: id },
			});
			setSelected(id);
		};

		const updateJobInfo = () => {
			store
				.dispatch('getJobState', jobId)
				.then((response) => {
					setLoadingTemplate(false);
				})
				.catch((error) => {
					loadingChallenge.value = false;
					setLoadingTemplate(false);
				});
		};

		const updateChallenge = () => {
			isMenuOpenChallenge.value = false;
			setShowChallenge(true);
		};

		const removeChallenge = () => {
			isMenuOpenChallenge.value = false;
			loadingChallenge.value = true;
			JobsAPI.removeJobChallenge(jobStore.value.id)
				.then((response) => {
					loadingChallenge.value = false;
					updateJobInfo();
					createToastAxelerate(
						'Remove Challenge',
						`Job's evaluations has been removed`,
						'success'
					);
				})
				.catch((err) => {
					loadingChallenge.value = false;
					const {
						response: {
							data: { errors },
						},
					} = err;
					createToastAxelerate(
						'Remove evaluation',
						errors ? errors[0] : `Job's challenge can't be removed`,
						'danger'
					);
				});
		};

		const EditJob = () => {
			router.push(`/edit-job/${route.params.id}`);
		};

		watch(
			() => stage.value,
			() => {
				getData();
			}
		);

		const createCandidate = () => {
			if (canAddCandidates.value || !hascompanyPlan.value) {
				router.push(`/create-candidate/${jobId}`);
			} else {
				showModalUpgrade.value = true;
			}
		};

		return {
			isLoadingCandidates,
			userInfo,
			activeTab,
			candidates,
			jobStore,
			getInitials,
			showModal,
			setShowModal,
			showDelete,
			toggleInfo,
			formRef,
			addAction,
			route,
			closeEdit,
			reloadCandidate,
			reloadAllCandidatesStage,
			closeInForm,
			getFromForm,
			selectedUser,
			isMenuOpen,
			isLoading,
			openEdit,
			openDelete,
			showOption,
			changeStage,
			resetSelected,
			timeAgo,
			inputValue,
			getData,
			setShowSearch,
			showSearch,
			selected,
			setSelected,
			handleCandidateClick,
			stage,
			selectStages,
			closeMenu,
			updateEvaluation,
			setShowModalEval,
			showModalEval,
			removeEvaluation,
			setLoadingTemplate,
			loadingTemplate,
			updateJobInfo,
			isMenuOpenChallenge,
			openTemplate,
			showChallenge,
			setShowChallenge,
			updateChallenge,
			removeChallenge,
			loadingChallenge,
			jobId,
			jobIdPath,
			EditJob,
			showModalUpgrade,
			createCandidate,
		};
	},
});

export default JobBrowser;
