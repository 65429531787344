
import { defineComponent } from "vue";
import Button from "../button/index.vue";

const ResourceViewer = defineComponent({
  name: "ResourceViewer",
  component: {
    //Button,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const getFileName = (file: any) => {
      if (!file) return "";
      let auxFileName = file.substring(file.lastIndexOf("/") + 1);
      return auxFileName.substr(0, auxFileName.lastIndexOf("."));
    };

    const getFileExtension = (file: any) => {
      if (!file) return "";
      let auxFileName = file.substring(file.lastIndexOf("/") + 1);
      return auxFileName.split(".").pop();
    };

    const getFileIcon = (type: string) => {
      switch (type) {
        case "pdf":
          return require("@/assets/svgs/pdf.png");
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
          return require("@/assets/svgs/img.png");
        case "xls":
        case "xlsx":
          return require("@/assets/svgs/xls.png");
        case "doc":
        case "docx":
          return require("@/assets/svgs/doc.png");
        default:
          return require("@/assets/svgs/file.png");
      }
    };

    return {
      getFileName,
      getFileExtension,
      getFileIcon,
    };
  },
});
export default ResourceViewer;
