
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref, onBeforeMount } from "vue";
import { useRoute,useRouter } from "vue-router";
import { JobsAPI } from "@/services/jobs/index";
import { createToastAxelerate } from "@/utils/toast";

const ConfirmManagedJob = defineComponent({
  name: "ConfirmManagedJob",
  components: {
   
  },
  setup() {
   const route = useRoute();
   const router = useRouter();
   const jobId:any = computed(() =>route.params.jobId);
   const confirmation = route.params.confirm === 'true' ? true: false;
   

   let payload = {
      confirmation: confirmation
    };

    JobsAPI.managedJobConfirmation(jobId.value, payload).then((response) =>{
        createToastAxelerate('Confirm Managed Job', `The managed job service has been ${confirmation ? 'Accepted' :'Declined'}`, 'success')
        router.push(`/jobs/${jobId.value}/details`);
    }).catch((error) =>{
        createToastAxelerate('Confirm Managed Job', 'An error has ocurred', 'danger');
    })


  },
});

export default ConfirmManagedJob;
