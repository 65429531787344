
import { defineComponent } from "vue";
import { FieldArray } from "vee-validate";
import TextField from "../textField/index.vue";
import RenderCheckBox from "../renderCheckbox/index.vue";
import CustomDatePicker from "../datePicker/index.vue";

const EducationArrayField = defineComponent({
  name: "EducationArrayField",
  props: {
    primaryColor: {
      type: String,
      default: "#7E24ED",
    },
  },
  components: {
    FieldArray,
    TextField,
    RenderCheckBox,
    CustomDatePicker,
  },
  setup() {
    return {};
  },
});

export default EducationArrayField;
