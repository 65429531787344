
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref } from "vue";

import Button from "../button/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import { useState } from "../../composables/useState";
import { buildJobs } from "../../utils/jobs";
import { createToastAxelerate } from "../../utils/toast";

const AddCandidateInJob = defineComponent({
  name: "AddCandidateInJob",
  components: {
    Button,
  },
  props: {
    modalTitle: {
      type: String,
      required: false,
      default: "Add candidate to a Job",
    },
    candidateId: {
      type: String,
      required: true,
    },
  },
  emits: ["closeModal", "reloadCandidate"],
  setup(props, context) {
    const isLoading = ref(false);
    const [jobs, setJobs] = useState([]);
    const job_id = ref(null);

    const onSubmit = () => {
      isLoading.value = true;
      JobsAPI.postCandidateToJob(job_id.value, props.candidateId)
        .then(async () => {
          createToastAxelerate(
            "Add candidate",
            "The candidate has been added to job",
            "success"
          );
          await context.emit("closeModal");
          await context.emit("reloadCandidate");
          isLoading.value = false;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Add candidates", errors[0], "danger");
          isLoading.value = false;
        });
    };
    onMounted(() => {
      JobsAPI.getJobList().then((res: any) => {
        setJobs(buildJobs(res.data));
      });
    });
    return { onSubmit, jobs, job_id, isLoading };
  },
});

export default AddCandidateInJob;
