<template>
  <div class="rounded shadow-sm bg-white justify-center block p-2">
    <div class="flex justify-start">
      <span class="py-2 font-semibold flex justify-start">
        Next Billing Date
      </span>
      <div class="pl-2">
        <Tooltip message="info for next billing" />
      </div>
    </div>

    <span class="text-xl font-extrabold flex justify-start p-2"
      >${{ amount }} {{ country === "usa" ? "USD" : "MXN" }}</span
    >
    <span class="text-md text-gray-300 font-medium flex justify-start p-2"
      >On {{ date }}</span
    >
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import Tooltip from "../helpTooltip/index.vue";
import { useStore } from "vuex";
import { numberWithCommas } from "../../utils/functions";

const NextBilling = defineComponent({
  name: "NextBilling",
  components: {
    Tooltip,
  },
  setup() {
    const store = useStore();
    const country = computed(() => store.state.profile.data.company?.country);
    const amount = computed(
      () =>
        numberWithCommas(
          store.state.profile.data.company?.last_subscription.amount / 100
        ) + ".00"
    );
    const date = computed(
      () => store.state.profile.data.company?.last_subscription.end_date
    );

    return {
      country,
      amount,
      numberWithCommas,
      date,
    };
  },
});

export default NextBilling;
</script>
