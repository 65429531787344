
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CreateJobForm from "../../components/createJobForm/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import { createToastAxelerate } from "../../utils/toast";
import JobStepper from "../../components/jobStepper/index.vue";

const EditJob = defineComponent({
  name: "EditJob",
  components: {
    JobStepper,
  },
  setup() {
    const store = useStore();
    const formRef = ref<InstanceType<typeof CreateJobForm>>();
    const jobTitle = computed(() => store.state.createJob.title);
    const stages = computed(() => store.state.jobStages.data);
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);

    const submitForm = async (jobStatus: number) => {
      const res: any = await formRef.value?.onSubmit();
      const newResult = {
        job: {
          ...res,
          job_status: jobStatus,
          payroll_id: null,
          stages: stages.value,
        },
      };
      await JobsAPI.editJob(newResult, route.params.id)
        .then(() => {
          createToastAxelerate(
            "Edit Job",
            "The job has been saved.",
            "success"
          );
          // Change this route to proper route params
          router.push(`/jobs/${route.params.id}/details`);
        })
        .catch((err) => {
          const {
            respose: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Edit Job", errors[0], "danger");
        });
    };

    const openWorkFlow = async () => {
      formRef.value?.openModal();
    };

    return {
      jobTitle,
      formRef,
      submitForm,
      stages,
      openWorkFlow,
      isLoading,
    };
  },
});

export default EditJob;
