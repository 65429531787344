
import { defineComponent } from "vue";
import { JobsAPI } from "../../services/jobs/index";
import { useRoute } from "vue-router";
import { createToastAxelerate } from "../../utils/toast";

const DeleteCandidate = defineComponent({
  name: "DeleteCandidate",
  emits: ["closeModal", "getCandidates"],
  props: {
    candidateId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const closeModal = () => {
      context.emit("closeModal");
    };
    const remove = () => {
      JobsAPI.deleteJobCanidate(route.params.id, props.candidateId)
        .then(() => {
          context.emit("closeModal");
          context.emit("getCandidates");
          createToastAxelerate(
            "Remove Candidate",
            "The candidate has been removed successfully.",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Remove Candidate", errors[0], "danger");
        });
    };
    return {
      closeModal,
      remove,
    };
  },
});

export default DeleteCandidate;
