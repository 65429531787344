
import {
  defineComponent,
  PropType,
  ref,
  Ref,
  watch,
  onMounted,
  reactive,
  computed,
} from "vue";
import { JobsAPI } from "@/services/jobs";
import { createToastAxelerate } from "@/utils/toast";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "ModalJobMember",
  components: {},
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      default: "",
    },
  },
  emits: ["update:openModal", "reloadMembers"],
  setup(props, context) {
    const companyJob = ref();
    const store = useStore();
    const jobMembers: any = ref([]);
    const memberRole = ref("");
    const jobMember: any = ref("");
    const optionMembers = ref([]);
    const auxId = ref();
    const route = useRoute();
    const companyId = computed(() => store.state.profile.data?.company?.id);

    onMounted(() => {
      getJobMembers();
      getListMembers();
    });

    const getJobMembers = async () => {
      await JobsAPI.getJobMembers(props.jobId)
        .then((response: any) => {
          jobMembers.value = response.data.job.job_members;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Get job members", errors[0], "danger");
        });
    };

    const onSelectChange = (e: any, memberId: any) => {
      let role = e.target.value;
      updateMemberRole(role, memberId);
    };

    const updateMemberRole = (role: any, member_id: string) => {
      let payload = {
        job_member: {
          role: role,
        },
      };

      JobsAPI.updateJobMember(props.jobId, member_id, payload)
        .then((res: any) => {
          createToastAxelerate("User updated successfully", "", "success");
          context.emit("reloadMembers");
        })
        .catch((err: any) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Delete job member", errors[0], "danger");
        });
    };

    const addMember = async () => {
      let payload = {
        id: jobMember.value,
        role: memberRole.value,
      };
      const data = {
        job_member: {
          users: [payload],
        },
      };

      await JobsAPI.addJobMembers(data, props.jobId)
        .then(async (response) => {
          createToastAxelerate("Job member added", "", "success");
          await getJobMembers();
          context.emit("reloadMembers");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Add job member", errors[0], "danger");
        });
      jobMember.value = "";
      memberRole.value = "";
    };

    const deleteJobMember = (id: string) => {
      auxId.value = id;
      JobsAPI.removeJobMember(props.jobId, id)
        .then(async (response) => {
          createToastAxelerate("Job member removed", "", "success");
          await getJobMembers();
          await context.emit("reloadMembers");
          auxId.value = null;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Delete job member", errors[0], "danger");
        });
    };

    const getListMembers = async () => {
      JobsAPI.jobMemberList(companyId.value)
        .then((response: any) => {
          optionMembers.value = response.data.users;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Get members lists", errors[0], "danger");
        });
    };

    const loading: Ref<boolean> = ref(false);

    function closeModal() {
      context.emit("update:openModal", false);
    }
    return {
      jobMember,
      loading,
      closeModal,
      companyJob,
      jobMembers,
      memberRole,
      optionMembers,
      deleteJobMember,
      onSelectChange,
      addMember,
      auxId,
    };
  },
});
