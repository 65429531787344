
import { defineComponent } from "vue";
import { getInitials } from "../../utils/functions";

const Avatar = defineComponent({
  name: "Avatar",
  props: {
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
  },
  setup() {
    const buildSize = (size: string) => {
      switch (size) {
        case "xxs":
          return "text-xs w-8 h-8";
        case "xs":
          return "text-xs w-10 h-10";
        case "s":
          return "text-sm w-12 h-12";
        case "m":
          return "text-sm w-14 h-14";
        case "l":
          return "w-16 h-16";
        case "xl":
          return "text-xl w-20 h-20";
        case "2xl":
          return "text-2xl w-24 h-24";
        case "3xl":
          return "text-3xl w-28 h-28";
        default:
          return "text-xs w-8 h-8";
      }
    };
    return { getInitials, buildSize };
  },
});
export default Avatar;
