import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home bg-background" }
const _hoisted_2 = { class: "bg-white" }
const _hoisted_3 = { class: "container bg-background" }
const _hoisted_4 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_AddCandidateGeneralForm = _resolveComponent("AddCandidateGeneralForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AddCandidateGeneralForm, {
          typeForm: _ctx.typeForm,
          saveCandidate: _ctx.saveCandidate,
          onOnValidForm: _ctx.onValidForm,
          onOnError: _ctx.onError,
          onIsLoadingAction: _ctx.isLoadingAction
        }, null, 8, ["typeForm", "saveCandidate", "onOnValidForm", "onOnError", "onIsLoadingAction"])
      ])
    ])
  ]))
}