
import { defineComponent, ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router"
import MenuDropDown from "../menuDropdown/index.vue";
import NavBar from "../navBar/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import BaseModal from "@/components/baseModal/index.vue";
import { usePromisedModal } from "../../composables/usePromisedModal";

const Header = defineComponent({
  name: "Header",
  emits: ["logOut"],
  components: {
    MenuDropDown,
    NavBar,
    BaseModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const token = computed(() => store.state.auth.token);
    const profile = computed(() => store.state.profile.data);
    const accountStatus = computed(() => store.state.accountStatus);
    const user = ref({});
    const showModal = ref(false);
    const confirmationModal = usePromisedModal<boolean>();
    const unreadNotifications = computed(
      () => store.state.profile.data?.unread_notifications_count
    );
    const planLimits = computed(() => store.state.analytics.data)

    const paywallRoutes = [
      "Home",
      "Jobs",
      "Candidates",
      "Talent Evaluation",
      "Notifications",
    ];

    const blockedRoutes = ["JobDetails", "Candidate Show", "Edit Job"];

    onBeforeMount(async () => {
      await store.dispatch("getProfile");
      await store.dispatch("getAnalytics");
      /* if (
        accountStatus.value.accountStatus === "blocked" &&
        blockedRoutes.includes(String(route.name))
      ) {
        router.push("/");
      } */
      upgradeModal();
    });

    const upgradeModal = async () => {
      if (
        accountStatus.value.accountStatus === "blocked" &&
        paywallRoutes.includes(String(route.name))
      ) {
        /* showModal.value = true;
        const confirmed = await confirmationModal.ask();

        if (confirmed) {
          window.location.replace(process.env.VUE_APP_UPGRADE_PLAN);
        }

        showModal.value = false; */
      }
    };

    const logOut = () => {
      store
        .dispatch("logout", { token: token.value })
        .then(async () => {
          await store.commit("RESET_PROFILE");
          window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
        })
        .catch(() => {
          createToastAxelerate("Log out", "An Error has ocurred.", "danger");
        });
    };
    return {
      user,
      showModal,
      logOut,
      profile,
      router,
      unreadNotifications,
      confirmationModal,
      planLimits,
    };
  },
});

export default Header;
