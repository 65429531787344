<template>
  <div>
    <button class="space-x-3" type="button" @click="handleChange(value)">
      <i
        class="fa"
        :class="{
          [`fa-lg fa-check-circle text-primary`]: checked,
          [`fa-lg fa-circle text-gray-300`]: !checked,
        }"
      />
      <span class="text-sm">{{ label }}</span>
    </button>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: null,
    },
    name: String,
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
  setup(props) {
    const { checked, handleChange } = useField(props.name, undefined, {
      type: "checkbox",
      checkedValue: !checked,
      uncheckedValue: !checked,
    });

    return {
      checked,
      handleChange,
    };
  },
});
</script>
