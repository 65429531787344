
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import { ChallengesAPI } from "../../services/challenges/index";
import { enUS } from "date-fns/locale";
import { format } from "date-fns";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Skeleton from "../skeleton/index.vue";

const ChallengeTab = defineComponent({
  name: "ChallengeTab",
  emits: ["reloadOne"],
  components: {
    Button,
    Skeleton,
  },
  props: {
    candidateId: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    isSent: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
      resend: {
        type: Boolean,
        default: false,
      },
    },
  },
  setup(props: any, context: any) {
    const validStatus = ["started", "over"];
    const isLoadingButton = ref(false);
    const route = useRoute();
    const isLoading = ref(false);

    const sendChallenge = () => {
      isLoading.value = true;
      let jobId = String(route.params.id);
      isLoadingButton.value = true;
      ChallengesAPI.sendChallengeToCandidate(jobId, props.candidateId)
        .then(async () => {
          await context.emit("reloadOne");
          await createToastAxelerate(
            "Send challenge",
            "The challenge has been sent",
            "success"
          );
          isLoadingButton.value = false;
          await context.emit("reloadOne");
          isLoading.value = false;
        })
        .catch((err: any) => {
          isLoadingButton.value = false;
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Assign challenge",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
          isLoading.value = false;
        });
    };

    const resendChallenge = () => {
      isLoadingButton.value = true;
      ChallengesAPI.resendChallengeToCandidate(props.candidateId)
        .then(async () => {
          await context.emit("reloadOne");
          await createToastAxelerate(
            "Re-send challenge",
            "The challenge has been re-sent",
            "success"
          );
          isLoadingButton.value = false;
        })
        .catch((err: any) => {
          isLoadingButton.value = false;
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Assign challenge",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const updateChallengStatus = (e: any, data: any) => {
      isLoading.value = true;
      let payload = {
        challenge_status: e.target.value,
      };
      let jobId = String(route.params.id);
      let jobChallengeId = data.id;

      ChallengesAPI.updateChallengeStatus(
        jobId,
        props.candidateId,
        jobChallengeId,
        payload
      )
        .then(async () => {
          await context.emit("reloadOne");
          await createToastAxelerate(
            "Re-send challenge",
            "The challenge has been re-sent",
            "success"
          );
          isLoading.value = false;
        })
        .catch((err: any) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Assign challenge",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
          isLoading.value = false;
        });
    };

    return {
      enUS,
      format,
      isLoading,
      validStatus,
      sendChallenge,
      resendChallenge,
      isLoadingButton,
      updateChallengStatus,
    };
  },
});

export default ChallengeTab;
