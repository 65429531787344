<template>
  <FieldArray :name="name" v-slot="{ fields, push, remove }">
    <div
      class="space-y-4 flex flex-col"
      v-for="(field, idx) in fields"
      :key="field.key"
    >
      <div class="flex flex-row space-x-4 w-full bg-white justify-between p-3">
        <div class="w-11/12">
          <TextField
            custom
            customClass="bg-white"
            required
            label="Please fill a question... "
            :name="`${name}[${idx}].content`"
            :isError="errors && Boolean(errors[`${name}[${idx}].content`])"
          />
        </div>
        <div class="flex flex-row w-1/12 space-x-3 justify-center">
          <button
            class="rounded"
            v-if="field.isLast"
            type="button"
            @click="
              push({
                content: '',
              })
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-primary hover:bg-gray-100"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <button type="button" @click="remove(idx)" v-if="!field.isFirst">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-red-500 hover:bg-gray-100"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </FieldArray>
</template>

<script>
import { defineComponent } from "vue";
import { FieldArray } from "vee-validate";
import TextField from "../textField/index.vue";

const QuestionArrayField = defineComponent({
  name: "QuestionArrayField",
  props: {
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    FieldArray,
    TextField,
  },
});

export default QuestionArrayField;
</script>
