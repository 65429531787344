<template>
  <div
    class="
      chip-container
      w-full
      flex flex-wrap
      content-center
      bg-gray-100
      rounded-xl
      px-2
      py-4
    "
    :class="chips.length > 0 && 'border-2 border-black'"
  >
    <div
      class="
        chip
        flex
        items-center
        m-1
        rounded-full
        px-2
        py-1
        text-md
        bg-primary-light
        text-primary-dark
      "
      v-for="(chip, i) in chips"
      :key="chip"
    >
      {{ chip }}
      <i
        :class="isInput ? 'block' : 'hidden'"
        class="
          text-primary-dark text-base
          fa fa-times
          cursor-pointer
          opacity-70
          ml-2
        "
        @click="deleteChip(i)"
      />
    </div>
    <input
      :placeholder="placeholder"
      class="w-48 text-md bg-gray-100"
      :class="isInput ? 'block' : 'hidden'"
      v-model="currentInput"
      @change="validateEmail"
      @keypress="disabledEnter"
    />
    <button
      :class="isInput ? 'block' : 'hidden'"
      type="button"
      :disabled="!isValid || currentInput === ''"
      class="text-lg text-primary button-add"
      @click="saveChip"
    >
      <i class="text-base fa fa-plus opacity-70 cursor-pointer ml-2" />
    </button>
  </div>
  <span v-if="!isValid" class="text-red-500">must be a valid Email</span>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { defineComponent } from "vue";

const EmailChips = defineComponent({
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    set: {
      type: Boolean,
      default: true,
    },
    chips: {
      type: Array,
      required: true,
    },
    isInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentInput: "",
      isValid: true,
    };
  },
  methods: {
    validateEmail(e) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isValid = re.test(e.target.value);
    },
    saveChip() {
      const { chips, currentInput, set } = this;
      ((set && chips.indexOf(currentInput) === -1) || !set) &&
        chips.push(currentInput);
      this.currentInput = "";
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
    },
    disabledEnter(e) {
      if (e.which === 13) {
        e.preventDefault();
      }
    },
    backspaceDelete({ which }) {
      which == 8 &&
        this.currentInput === "" &&
        this.chips.splice(this.chips.length - 1);
    },
  },
});
export default EmailChips;
</script>
<style scoped>
.chip-container {
  min-height: 34px;
}
input {
  border: 0;
  outline: 0;
  padding: 4px;
  border-bottom: 2px solid #8908f9;
}
input:focus {
  border-color: #f5ebfe;
}
.button-add {
  color: #4d00c5;
}
.button-add:disabled {
  color: #d3d3d3;
}
</style>
