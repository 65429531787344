import { MutationTree } from "vuex";
import { JobStagesInt } from "./types";

export const mutations: MutationTree<JobStagesInt> = {
  JOB_STAGES_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  RESET_JOB_STAGES(state) {
    state.data = [];
  },
  JOB_STAGES_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
