
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import { useRouter } from "vue-router";
import * as yup from "yup";
import TextField from "../../components/textField/index.vue";
import { userAPI } from "@/services/user";
import { createToastAxelerate } from "../../utils/toast";

const RecoveryPassword = defineComponent({
  name: "RecoveryPassword",
  components: {
    TextField,
  },
  setup() {
    const showConfirm = ref(false);
    const router = useRouter();
    const schema = yup.object({
      email: yup.string().required().email(),
    });

    const { value: email } = useField("email");
    const { meta, handleSubmit, resetForm } = useForm({
      initialValues: {
        email: "",
      },
      validationSchema: schema,
    });
    const onSubmit = handleSubmit((data) => {
      userAPI
        .recoverPassword(data)
        .then(() => {
          resetForm();
          showConfirm.value = !showConfirm.value;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          resetForm();
          createToastAxelerate("Recover Password", errors[0], "danger");
        });
    });
    return { onSubmit, email, meta, showConfirm, router };
  },
});

export default RecoveryPassword;
