
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

const MenuCandidate = defineComponent({
  name: "MenuCandidate",
  emits: ["openEdit", "deleteCandidate", "assignJob"],
  props: {
    candidateId: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const openEdit = () => {
      context.emit("openEdit");
      isMenuOpen.value = false;
    };
    const deleteCandidate = () => {
      context.emit("deleteCandidate");
      isMenuOpen.value = false;
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const assignJob = () => {
      context.emit("assignJob");
      isMenuOpen.value = false;
    };
    return {
      isMenuOpen,
      closeMenu,
      openEdit,
      deleteCandidate,
      router,
      assignJob,
    };
  },
});
export default MenuCandidate;
