import { Module } from "vuex";
import { RootState } from "../types";
import { CandidatesWithoutJobInt } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  error: "",
  data: [],
} as CandidatesWithoutJobInt;

export const candidatesWithoutJob: Module<CandidatesWithoutJobInt, RootState> =
  {
    state,
    getters,
    actions,
    mutations,
  };
