import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47e0d396"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  value: "",
  disabled: ""
}
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Field, {
      name: _ctx.name,
      as: "select",
      class: _normalizeClass(_ctx.className),
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, {
      default: _withCtx(({ value }) => [
        _createElementVNode("option", _hoisted_1, _toDisplayString(_ctx.label), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option.id,
            value: option.id
          }, _toDisplayString(option.name), 9, _hoisted_2))
        }), 128)),
        _createTextVNode(" " + _toDisplayString(value), 1)
      ]),
      _: 1
    }, 8, ["name", "class", "disabled"]),
    _createVNode(_component_ErrorMessage, {
      name: _ctx.name,
      class: "text-sm overflow-ellipsis",
      style: {"color":"#ff3333"},
      id: "error"
    }, null, 8, ["name"])
  ], 64))
}