<template>
  <form class="w-full flex flex-col">
    <div class="mb-5">
      <Field
        name="title"
        class="bg-white p-3 text-black w-full rounded-2xl text-xl"
        disabled
      />
    </div>
    <GetCategoryArrayField :errors="errors" />
  </form>
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";
import { useForm, Field } from "vee-validate";
import * as yup from "yup";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { useRoute } from "vue-router";
import GetCategoryArrayField from "../getCategoryArrayField/index.vue";

const GetTalentEvaluationForm = defineComponent({
  name: "GetTalentEvaluationForm",
  components: {
    Field,
    GetCategoryArrayField,
  },
  setup() {
    const route = useRoute();
    const id = route.query.id;
    const schema = yup.object({
      title: yup.string().label("Title").required(),
      evaluation_categories: yup.array().of(
        yup.object().shape({
          name: yup.string().nullable().label("Category title").required(),
          evaluation_questions: yup
            .array()
            .of(
              yup
                .object()
                .shape({ content: yup.string().required().label("Question") })
            ),
        })
      ),
    });
    onBeforeMount(() => {
      TalentEvaluationAPI.getOneEvaluation(id).then((res) => {
        setValues(res.data.evaluation);
      });
    });
    const { values, meta, errors, setValues } = useForm({
      validationSchema: schema,
    });
    return { values, meta, errors };
  },
});
export default GetTalentEvaluationForm;
</script>
