
import { computed, defineComponent } from "vue";
import Button from "@/components/button/index.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const ModalUpgradePlan = defineComponent({
  name: "ModalUpgradePlan",
  components: {
    Button,
  },
  emits: ["closeModal", "attachPaymentMethod"],
  props: {
    infoType: {
      type: String,
      required: true,
    },
    event: {
      type: String,
      required: false,
      default: "upgrade",
    }
  },
  setup(props, context) {
    const closeModal = () => {
      context.emit("closeModal");
    };
    const router = useRouter();
    const store = useStore();

    const upgradePlan = () => {
      router.push("/pricing");
    };

    const addPaymentMehtod = () => {
      context.emit("attachPaymentMethod");
    };

    return {
      closeModal,
      upgradePlan,
      addPaymentMehtod,
    };
  },
});

export default ModalUpgradePlan;
