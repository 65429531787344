
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import CardJobCandidate from "../cardJobCandidate/index.vue";
//import VPagination from "@hennge/vue3-pagination";
import Paginator from "@/components/paginator/index.vue";
import "../../styles/pagination.css";
import Skeleton from "../skeleton/index.vue";
import SearchInput from "../searchInput/index.vue";
import { useStore } from "vuex";
import { useState } from "../../composables/useState";
import { removeBlankProperties } from "../../utils/params";

const AddCandidatesInJob = defineComponent({
  name: "AddCandidatesInJob",
  emits: ["getCandidates"],
  components: {
    Skeleton,
    SearchInput,
    CardJobCandidate,
    //VPagination,
    Paginator,
  },
  setup(props, context) {
    const store = useStore();
    const [page, setPage] = useState(1);
    const isLoading = ref(true);
    const inputValue = ref("");

    const candidates = computed(() => store.state.candidatesWithoutJob.data);
    onBeforeMount(() => {
      store.commit("RESET_CANDIDATES_WITHOUT_JOB");
      getCandidates();
    });

    const getCandidates = (newPage = 1) => {
      isLoading.value = true;
      setPage(newPage);
      store
        .dispatch("getCandidatesWithoutJob", {
          page: page.value,
          filters: removeBlankProperties({
            search: inputValue.value,
            tagFilters: "out_of_process",
          }),
        })
        .then(() => {
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
        });
    };
    const getCandidatesOutside = () => {
      context.emit("getCandidates");
    };
    const updateHandler = (e: any) => {
      getCandidates(e);
    };
    return {
      candidates,
      getCandidates,
      isLoading,
      inputValue,
      getCandidatesOutside,
      updateHandler,
      page,
    };
  },
});

export default AddCandidatesInJob;
