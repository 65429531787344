/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceLoggedAPI, instanceFormAPI } from "../axiosConfig";
export default class TalentEvaluationAPIService {
  private axiosInstance: AxiosInstance;
  private instanceForm: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.instanceForm = instanceFormAPI;
  }
  async getAllTemplates(filters?: any, page = 1): Promise<unknown> {
    return this.axiosInstance.get(
      `/evaluations?page=${page}&${makeFilter(filters)}`
    );
  }
  async createEvaluation(data: any): Promise<unknown> {
    return this.axiosInstance.post("/evaluations", data);
  }
  async getAswersByTalent(id: string): Promise<unknown> {
    return this.axiosInstance.get(`/evaluation_answers/${id}`);
  }
  async updateAswersByTalent(id: string, payload: any): Promise<unknown> {
    return this.axiosInstance.put(`/evaluation_answers/${id}`, payload);
  }
  async getOneEvaluation(id: any): Promise<unknown> {
    return this.axiosInstance.get(`/evaluations/${id}`);
  }
  async updateTemplate(id: any, payload: any): Promise<unknown> {
    return this.axiosInstance.put(`/evaluations/${id}`, payload);
  }
  async assignTemplate(id: string, payload: any): Promise<unknown> {
    return this.instanceForm.put(`/evaluations/${id}/attach`, payload);
  }
  async archiveTemplate(id: string): Promise<unknown> {
    return this.axiosInstance.put(`/evaluations/${id}/deactivate`);
  }

  async copyTemplate(id: string, payload: any): Promise<unknown> {
    return this.axiosInstance.post(`/evaluations/${id}/duplicate`, payload);
  }
}
export const TalentEvaluationAPI = new TalentEvaluationAPIService();
