
import { defineComponent } from "vue";

const SimpleSelect = defineComponent({
  name: "SimpleSelect",
  inheritAttrs: false,
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String || Number,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        [{ id: 1, name: "test" }];
      },
    },
    className: {
      type: String,
      default:
        "w-full px-0 mt-0 border-2 rounded-lg border-solid appearance-none select-bg",
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },
});
export default SimpleSelect;
