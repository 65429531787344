<template>
  <form @submit="onSubmit" class="w-full flex flex-col">
    <div class="w-full py-3 px-4 rounded-2xl bg-white mb-5 h-16">
      <span class="text-3xl">
        {{ values && values.title !== "" ? values.title : "Talent evaluation" }}
      </span>
    </div>
    <div class="mb-5">
      <TextField
        name="title"
        :required="true"
        label="Title for the talent evaluation template..."
        :isError="errors && Boolean(errors.title)"
      />
    </div>
    <EditCategoryArrayField :errors="errors" />
  </form>
  <div class="w-full justify-end flex flex-row py-8">
    <Button
      @onClick="submitData"
      label="Save template"
      variant="contained"
      :disabled="!meta.dirty || !meta.valid"
    />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";
import TextField from "../textField/index.vue";
import { useForm } from "vee-validate";
import Button from "../button/index.vue";
import * as yup from "yup";
import EditCategoryArrayField from "../editCategoryArrayField/index.vue";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { useRoute } from "vue-router";

const EditTalentEvaluationForm = defineComponent({
  name: "EditTalentEvaluationForm",
  emits: ["submitData"],
  components: {
    TextField,
    EditCategoryArrayField,
    Button,
  },
  setup(props, context) {
    const route = useRoute();
    const id = route?.query?.id;
    yup.addMethod(yup.array, "unique", function (field, message) {
      return this.test("unique", message, function (array) {
        const uniqueData = Array.from(
          new Set(array && array.map((row) => row[field]?.toLowerCase()))
        );
        const isUnique = array && array.length === uniqueData.length;
        if (isUnique) {
          return true;
        }
        const index =
          array &&
          array.findIndex(
            (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
          );
        if (
          array &&
          array[index] &&
          array[index][field] &&
          array[index][field] === ""
        ) {
          return true;
        }
        return this.createError({
          path: `${this.path}[${index}].${field}`,
          message,
        });
      });
    });

    const schema = yup.object({
      title: yup.string().label("Title").required(),
      evaluation_categories: yup
        .array()
        .unique(
          "name",
          "This title already exists. Choose another one or add a version number and/or a special character. "
        )
        .of(
          yup.object().shape({
            name: yup.string().nullable().label("Category title").required(),
            evaluation_questions: yup
              .array()
              .of(
                yup
                  .object()
                  .shape({ content: yup.string().required().label("Question") })
              ),
          })
        ),
    });

    const { handleSubmit, values, meta, errors, setValues } = useForm({
      validationSchema: schema,
      initialValues: {
        categories: [{ name: "", questions: [{ content: "" }] }],
      },
    });
    const submitData = () => context.emit("submitData");
    const onSubmit = handleSubmit((values) => {
      return values;
    });
    onBeforeMount(() => {
      TalentEvaluationAPI.getOneEvaluation(id).then((res) => {
        setValues(res.data.evaluation);
      });
    });
    return { onSubmit, values, meta, submitData, errors };
  },
});
export default EditTalentEvaluationForm;
</script>
