
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { JobsAPI } from "../../services/jobs/index";
import { createToastAxelerate } from "../../utils/toast";
import ReusableModal from "../reusableModal/index.vue";
import WarningModal from "../warningModal/index.vue";
import { useState } from "../../composables/useState";
import { useStore } from "vuex";
import { optionsRejected } from "../../constants/rejectedOptions";
import ModalRejectCandidate from "@/components/modalRejectCandidate/index.vue";

const DropDownStage = defineComponent({
  name: "DropDownStage",
  emits: ["reloadCandidates", "setLoadingStage"],
  components: {
    ReusableModal,
    WarningModal,
    ModalRejectCandidate,
  },
  props: {
    stages: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const isMenuOpen = ref(false);
    const reason = ref("");
    const [showDrop, setShowDrop] = useState(false);
    const [showRejected, setShowRejected] = useState(false);
    const [lastStage, setLastStage] = useState("");
    const candidateId = computed(() => store.state.candidateInfo.data?.id);
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const openModal = (id: string) => {
      isMenuOpen.value = false;
      setShowDrop(true);
      setLastStage(id);
    };
    const openModalRejected = (id: string) => {
      isMenuOpen.value = false;
      setShowRejected(true);
      setLastStage(id);
    };
    const validateLastStage = () => {
      JobsAPI.updateCandidateStage(lastStage.value)
        .then(() => {
          createToastAxelerate(
            "Stage Candidate",
            "The candidate has been moved to stage successfully",
            "success"
          );
          context.emit("reloadCandidates");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Stage Candidate",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    };

    const changeStage = (id: string) => {
      context.emit("setLoadingStage");
      JobsAPI.updateCandidateStage(id)
        .then(() => {
          createToastAxelerate(
            "Stage Candidate",
            "The candidate has been moved to stage successfully",
            "success"
          );
          context.emit("setLoadingStage");
          context.emit("reloadCandidates");
        })
        .catch((err) => {
          context.emit("setLoadingStage");
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Stage Candidate",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    };

    const reloadCandidates = () => {
      context.emit("reloadCandidates");
    };

    const handleWatchStage = (current: string, id: string) => {
      isMenuOpen.value = false;
      switch (current) {
        case "Hiring":
          return openModal(id);
        case "Rejected":
          return openModalRejected(id);
        default:
          return changeStage(id);
      }
    };

    return {
      isMenuOpen,
      closeMenu,
      changeStage,
      router,
      openModal,
      showDrop,
      setShowDrop,
      validateLastStage,
      setShowRejected,
      showRejected,
      handleWatchStage,
      optionsRejected,
      reason,
      lastStage,
      setLastStage,
      candidateId,
      reloadCandidates,
    };
  },
});
export default DropDownStage;
