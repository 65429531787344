
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, reactive, computed, ref, Ref, onMounted, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { numberWithCommas } from "../../utils/functions";
import TimeLine from "../timeLine/index.vue";
import Skeleton from "../skeleton/index.vue";
import Button from "@/components/button/index.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import JobCommentForm from "../../components/jobCommentForm/index.vue";
import { useStore } from "vuex";
import { useState } from "@/composables/useState";
import BaseModal from "@/components/baseModal/index.vue";
import { usePromisedModal } from "../../composables/usePromisedModal";
import { JobsAPI } from "@/services/jobs";
import { createToastAxelerate } from "../../utils/toast";
import { mxPrices, usaPrices } from "@/constants/pricing";

const JobShowDetail = defineComponent({
  name: "JobShowDetail",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // Chips,
    Button,
    TimeLine,
    Skeleton,
    JobCommentForm,
    BaseModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const labelNoInfo = "No information";
    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const jobId: Ref<string> = ref(route.params.id as string);
    const handleRouter = (route: string) => router.push(route);
    const replaceEnum = (str: string) => (str ? str.replace(/_/g, " ") : str);
    const companyIndustryProfile = computed(
      () => store.state.profile.data.company.company_industry
    );
    const infoCommentStore = computed(() => store.state.jobs.info_comments);
    const detailsCommentStore = computed(
      () => store.state.jobs.details_comments
    );
    const functionCommentStore = computed(
      () => store.state.jobs.function_comments
    );
    const salaryCommentStore = computed(() => store.state.jobs.salary_comments);
    const showConfirmationModal = ref(false);
    const profile = computed(() => store.state.profile.data);
    const confirmationModal = usePromisedModal<boolean>();
    const managedJobConfirmation = ref(false);
    const pricing = ref();

    const getCommentsBySection = (path: string) => {
      setLoadingTimeline(true);
      store
        .dispatch(path, jobId.value)
        .then(() => {
          setLoadingTimeline(false);
        })
        .catch(() => {
          setLoadingTimeline(false);
        });
    };
    const expand = reactive({
      comment_info: false,
      comment_function: false,
      comment_details: false,
      comment_salary: false,
    });
    const getFirstTime = () => {
      const values = [
        "getInfoComments",
        "getFunctionComments",
        "getDetailsComments",
        "getSalaryComments",
      ];
      values.map((item) => {
        getCommentsBySection(item);
      });
    };
    const managedJobActions = async (option: boolean) => {
      managedJobConfirmation.value = option;
      showConfirmationModal.value = true;
      const confirmed = await confirmationModal.ask();
      let confirmation = {
        confirmation: option,
      };

      if (confirmed) {
        JobsAPI.managedJobConfirmation(jobId.value, confirmation)
          .then(() => {
            store.dispatch("getJobState", jobId.value);
            createToastAxelerate("Job", "Job updated", "success");
          })
          .catch(() => {
            createToastAxelerate("Error", "Something went wrong", "danger");
          });
      }

      showConfirmationModal.value = false;
    };
    onBeforeMount(() => {
      if (profile.value.company.company_country.short_code === "MX") {
        pricing.value = mxPrices;
      } else {
        pricing.value = usaPrices;
      }
    });
    onMounted(() => {
      getFirstTime();
    });
    return {
      route,
      router,
      handleRouter,
      replaceEnum,
      numberWithCommas,
      labelNoInfo,
      setShowModal,
      showModal,
      expand,
      loadingTimeline,
      setLoadingTimeline,
      companyIndustryProfile,
      infoCommentStore,
      getCommentsBySection,
      detailsCommentStore,
      functionCommentStore,
      salaryCommentStore,
      profile,
      confirmationModal,
      showConfirmationModal,
      managedJobActions,
      managedJobConfirmation,
      pricing,
    };
  },
});

export default JobShowDetail;
