import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table-auto bg-white w-full text-center text-sm" }
const _hoisted_2 = { class: "p-3 flex justify-center" }
const _hoisted_3 = { class: "p-3" }
const _hoisted_4 = { class: "p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuMember = _resolveComponent("MenuMember")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
          return (_openBlock(), _createElementBlock("th", {
            key: header.key,
            class: "border-b-2 border-gray-100 p-2 font-semibold text-gray-500 text-center"
          }, _toDisplayString(header.label), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members && _ctx.members.length > 0 && _ctx.members, (item, i) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: `${i + 1}-item-table`,
          class: _normalizeClass('border-b-2 border-gray-100')
        }, [
          _createElementVNode("td", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([
              item.status === 'inactive'
                ? 'bg-white border-2 border-dashed	border-gray-300'
                : 'bg-primary'
            , "flex justify-center items-center rounded-full text-white w-12 h-12 p-4"])
            }, _toDisplayString(item.status !== "inactive"
                ? _ctx.getInitials(`${item.first_name} ${item.last_name}`)
                : ""), 3)
          ]),
          _createElementVNode("td", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass([item.status === 'inactive' && 'opacity-40', "font-bold"])
            }, _toDisplayString(item.status !== "inactive"
                ? `${item.first_name} ${item.last_name}`
                : "Invitation sent"), 3)
          ]),
          _createElementVNode("td", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass(item.status === 'inactive' && 'opacity-50')
            }, _toDisplayString(item.email), 3)
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_MenuMember, {
              onDeleteMember: ($event: any) => (_ctx.deleteMember(item.id))
            }, null, 8, ["onDeleteMember"])
          ])
        ]))
      }), 128))
    ])
  ]))
}