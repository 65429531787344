import { Module } from "vuex";
import { RootState } from "../types";
import { JobsInterface } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  error: "",
  data: [],
  info_comments: [],
  function_comments: [],
  details_comments: [],
  salary_comments: [],
} as JobsInterface;

export const jobs: Module<JobsInterface, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
