<template>
  <div
    class="relative inline-block text-left text-black"
    v-click-away="closeMenu"
  >
    <div>
      <span class="rounded-md shadow-sm">
        <button
          @click="isMenuOpen = !isMenuOpen"
          type="button"
          class="
            inline-flex
            items-center
            justify-between
            w-full
            rounded-md
            h-10
            px-4
            py-4
            bg-primary
            text-white
            hover:text-gray-200
            transition
            ease-in-out
            duration-150
          "
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <span v-if="profile && profile.first_name">
            {{ profile && `${profile.first_name} ${profile.last_name}` }}
          </span>
          <div v-else>
            <div class="animate-pulse flex space-x-4 rounded-md">
              <div class="rounded-full bg-blue-100 h-5 w-52"></div>
              <div class="flex-1 space-y-6 py-1"></div>
            </div>
          </div>
          <div class="pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </button>
      </span>
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="
          origin-top-right
          absolute
          right-0
          mt-2
          w-96
          rounded-2xl
          shadow-2xl
          text-sm
          overflow-hidden
          z-20
        "
      >
        <div
          class="rounded-md bg-white w-full px-2 py-3"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div>
            <div class="p-4 flex items-center">
              <div class="w-full">
                <div class="flex flex-row">
                  <div
                    class="
                      flex
                      justify-center
                      items-center
                      rounded-full
                      bg-primary
                      text-white
                      w-12
                      h-12
                      p-4
                    "
                  >
                    {{
                      getInitials(
                        profile && `${profile.first_name} ${profile.last_name}`
                      )
                    }}
                  </div>
                  <div class="flex flex-col pl-3 py-1">
                    <span class="font-semibold text-base">{{
                      profile && `${profile.first_name} ${profile.last_name}`
                    }}</span>
                    <span class="text-sm text-gray-500">{{
                      profile && profile.email
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              profile &&
              profile.company &&
              profile.company.subscription_status === 'inactive'
            "
            class="
              flex flex-row
              justify-between
              bg-background-secondary
              rounded
              mt-2
              p-4
            "
          >
            <div class="flex flex-col">
              <span class="font-medium">Professional plan</span>
              <span>Monthly renewal</span>
            </div>
            <button
              v-on:click="router.push('/subscriptions')"
              class="text-primary font-semibold"
            >
              Upgrade
            </button>
          </div>
          <div
            v-else
            class="
              flex flex-row
              justify-between
              bg-background-secondary
              rounded
              mt-2
              p-4
            "
          >
            <div class="flex flex-col">
              <span class="font-medium">
                {{
                  profile &&
                  profile.company &&
                  profile.company.last_subscription?.nickname &&
                  profile.company.last_subscription?.nickname
                    ? profile.company.last_subscription?.nickname
                    : "Professional plan"
                }}
              </span>
              <span>Monthly renewal</span>
            </div>
            <span class="text-primary font-semibold p-3"> Active </span>
          </div>
          <div class="flex flex-col mt-4">
            <button
              @click="accountSetting"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row">
                  <img src="../../assets/navbar-icons/settings.svg" />
                  <span class="font-semibold ml-4">Account settings</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                  />
                  <path
                    d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                  />
                </svg>
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button
              @click="goToAccountMembers"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row">
                  <img src="../../assets/navbar-icons/dribbble.svg" />
                  <span class="font-semibold ml-4">Account members</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                  />
                  <path
                    d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                  />
                </svg>
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button
              @click="goToBilling"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row">
                  <img src="../../assets/navbar-icons/dollar.svg" />
                  <span class="font-semibold ml-4">Billing</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                  />
                  <path
                    d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                  />
                </svg>
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button
              class="rounded p-4 hover:bg-background"
              @click="router.push('/notifications')"
            >
              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span class="font-semibold ml-4">Notifications</span>
                </div>
              </div>
            </button>
            <div
              class="h-0.5 bg-gray-200 mx-4 my-2"
              v-if="roles.length > 1"
            ></div>
            <button
              @click="changePlatform"
              class="rounded p-4 hover:bg-background"
              v-if="roles.length > 1"
            >
              <div class="flex flex-row items-center">
                <img class="h-7 w-7 -ml-1" src="../../assets/workforce.svg" />
                <span class="font-semibold ml-2"
                  >Change to Workforce Solutions</span
                >
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button
              @click="goToHelpCenter()"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row">
                  <img
                    class="h-6 w-5"
                    src="../../assets/navbar-icons/circle-question.svg"
                  />
                  <span class="font-semibold ml-4">Axelerate Help Center</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                  />
                  <path
                    d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                  />
                </svg>
              </div>
            </button>

            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button @click="logOut" class="rounded p-4 hover:bg-background">
              <div class="flex flex-row items-center">
                <img src="../../assets/navbar-icons/logout.svg" />
                <span class="font-semibold ml-4">Log out</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getInitials } from "../../utils/functions";

const MenuDropDown = defineComponent({
  name: "MenuDropdown",
  emits: ["logOut"],
  setup(props, context) {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const store = useStore();
    const profile = computed(() => store.state.profile.data);
    const roles = computed(() => store.state.profile.data.roles);
    const logOut = () => {
      context.emit("logOut");
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    const changePlatform = () => {
      window.location.replace(process.env.VUE_APP_WORKFORCE_SOLUTIONS_URL);
    };

    const accountSetting = () => {
      window.location.replace(process.env.VUE_APP_ACCOUNT_SETTINGS);
    };

    const goToAccountMembers = () => {
      window.location.replace(
        `${process.env.VUE_APP_ACCOUNT_SETTINGS}`.replace(
          /\/settings/,
          "/members"
        )
      );
    };

    const goToBilling = () => {
      window.location.replace(
        `${process.env.VUE_APP_ACCOUNT_SETTINGS}`.replace(
          /\/settings/,
          "/billing"
        )
      );
    };

    const goToHelpCenter = () => {
      window.open("http://support.axelerate.io", "_blank");
    };

    return {
      isMenuOpen,
      roles,
      closeMenu,
      router,
      logOut,
      profile,
      getInitials,
      changePlatform,
      accountSetting,
      goToAccountMembers,
      goToBilling,
      goToHelpCenter,
    };
  },
});

export default MenuDropDown;
</script>
