
import { defineComponent } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { JobsAPI } from "@/services/jobs";
import { useRoute } from "vue-router";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import TextField from "../../components/textField/index.vue";
import TimeLine from "../timeLine/index.vue";

const JobCommentForm = defineComponent({
  name: "JobCommentForm",
  emits: ["getDescriptionComments"],
  components: {
    Button,
    TextField,
    TimeLine,
  },
  props: {
    section: {
      type: String,
    },
  },
  setup(props, context) {
    const comment = "";
    const route = useRoute();
    const path = route.path;
    const jobId = route.params.id;
    const schema = yup.object({
      comment: yup.string().required().label("comment"),
    });

    const { meta, handleSubmit, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        comment: "",
      },
    });
    const getDescriptionComments = () => {
      context.emit("getDescriptionComments");
    };
    const onSubmit = handleSubmit((values) => {
      const buildResponse = {
        comment: {
          content: values.comment,
          section: props.section,
        },
      };
      JobsAPI.createJobComment(buildResponse, jobId)
        .then(() => {
          createToastAxelerate(
            "Add comment",
            "The comment has been added.",
            "success"
          );
          resetForm();
          getDescriptionComments();
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Add comment",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    });

    return {
      onSubmit,
      path,
      comment,
      meta,
      TextField,
      TimeLine,
    };
  },
});
export default JobCommentForm;
