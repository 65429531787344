import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4116dd1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value"]
const _hoisted_2 = {
  value: "",
  disabled: ""
}
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    name: _ctx.name,
    class: _normalizeClass(_ctx.className),
    value: _ctx.modelValue,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
  }, [
    _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.label), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option.id,
        value: option.id
      }, _toDisplayString(option.name), 9, _hoisted_3))
    }), 128)),
    _createTextVNode(" " + _toDisplayString(_ctx.modelValue), 1)
  ], 42, _hoisted_1))
}