/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";
export default class HomeAPIService {
  private axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getAnalytics(): Promise<unknown> {
    return this.axiosInstance.get("/analytics");
  }
}
export const HomeAPI = new HomeAPIService();
