import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Slider, _mergeProps({
      class: "w-full",
      modelValue: _ctx.rangeValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rangeValue.value) = $event))
    }, _ctx.rangeValue, {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = (e) => _ctx.$emit('update:modelValue', e))
    }), null, 16, ["modelValue"])
  ]))
}