<template>
  <div class="floating-input relative">
    <Field
      ref="inputRef"
      type="text"
      :placeholder="name"
      :label="label"
      :value="modelValue"
      :name="name"
      @focus="focusDefault"
      class="
        border border-gray-200
        focus:outline-none
        rounded-xl
        focus:border-gray-500 focus:shadow-sm
        w-full
        p-3
        h-16
      "
    />
    <label
      :for="name"
      class="
        absolute
        top-0
        left-0
        px-3
        py-5
        h-full
        pointer-events-none
        transform
        origin-left
        transition-all
        text-gray-500
        duration-100
        ease-in-out
      "
      >{{ label }}</label
    >
    <ErrorMessage
      :name="name"
      class="text-sm overflow-ellipsis"
      style="color: #ff3333"
      id="error"
    />
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { defineComponent, watch } from "vue";
import { ErrorMessage, Field } from "vee-validate";

const CurrencyField = defineComponent({
  name: "CurrencyField",
  inheritAttrs: false,
  components: {
    ErrorMessage,
    Field,
  },
  props: {
    name: String,
    modelValue: Number || String,
    className: {
      type: String,
      default:
        "pt-5 pb-2 pl-4 pr-10 block w-full px-0 mt-0 bg-transparent border-2 rounded-lg border-solid appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200",
    },
    label: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput({
      currency: "USD",
      currencyDisplay: "hidden",
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: false,
      useGrouping: true,
      accountingSign: false,
    });

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );
    const focusDefault = (e) => {
      if (e.target.value === "") {
        setValue(0);
      }
    };
    return { inputRef, formattedValue, focusDefault };
  },
});

export default CurrencyField;
</script>
<style src="./styles.css" lang="css" scoped></style>
