
import { defineComponent, computed } from "vue";
import JobShowDetail from "../../components/jobShowDetail/index.vue";
import { useStore } from "vuex";

const JobDetails = defineComponent({
  name: "JobDetails",
  components: {
    JobShowDetail,
  },
  setup() {
    const store = useStore();
    const job = computed(() => store.state.addCanidates.data);

    return { job };
  },
});

export default JobDetails;
