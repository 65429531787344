
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  ref,
  onMounted,
  watchEffect,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Header from "../../components/header/index.vue";
import Button from "../../components/button/index.vue";
import "../../styles/pagination.css";
import { useState } from "../../composables/useState";
import Tabs from "../../components/tabs/index.vue";
import { NotificationsAPI } from "../../services/notifications/index";
import { JobsAPI } from "../../services/jobs/index";
import NotificationList from "../../components/notificationList/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import SkeletonNoficatios from "../../components/skeleton-notifications/index.vue";
import RadioButton from "../../components/radioButton/index.vue";
import { removeBlankProperties } from "@/utils/params";
import EmptyState from "../../components/emptyState/index.vue";
import { useStore } from "vuex";

const NotificationsCenter = defineComponent({
  name: "NotificationsCenter",
  components: {
    Header,
    Tabs,
    NotificationList,
    Button,
    RadioButton,
    Loading,
    SkeletonNoficatios,
    EmptyState,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const page = ref(1);
    const isLoading = ref(true);
    const [loadingNotif, setLoadingNotif] = useState(false);
    const [loadingMoreNotifications, setloadingMoreNotifications] =
      useState(false);
    const toggleActive = ref(false);
    const jobs: any = ref([]);
    const tab = ref(0);
    const inputValue = ref("");
    const label = ref("");
    const filters = ref({});
    const select = ref("");
    const pagination = ref({});
    const notifications = ref([]);
    const notificationsJobs = ref([]);
    const category = ref("");
    const all = ref(0);
    const jobs_count = ref(0);
    const candidates = ref(0);
    const comments = ref(0);
    const tabElements = ["All", "Jobs", "Candidates", "Comments"];
    const radioStatus = ref("");
    const radioJob = ref("");
    const meta = ref({});
    const expand = reactive({
      status: false,
      jobs: false,
    });

    const status = [
      {
        name: "All",
        id: "all",
      },
      {
        name: "Read",
        id: "read",
      },
      {
        name: "Unread",
        id: "unread",
      },
    ];

    function notificationsCenter() {
      NotificationsAPI.getNotifications(
        page.value,
        category.value,
        filters.value
      )
        .then((response: any) => {
          notifications.value = notifications.value.concat(
            response.data.notifications
          );

          page.value = response.data.meta.pagination.next_page;
          meta.value = response.data.meta.pagination;
          all.value = response.data.all_notifications_count;
          jobs_count.value = response.data.jobs_notifications_count;
          candidates.value = response.data.candidates_notifications_count;
          comments.value = response.data.comments_notifications_count;
          setLoadingNotif(false);
          isLoading.value = false;
          setloadingMoreNotifications(false);
        })
        .catch((err: any) => {
          setloadingMoreNotifications(false);
          isLoading.value = false;
          setLoadingNotif(false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Notifications center", errors[0], "danger");
        });
    }

    function clearFilters() {
      radioStatus.value = "";
      radioJob.value = "";

      filters.value = removeBlankProperties({
        jobs: radioJob.value,
        status: radioStatus.value !== "all" ? radioStatus.value : "",
      });
      resetSearch();
    }

    const readAllNotifications = () => {
      NotificationsAPI.readAllNotifications()
        .then(async () => {
          page.value = 1;
          notifications.value = [];
          resetSearch();
          await store.dispatch("getProfile");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Notifications center", errors[0], "danger");
        });
    };

    watchEffect(() => {
      switch (tab.value) {
        case 1:
          category.value = "jobs";
          break;
        case 2:
          category.value = "candidates";
          break;
        case 3:
          category.value = "comments";
          break;
        default:
          category.value = "";
          break;
      }
    });

    watch(
      () => category.value,
      () => {
        resetSearch();
      }
    );

    onMounted(() => {
      notifications.value = [];
      setLoadingNotif(true);
      notificationsCenter();
      radioStatus.value = "all";
      JobsAPI.getJobList()
        .then((response: any) => {
          jobs.value = response.data;
        })
        .catch(() => {
          createToastAxelerate("error", "error", "danger");
        });
    });

    const loadMore = () => {
      setloadingMoreNotifications(true);
      notificationsCenter();
    };

    watch(
      () => radioStatus.value,
      () => {
        filters.value = removeBlankProperties({
          jobs: radioJob.value,
          status: radioStatus.value !== "all" ? radioStatus.value : "",
        });
      }
    );
    watch(
      () => radioJob.value,
      () => {
        filters.value = removeBlankProperties({
          jobs: radioJob.value,
          status: radioStatus.value !== "all" ? radioStatus.value : "",
        });
      }
    );

    const resetSearch = () => {
      page.value = 1;
      notifications.value = [];
      setLoadingNotif(true);
      notificationsCenter();
    };

    return {
      readAllNotifications,
      notificationsCenter,
      jobs,
      toggleActive,
      isLoading,
      router,
      page,
      notifications,
      inputValue,
      select,
      filters,
      label,
      tab,
      tabElements,
      pagination,
      setLoadingNotif,
      loadingNotif,
      notificationsJobs,
      category,
      all,
      jobs_count,
      candidates,
      comments,
      expand,
      status,
      radioStatus,
      radioJob,
      clearFilters,
      loadMore,
      loadingMoreNotifications,
      setloadingMoreNotifications,
      meta,
      resetSearch,
    };
  },
});

export default NotificationsCenter;
