
import { defineComponent } from "vue";

const BillingTable = defineComponent({
  name: "BillingTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  setup() {
    function getAmount(amount: string) {
      return `$${parseFloat(amount)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    function getService(service: string) {
      return service.replace("_", " ");
    }

    return {
      getAmount,
      getService,
    };
  },
});
export default BillingTable;
