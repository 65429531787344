import { MutationTree } from "vuex";
import { CandidatesWithoutJobInt } from "./types";

export const mutations: MutationTree<CandidatesWithoutJobInt> = {
  CANDIDATES_WITHOUT_JOB_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  RESET_CANDIDATES_WITHOUT_JOB(state) {
    state.data = [];
  },
  CANDIDATES_WITHOUT_JOB_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
