
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";

const TextArea = defineComponent({
  name: "TextArea",
  inheritAttrs: false,
  components: {
    ErrorMessage,
    Field,
  },
  props: {
    rows: {
      type: String,
      default: "3",
    },
    cols: {
      type: String,
      default: "50",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default:
        "pt-5 pb-2 pl-4 pr-10 block w-full px-0 mt-0 bg-transparent border-2 rounded-lg border-solid appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200",
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
});
export default TextArea;
