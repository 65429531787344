import Vuex, { StoreOptions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { RootState } from "./types";
import { auth } from "./auth";
import { createJob } from "./createJobForm";
import { profile } from "./profile";
import { addCanidates } from "./addCanidates";
import { jobs } from "./jobs";
import { candidateInfo } from "./candidateInfo";
import { jobCandidates } from "./jobCandidates";
import { jobStages } from "./jobStages";
import { templates } from "./templates";
import { accountMembers } from "./accountMembers";
import { candidates } from "./candidates/index";
import { drafts } from "./drafts/index";
import { subscriptions } from "./subscriptions/index";
import { stripes } from "./stripes/index";
import { timeLine } from "./timeLine/index";
import { analytics } from "./analytics/index";
import { candidatesWithoutJob } from "./candidatesWithoutJob/index";
import { answers } from "./answers/index";
import { talentEvaluation } from "./talentEvaluation/index";
import { nylas } from "./nylas";
import { accountStatus } from "./accountStatus/index";
import { emailComposer } from "./emailComposer/index";

const tokenState = createPersistedState({
  paths: ["auth", "profile", "nylas"],
});

const store: StoreOptions<RootState> = {
  state: {},
  modules: {
    accountMembers,
    addCanidates,
    analytics,
    answers,
    auth,
    candidates,
    candidateInfo,
    candidatesWithoutJob,
    createJob,
    emailComposer,
    drafts,
    jobCandidates,
    jobStages,
    jobs,
    profile,
    subscriptions,
    stripes,
    templates,
    timeLine,
    talentEvaluation,
    nylas,
    accountStatus,
  },
  mutations: {},
  actions: {},
  plugins: [tokenState],
};

export default new Vuex.Store<RootState>(store);
