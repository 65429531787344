/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { JobsInterface } from "./types";
import { JobsAPI } from "../../services/jobs/index";

export const actions: ActionTree<JobsInterface, RootState> = {
  getAllJobs({ commit }, obj) {
    return new Promise((resolve, reject) => {
      JobsAPI.getAllJobs(obj.filters, obj.page)
        .then((data: any) => {
          resolve(false);
          commit("JOBS_FULLFILED", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("JOBS_REJECTED", err);
        });
    });
  },
  getInfoComments({ commit }, obj) {
    return new Promise((resolve, reject) => {
      JobsAPI.infoComments(obj)
        .then((data: any) => {
          resolve(false);
          commit("INFO_COMMENTS", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("INFO_COMMENTS", err);
        });
    });
  },
  getFunctionComments({ commit }, obj) {
    return new Promise((resolve, reject) => {
      JobsAPI.functionComments(obj)
        .then((data: any) => {
          resolve(false);
          commit("FUNCTION_COMMENTS", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("FUNCTION_COMMENTS", err);
        });
    });
  },
  getDetailsComments({ commit }, obj) {
    return new Promise((resolve, reject) => {
      JobsAPI.detailsComments(obj)
        .then((data: any) => {
          resolve(false);
          commit("DETAILS_COMMENTS", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("DETAILS_COMMENTS", err);
        });
    });
  },
  getSalaryComments({ commit }, obj) {
    return new Promise((resolve, reject) => {
      JobsAPI.salaryComments(obj)
        .then((data: any) => {
          resolve(false);
          commit("SALARY_COMMENTS", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("SALARY_COMMENTS", err);
        });
    });
  },
};
