/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from "vuex";
import { AnalyticsInt } from "./types";

export const mutations: MutationTree<AnalyticsInt> = {
  ANALYTICS_FULLFILED(state, payload: any) {
    state.data = payload;
  },
  ANALYTICS_REJECTED(state, payload: string) {
    state.error = payload;
  },
  RESET_ANALYTICS(state) {
    state.data = [];
  },
};
