<template>
  <div class="flex flex-row space-x-3 justify-between">
    <div class="flex flex-row">
      <span class="text-lg font-bold py-3">Organization name</span>
      <div class="pl-1 pt-1">
        <Tooltip message="you could change your company info." />
      </div>
    </div>
    <button
      type="button"
      v-if="false"
      disabled
      @click="showModal = !showModal"
      class="text-sm font-bold text-primary"
    >
      Edit
    </button>
  </div>
  <div class="pt-3 flex flex-row">
    <div
      class="
        m-1
        mr-2
        w-12
        h-12
        relative
        flex
        justify-center
        items-center
        rounded-full
        bg-primary
        text-xl text-white
        uppercase
      "
    >
      {{ getInitials(userRes && userRes.name) }}
    </div>
    <div class="pl-3 flex flex-col space-y-2">
      <span class="text-xl font-bold">{{ userRes && userRes.name }}</span>
      <span class="text-base font-light text-gray-400">
        Software development
      </span>
      <div v-if="false" class="flex flex-row space-x-3 items-center">
        <span class="text-sm text-primary font-light">{{
          `${userRes && userRes.email ? userRes.email : "-"}`
        }}</span>
        <span class="text-xs text-gray-400 font-light">{{
          `+ ${userRes && userRes.phone}`
        }}</span>
      </div>
    </div>
  </div>
  <ReusableModal :show="showModal" @close="showModal = false">
    <form @submit="onSubmit">
      <div class="flex flex-col mb-6">
        <span class="text-xl font-bold py-3">Edit Company details</span>
      </div>
      <div
        v-for="(item, index) in userFields"
        :key="index"
        class="flex flex-col mb-6"
      >
        <div class="relative">
          <TextField
            v-model="item.model"
            :label="item.label"
            :typeInput="item.type"
            :name="item.name"
          />
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-5/12">
          <button
            type="submit"
            :disabled="!meta.dirty || !meta.valid || !meta.touched"
            class="
              flex
              items-center
              justify-center
              disabled:opacity-50
              focus:outline-none
              text-white text-sm
              sm:text-base
              bg-primary
              rounded-full
              py-2
              w-full
              transition
              duration-150
              ease-in
              h-14
              btn-submit
            "
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </ReusableModal>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import Tooltip from "../helpTooltip/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import TextField from "../../components/textField/index.vue";
import { useStore } from "vuex";
import { getInitials } from "../../utils/functions";

const CompanyDetails = defineComponent({
  name: "CompanyDetails",
  components: {
    Tooltip,
    ReusableModal,
    TextField,
  },
  setup() {
    const showModal = ref(false);
    const store = useStore();
    const userRes = computed(() => store.state.profile.data.company);
    const schema = yup.object({
      company_name: yup.string().required(),
      industry: yup.string().required().min(8),
      position: yup.string().required(),
      phone: yup.string(),
    });

    const { value: companyName } = useField("company_name");
    const { value: lastName } = useField("industry");
    const { value: website } = useField("website");
    const { value: phone } = useField("phone");
    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        company_name: "",
        industry: "",
        website: "",
        email: "",
        phone: "",
      },
    });

    const userFields = ref([
      {
        name: "company_name",
        model: companyName,
        label: "Company name*",
        type: "text",
      },
      { name: "industry", model: lastName, label: "industry*", type: "text" },
      { name: "website", model: website, label: "Website*", type: "text" },
      { name: "phone", model: phone, label: "Phone number*", type: "text" },
    ]);

    return { userFields, meta, showModal, userRes, getInitials };
  },
});

export default CompanyDetails;
</script>
