
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  watchEffect,
  Ref,
  reactive,
} from "vue";
import { useRouter } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useStore } from "vuex";
import Header from "../../components/header/index.vue";
import Button from "../../components/button/index.vue";
import JobsEmptyState from "../../components/jobsEmptyState/index.vue";
import JobCard from "../../components/jobCard/index.vue";
//import VPagination from "@hennge/vue3-pagination";
import Paginator from "@/components/paginator/index.vue";
import "../../styles/pagination.css";
import SearchInput from "../../components/searchInput/index.vue";
import { useState } from "../../composables/useState";
import { optionDepartament } from "../../constants/jobs";
import { removeBlankProperties } from "../../utils/params";
import CustomDropdown from "../../components/customDropdown/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import AddTemplateToJob from "../../components/addTemplateToJob/index.vue";
import AddChallengeToJob from "../../components/addChallengeToJob/index.vue";
import AddRecruiterToJob from "../../components/addRecruiterToJob/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import RadioButton from "../../components/radioButton/index.vue";
import { jobStatus } from "../../constants/jobs";
import CheckBoxGroup from "../../components/checkboxGroup/index.vue";
import CloseJob from "../../components/closeJob/index.vue";
import { createToastAxelerate } from "@/utils/toast";
import ModalUpgradePlan from "@/components/modalUpgradePlan/index.vue";
import BaseModal from "@/components/baseModal/index.vue";
import { usePromisedModal } from "@/composables/usePromisedModal";

const Jobs = defineComponent({
  name: "Jobs",
  components: {
    Header,
    JobsEmptyState,
    JobCard,
    AddTemplateToJob,
    Paginator,
    SearchInput,
    Button,
    Loading,
    CustomDropdown,
    ReusableModal,
    AddChallengeToJob,
    AddRecruiterToJob,
    RadioButton,
    CheckBoxGroup,
    CloseJob,
    ModalUpgradePlan,
    BaseModal,
  },
  setup() {
    const store = useStore();
    const token = computed(() => store.state.auth);
    const jobsStore = computed(() => store.state.jobs.data);
    const userId = computed(() => store.state.profile.data.id);
    const router = useRouter();
    const checkValues = ref([]);
    const [page, setPage] = useState(1);
    const isLoading = ref(true);
    const toggleActive = ref(false);
    const jobs: any = ref([]);
    const inputValue = ref("");
    const label = ref("");
    const [exclude, setExclude] = useState(false);
    const filters = ref({});
    const jobName = ref("");
    const select = ref("");
    const radioLocation = ref("");
    const deparments: Ref<Array<any>> = ref([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [showChallenge, setShowChallenge] = useState(false);
    const [showRecruiter, setShowRecruiter] = useState(false);
    const [showCloseJob, setShowCloseJob] = useState(false);
    const [locations, setLocations] = useState([]);
    const managedJobConfirmation = ref(false);
    const showConfirmationModal = ref(false);
    const confirmationModal = usePromisedModal<boolean>();
    const dataJob = ref({});
    const showModalUpgrade = ref(false);
    const profile = computed(() => store.state.profile.data);
    const hascompanyPlan = computed(
      () => store.state.profile.data?.company_plan_resources
    );
    const canAddJobs = computed(
      () => store.state.profile.data?.company_plan_resources?.can_add_jobs
    );
    const expand = reactive({
      location: false,
      status: false,
    });

    const getAllResults = async () => {
      isLoading.value = true;
      store
        .dispatch("getAllJobs", {
          page: page.value,
          filters: {
            search: Object.values(filters.value).join("%26"),
            location:
              radioLocation.value === "Remote"
                ? "remote_location"
                : radioLocation.value,
            job_status: setArchivedJobs(checkValues.value),
          },
        })
        .then((res: any) => {
          isLoading.value = res[0];
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const openTemplate = (id: string) => {
      setShowModal(true);
      setSelectedId(id);
    };

    const openChallenge = (id: string) => {
      setShowChallenge(true);
      setSelectedId(id);
    };

    onMounted(() => {
      JobsAPI.getJobLocations(userId.value).then((res: any) => {
        res.data.unshift("Remote");
        setLocations(res.data);
      });

      label.value = "all_departments";
      if (!token.value.token) {
        router.push("/log-in");
      }
      deparments.value = [...optionDepartament];
      if (
        deparments.value.filter((e) => e.id !== "all_departments").length > 0
      ) {
        deparments.value.splice(0, 0, {
          id: "all_departments",
          name: "All deparments",
        });
      }
      getAllResults();
    });

    function clearResults() {
      radioLocation.value = "";
      checkValues.value = [];
      clearFilters();
      initFilter();
      searchFilter();
    }

    watchEffect(() => {
      if (select.value) {
        label.value = select.value;
        if (select.value === "all_departments") clearResults();
        searchFilter();
      }
    });

    const setArchivedJobs = (data: Array<string>) => {
      let auxArray = [];
      if (data.includes("archived"))
        auxArray.push(
          "inactive",
          "completed",
          "on_hold_by_client",
          "changed_in_scope"
        );
      if (data.includes("draft")) auxArray.push("draft");
      if (data.includes("published")) auxArray.push("published");
      if (data.includes("pending_approval")) auxArray.push("pending_approval");
      return auxArray;
    };

    const updateHandler = (e: any) => {
      isLoading.value = true;
      setPage(e);
      getAllResults();
    };

    const acceptManageJob = async (job: any) => {
      showConfirmationModal.value = true;
      jobName.value = job.title;
      const confirmed = await confirmationModal.ask();
      if (confirmed) {
        let confirmation = {
          confirmation: true,
        };
        JobsAPI.managedJobConfirmation(job.id, confirmation)
          .then(() => {
            store.dispatch("getJobState", job.id);
            createToastAxelerate("Job", "Job updated", "success");
            getAllResults();
          })
          .catch(() => {
            createToastAxelerate("Error", "Something went wrong", "danger");
          });
      }

      showConfirmationModal.value = false;
    };

    const searchFilter = () => {
      isLoading.value = true;
      initFilter();
      setPage(1);
      store
        .dispatch("getAllJobs", {
          page: page.value,
          filters: { search: Object.values(filters.value).join("%26") },
        })
        .then(() => (isLoading.value = false))
        .catch(() => (isLoading.value = false));
    };

    const clearFilters = () => {
      select.value = "";
      inputValue.value = "";
      setExclude(false);
    };

    const initFilter = () => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
        deparment: select.value,
        excludeDrafts: exclude.value ? "published" : "",
        job_status: setArchivedJobs(checkValues.value),
        location: radioLocation.value,
      });
    };

    const assignJob = (item: any) => {
      setShowRecruiter(true);
      dataJob.value = item;
    };

    const deactivateJob = (job: any) => {
      if (
        job.job_status === "completed" ||
        job.job_status === "changed_in_scope" ||
        job.job_status === "on_hold_by_client"
      ) {
        const job_data = {
          job_status: "published",
        };
        JobsAPI.deactivateJob(job.id, job_data)
          .then(() => {
            createToastAxelerate(
              "Deactivate Job",
              "The job has been activated successfully",
              "success"
            );
            getAllResults();
          })
          .catch(() => {
            createToastAxelerate(
              "Deactivate Job",
              "An error has ocurred",
              "danger"
            );
          });
      } else {
        setShowCloseJob(true);
        setSelectedId(job.id);
      }
    };

    const createJob = () => {
      if (canAddJobs.value || !hascompanyPlan.value) {
        router.push("/create-job");
      } else {
        showModalUpgrade.value = true;
      }
    };

    return {
      jobs,
      toggleActive,
      isLoading,
      jobsStore,
      getAllResults,
      updateHandler,
      router,
      page,
      inputValue,
      searchFilter,
      clearResults,
      select,
      optionDepartament,
      deparments,
      exclude,
      setExclude,
      filters,
      clearFilters,
      initFilter,
      label,
      setShowModal,
      showModal,
      setSelectedId,
      selectedId,
      openTemplate,
      openChallenge,
      showChallenge,
      setShowChallenge,
      assignJob,
      showRecruiter,
      setShowRecruiter,
      dataJob,
      expand,
      userId,
      locations,
      setLocations,
      jobStatus,
      checkValues,
      radioLocation,
      setArchivedJobs,
      deactivateJob,
      setShowCloseJob,
      showCloseJob,
      canAddJobs,
      createJob,
      showModalUpgrade,
      acceptManageJob,
      managedJobConfirmation,
      showConfirmationModal,
      profile,
      confirmationModal,
      jobName,
    };
  },
});

export default Jobs;
