
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "Custom Dropdown",
  emits: ["update:modelValue"],
  components: {},
  props: {
    filters: {
      type: Array,
      default: () => [{}],
    },
    label: {
      type: String,
      default: "All",
    },
  },
  setup(props, { emit }) {
    const openFilter = ref(false);
    const selectedFilter = ref("");

    function selectFilter(params: any) {
      selectedFilter.value = params.name;
      emit("update:modelValue", params.id);
      closeMenu();
    }

    function closeMenu() {
      openFilter.value = false;
    }

    onMounted(() => {
      selectedFilter.value = props.label;
    });

    return {
      openFilter,
      selectedFilter,
      selectFilter,
      closeMenu,
    };
  },
});
