import { MutationTree } from "vuex";
import { SubscriptionsInt } from "./types";

export const mutations: MutationTree<SubscriptionsInt> = {
  SUBSCRIPTIONS_FULLFILED(state, payload: []) {
    state.subscriptions = payload;
  },
  RESET_SUBSCRIPTIONS(state) {
    state.subscriptions = [];
  },
  SUBSCRIPTIONS_REJECTED(state, payload: string) {
    state.error = payload;
  },
  PAYMENTS_FULLFILED(state, payload: []) {
    state.payments = payload;
  },
  RESET_PAYMENTS(state) {
    state.payments = [];
  },
  PAYMENTS_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
