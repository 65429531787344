
import { defineComponent, ref, watchEffect } from "vue";

const AutocompleteLocation = defineComponent({
  name: "AutocompleteLocation",
  emits: ["update:modelValue", "validAddress"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    locationName: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const address = ref("");
    const validAddress = ref(true);
    const options = {
      componentRestrictions: {
        country: [
          "us",
          "mx",
          "ca",
          "es",
          "co",
          "cl",
          "cr",
          "ec",
          "gt",
          "hn",
          "ni",
          "pa",
          "pe",
          "pr",
          "py",
          "sv",
          "ve",
        ],
      },
    };

    const validate = "administrative_area_level_1";

    /* eslint-disable @typescript-eslint/no-explicit-any */
    function setPlace(event: any) {
      validAddress.value = validateLocation(event.address_components);
      if (!validAddress.value) {
        emit("update:modelValue", null);
        setTimeout(() => {
          (document.getElementById("location") as HTMLInputElement).value = "";
          validAddress.value = true;
        }, 5000);
      } else {
        emit("update:modelValue", event.place_id);
      }
    }

    function validateLocation(location: any) {
      let isLocationValid = false;
      Object.values(location).forEach((data: any) => {
        data.types.forEach((type: any) => {
          if (type === validate) {
            isLocationValid = true;
          }
        });
      });
      return isLocationValid;
    }

    function clearInput() {
      address.value = "";
      emit("update:modelValue", null);
    }

    watchEffect(() => {
      if (props.locationName) {
        address.value = props.locationName;
      }
    });

    return {
      setPlace,
      address,
      clearInput,
      options,
      validAddress,
    };
  },
});
export default AutocompleteLocation;
