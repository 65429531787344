import { Module } from "vuex";
import { RootState } from "../types";
import { EvaluationsInterface } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  error: "",
  data: [],
} as EvaluationsInterface;

export const talentEvaluation: Module<EvaluationsInterface, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
