
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, onMounted } from "vue";
const SearchInput = defineComponent({
  name: "SearchInput",
  emits: ["onSubmit", "update:modelValue", "clearResults"],
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    clearButton: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },

  setup(props, context) {
    const clear = ref(false);
    const search = (e: any) => {
      if (e.key === "Enter") {
        context.emit("onSubmit");
      }
    };
    const checkValue = (e: any) => {
      if (e.target.value.length >= 0) {
        clear.value = true;
      } else {
        clear.value = false;
      }
    };

    function clearSearch() {
      context.emit("clearResults");
    }

    onMounted(() => {
      clear.value = props.modelValue ? true : false;
    });

    return { search, checkValue, clearSearch, clear };
  },
});
export default SearchInput;
