import { MutationTree } from "vuex";
import { EvaluationsInterface } from "./types";

export const mutations: MutationTree<EvaluationsInterface> = {
  EVALUATIONS_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  EVALUATIONS_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
