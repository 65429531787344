/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";
export default class EmailsAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  /**
   * @todo Add payload data type
   */
  async saveEmailTimelineEvent(payload: any): Promise<unknown> {
    return this.axiosInstance.post('/email_messages', payload)
  }
}
export const emailsAPI = new EmailsAPIService();
