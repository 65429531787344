
/* eslint-disable @typescript-eslint/no-empty-function */
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

const CardsCount = defineComponent({
  name: "CardsCount",
  props: {
    analytics: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const buildData: any = ref([]);
    const store = useStore();
    const isLegacy = computed(() => store.state.profile.data.company.is_legacy);
    const legacyAnalitics = ref([
      {
        label: "Open jobs",
        value: props?.analytics?.open_jobs ? props.analytics.open_jobs : 0,
      },
      {
        label: "Total candidates",
        value: props?.analytics?.candidates ? props?.analytics?.candidates : 0,
      },
      {
        label: "Candidates in process",
        value: props?.analytics?.candidates_in_process
          ? props?.analytics?.candidates_in_process
          : 0,
      },
      {
        label: "Offers",
        value: props?.analytics?.offer_candidates
          ? props?.analytics?.offer_candidates
          : 0,
      },
      {
        label: "Hired candidates",
        value: props?.analytics?.hired_candidates
          ? props?.analytics?.hired_candidates
          : 0,
      },

      {
        label: "Closed jobs",
        value: props?.analytics?.closed_jobs
          ? props?.analytics?.closed_jobs
          : 0,
      },
    ]);
    const planAnalitics = ref([
      {
        label: "Open jobs",
        value: `${props?.analytics?.open_jobs}`,
      },
      {
        label: "Total candidates",
        value: `${props?.analytics?.candidates}`,
      },
      {
        label: "Candidates in process",
        value: props?.analytics?.candidates_in_process,
      },
      {
        label: "Offers",
        value: props?.analytics?.offer_candidates,
      },
      {
        label: "Hired candidates",
        value: props?.analytics?.hired_candidates,
      },
      {
        label: "Closed jobs",
        value: props?.analytics?.closed_jobs,
      },
    ]);
    buildData.value =
      isLegacy.value === "Growth"
        ? legacyAnalitics.value
        : planAnalitics.value;

    return { buildData };
  },
});
export default CardsCount;
