
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

const MenuMember = defineComponent({
  name: "MenuMember",
  emits: ["openEdit", "deleteMember"],
  props: {
    isSuperAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const openEdit = () => {
      context.emit("openEdit");
    };
    const deleteMember = () => {
      context.emit("deleteMember");
      isMenuOpen.value = false;
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    return {
      isMenuOpen,
      closeMenu,
      openEdit,
      deleteMember,
      router,
    };
  },
});
export default MenuMember;
