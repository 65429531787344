
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useState } from "../../composables/useState";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import PasswordField from "../../components/passwordField/index.vue";
import { userAPI } from "../../services/user";
import { createToastAxelerate } from "../../utils/toast";

const ResetPassword = defineComponent({
  name: "ResetPassword",
  components: {
    PasswordField,
  },
  setup() {
    const [remember, setRemember] = useState(false);
    const route = useRoute();
    const router = useRouter();
    const schema = yup.object({
      password: yup
        .string()
        .required()
        .matches(
          /[a-z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[A-Z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[@$!%*#?&."'<>+-]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /\d+/,
          "Must contain at least one uppercase, special character and number"
        )
        .min(8),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

    const { value: confirm } = useField("password_confirmation");
    const { value: password } = useField("password");
    const { meta, handleSubmit } = useForm({
      initialValues: {
        password_confirmation: "",
        password: "",
      },
      validationSchema: schema,
    });
    const onSubmit = handleSubmit((values) => {
      const builResponse = {
        token_id: route.query.token,
        ...values,
      };
      userAPI
        .resetPassword(builResponse)
        .then(() => {
          createToastAxelerate(
            "Set Password",
            "The password has been changed. ",
            "success",
            () => window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL)
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Set Password", errors[0], "danger", () =>
            router.push("/log-in")
          );
        });
    });

    return { onSubmit, confirm, password, remember, setRemember, meta };
  },
});
export default ResetPassword;
