import { MutationTree } from "vuex";
import { StripesInt } from "./types";

export const mutations: MutationTree<StripesInt> = {
  STRIPES_FULLFILED(state, payload: []) {
    state.stripes = payload;
  },
  RESET_STRIPES(state) {
    state.stripes = [];
  },
  STRIPES_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
