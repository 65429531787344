
import { defineComponent } from "vue";

const SwitchButton = defineComponent({
  name: "SwitchButton",
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "fa-lg",
    },
    fieldId: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
    },
    labelClass: {
      type: String,
      default: "text-sm text-primary-dark",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

export default SwitchButton;
