import { MutationTree } from "vuex";
import { JobCandidateInt } from "./types";

export const mutations: MutationTree<JobCandidateInt> = {
  JOB_CANDIDATES_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  RESET_JOB_CANDIDATES(state) {
    state.data = [];
  },
  JOB_CANDIDATES_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
