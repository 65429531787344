import { MutationTree } from "vuex";
import { TemplatesInt } from "./types";

export const mutations: MutationTree<TemplatesInt> = {
  TEMPLATE_DESCRIPTION_FULLFILED(state, payload: string) {
    state.descriptions = payload;
  },
  TEMPLATE_RESPONSIBILITIES_FULLFILED(state, payload: []) {
    state.responsibilities = payload;
  },
  TEMPLATE_REQUIREMENTS_FULLFILED(state, payload: []) {
    state.requirements = payload;
  },
  TEMPLATE_BENEFITS_FULLFILED(state, payload: []) {
    state.benefits = payload;
  },
  RESET_TEMPLATES(state) {
    state.descriptions = "";
    state.responsibilities = [];
    state.requirements = [];
    state.benefits = [];
  },
};
