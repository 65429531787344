
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, ref } from "vue";
import { RecruiterAPI } from "../../services/recruiters/index";
import { JobsAPI } from "../../services/jobs/index";
import { useState } from "@/composables/useState";
import Avatar from "../avatar/index.vue";
import Paginator from "../../components/paginator/index.vue";
import Skeleton from "../skeleton/index.vue";
import SearchInput from "../searchInput/index.vue";
import { createToastAxelerate } from "../../utils/toast";

const AddRecruiterToJob = defineComponent({
  name: "AddRecruiterToJob",
  emits: ["reload", "close"],
  props: {
    job: {
      type: Object,
      default: () => Object,
    },
  },
  components: {
    Avatar,
    Paginator,
    Skeleton,
    SearchInput,
  },
  setup(props, context) {
    const [data, setData] = useState([]);
    const recruiterSelected = ref("");
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const paginator = ref({});

    onBeforeMount(() => {
      getClients();
    });

    const getClients = async (newPage = 1) => {
      setIsLoading(true);
      setPage(newPage);
      RecruiterAPI.getInternals()
        .then((response: any) => {
          setData(response.data.account_members);
          paginator.value = response.data.meta;
          setIsLoading(false);
        })
        .catch(() => {
          createToastAxelerate(
            "Get internals",
            "An erro has ocurred",
            "danger"
          );
          setIsLoading(false);
        });
    };

    const updateJob = (id: string) => {
      JobsAPI.addRecruiterToJob(props.job.job_id, id)
        .then(async () => {
          await context.emit("reload");
          await context.emit("close");
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    const updateHandler = (e: number) => {
      setPage(e);
      getClients(e);
    };
    return {
      data,
      isLoading,
      updateHandler,
      getClients,
      page,
      recruiterSelected,
      updateJob,
      paginator,
    };
  },
});

export default AddRecruiterToJob;
