<template>
  <div
    class="
      min-h-screen
      flex flex-col
      items-center
      justify-center
      rounded
      bg-background
    "
  >
    <div
      class="
        flex flex-col
        bg-white
        lg:shadow-md
        px-4
        sm:px-6
        md:px-8
        lg:px-10
        py-8
        rounded-lg
        w-full
        max-w-md
      "
    >
      <img
        src="../../assets/Axelerate_logo.svg"
        alt="login logo"
        class="w-5/12 lg:pb-5"
      />
      <div class="font-bold self-start text-xl sm:text-2xl text-gray">
        {{
          currentStep === 0 ? "Try Axelerate for free" : "Just one last step"
        }}
      </div>
      <div class="mt-10">
        <div id="test">
          <form @submit="nextStep">
            <div v-show="currentStep === 0">
              <div
                v-for="(item, index) in firstFieldValues"
                class="flex flex-col mb-6"
                :key="index"
              >
                <div class="relative">
                  <TextField
                    v-model="item.model"
                    :label="item.label"
                    :typeInput="item.type"
                    :name="item.name"
                  />
                </div>
              </div>
              <div class="flex flex-col mb-6">
                <div class="relative z-0 w-full mb-2">
                  <div class="relative">
                    <PasswordField
                      v-model="formValues.password"
                      label="Password"
                      name="password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="currentStep === 1">
              <div
                v-for="(item, index) in secondFieldValues"
                class="flex flex-col mb-6"
                :key="index"
              >
                <div class="relative">
                  <TextField
                    v-model="item.model"
                    :disabled="item.disabled"
                    :label="item.label"
                    :typeInput="item.type"
                    :name="item.name"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="
                flex
                items-center
                justify-center
                focus:outline-none
                text-white text-sm
                sm:text-base
                bg-primary
                rounded-full
                py-2
                w-full
                transition
                duration-150
                ease-in
                h-14
              "
            >
              Register
            </button>
          </form>
        </div>
      </div>
      <div
        v-if="currentStep !== 1"
        class="flex justify-center items-center mt-8 text-base"
      >
        <span>Already have an Axelerate account?</span>
        <router-link to="/log-in">
          <span
            class="
              ml-3
              inline-flex
              items-center
              text-primary
              font-bold
              text-base text-centerml-2
            "
            >Log in</span
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useField, useForm } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";
import { ref, reactive, computed, defineComponent, onBeforeMount } from "vue";
import TextField from "../../components/textField/index.vue";
import PasswordField from "../../components/passwordField/index.vue";
import { userAPI } from "../../services/user";
import { createToastAxelerate } from "../../utils/toast";
import { useStore } from "vuex";

const SignUp = defineComponent({
  name: "SignUp",
  components: {
    TextField,
    PasswordField,
  },
  setup: () => {
    const currentStep = ref(0);
    const formValues = reactive({});
    const router = useRouter();
    const route = useRoute();
    const token = route.query.token;
    const store = useStore();
    const { value: firstName } = useField("first_name");
    const { value: lastName } = useField("last_name");
    const { value: email } = useField("email");
    const { value: company } = useField("company_name");
    const { value: website } = useField("website");
    const { value: industry } = useField("industry");
    const { value: phone } = useField("phone");
    onBeforeMount(() => {
      userAPI.getUserRegister(token).then((res) => {
        setValues(res.data);
      });
    });
    const firstFieldValues = ref([
      {
        name: "first_name",
        label: "First name",
        type: "text",
        model: firstName,
      },
      { name: "last_name", label: "Last name", type: "text", model: lastName },
      { name: "email", label: "Email", type: "email", model: email },
    ]);
    const secondFieldValues = ref([
      {
        name: "visible_name",
        label: "Company name",
        type: "text",
        disabled: false,
        model: company,
      },
      {
        name: "website",
        label: "Company website",
        type: "text",
        disabled: false,
        model: website,
      },
      {
        name: "industry",
        label: "Industry",
        type: "text",
        disabled: false,
        model: industry,
      },
      {
        name: "phone",
        label: "Phone number (Optional)",
        type: "tel",
        disabled: false,
        model: phone,
      },
    ]);

    const schemas = [
      yup.object({
        first_name: yup.string().required().max(50).min(3).label("First name"),
        last_name: yup.string().required().max(50).min(3).label("Last name"),
        email: yup.string().required().email(),
        password: yup
          .string()
          .required()
          .matches(
            /[0-9A-Za-z]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?][0-9a-zA-Z]*$/,
            "Must contain at least one uppercase, special character and number"
          ),
      }),
      yup.object({
        visible_name: yup
          .string()
          .required()
          .max(50)
          .min(3)
          .label("Visible name"),
        website: yup
          .string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter a correct url"
          )
          .required(),
        industry: yup.string().required().max(50).min(3),
        phone: yup.string().nullable(),
      }),
    ];

    const currentSchema = computed(() => {
      return schemas[currentStep.value];
    });
    const { handleSubmit, setValues } = useForm({
      validationSchema: currentSchema,
    });
    const nextStep = handleSubmit((values) => {
      if (currentStep.value === 1) {
        Object.assign(formValues, values);
        const objRequest = {
          user: {
            ...formValues,
            roles: ["admin"],
            password_confirmation: formValues.password,
          },
        };
        userAPI
          .updateSignUp(token, objRequest)
          .then(async (res) => {
            await localStorage.setItem("token", JSON.stringify(res.data.token));
            await store.commit("lOGIN_FULLFILED", res.data.token);
            await router.push("/subscriptions");
          })
          .catch(() => {
            createToastAxelerate(
              "Sign Up",
              "An error has occurred. ",
              "danger"
            );
          });
        return;
      }
      // accumlate the form values with the values from previous steps
      Object.assign(formValues, values);
      currentStep.value++;
    });
    const prevStep = () => {
      if (currentStep.value <= 0) {
        return;
      }
      currentStep.value--;
    };
    return {
      currentStep,
      firstFieldValues,
      secondFieldValues,
      currentSchema,
      prevStep,
      formValues,
      nextStep,
    };
  },
});
export default SignUp;
</script>
