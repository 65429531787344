
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, onBeforeMount, watchEffect } from "vue";
import RadioButton from "../../components/radioButton/index.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { JobsAPI } from "../../services/jobs/index";
import { useStore } from "vuex";

const DescriptionTemplates = defineComponent({
  name: "DescriptionTemplates",
  emits: ["closeModal", "addToInput"],
  components: {
    RadioButton,
    Loading,
  },
  setup(props, context) {
    const radioOption = ref("");
    const store = useStore();
    const isLoading = ref(false);
    const templeteOptions = ref([]);
    const templateDescription = ref({});
    const content = ref("");
    onBeforeMount(() => {
      isLoading.value = true;
      JobsAPI.getJobTemplates({ by_type: 0 }).then((res: any) => {
        isLoading.value = false;
        templeteOptions.value = res.data;
      });
    });

    const getTemplateInfo = () => {
      isLoading.value = true;
      JobsAPI.getOneTemplate(radioOption.value).then((res: any) => {
        isLoading.value = false;
        templateDescription.value = res.data;
        content.value = JSON.parse(res.data.content)[0];
      });
    };
    watchEffect(() => {
      if (radioOption.value !== "") {
        getTemplateInfo();
      }
    });
    const closeModal = () => {
      context.emit("closeModal");
    };
    const saveTemplate = () => {
      store.commit("TEMPLATE_DESCRIPTION_FULLFILED", content.value);
      closeModal();
      context.emit("addToInput");
    };
    return {
      templeteOptions,
      radioOption,
      isLoading,
      templateDescription,
      content,
      saveTemplate,
      closeModal,
    };
  },
});
export default DescriptionTemplates;
