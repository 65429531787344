
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  ref,
  watchEffect,
  computed,
  onMounted,
  Ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import TextField from "../../components/textField/index.vue";
import TextArea from "../../components/textArea/index.vue";
import CurrencyField from "../../components/currencyField/index.vue";
import DropDown from "../../components/selectDropdown/index.vue";
import Tooltip from "../helpTooltip/index.vue";
import ReusableModal from "../reusableModal/index.vue";
import WorkFlowFields from "../workflowFields/index.vue";
import DescriptionTemplates from "../descriptionTemplates/index.vue";
import ResponsabilitiesTemplates from "../responsabilitiesTemplate/index.vue";
import RequirementsTemplates from "../requirementsTemplates/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import { useRoute } from "vue-router";
import Button from "../button/index.vue";
import Bullets from "../bullets/index.vue";
import Chips from "../chips/index.vue";
import AutocompleteLocation from "../../components/autocompleteLocation/index.vue";
import { numberFormattedWithCommas } from "../../utils/functions";
import CheckBox from "../simpleCheckbox/index.vue";
import {
  optionCountry,
  optionDepartament,
  optionsEmployment,
  optionsExperience,
  optionsEducation,
  preferredWorkType,
} from "../../constants/jobs";
import {
  labelsTitle,
  labelsLocation,
  labelsCompany,
  labelsDetails,
  labelSkills,
  labelSalary,
} from "../../constants/createJob";
import { createToastAxelerate } from "@/utils/toast";
import { useState } from "@/composables/useState";
const CreateJobForm = defineComponent({
  name: "CreateJobForm",
  emits: [
    "onDraft",
    "onPublish",
    "openWorkFlow",
    "setRequiredSkills",
    "setNiceToHaveSkills",
  ],
  components: {
    TextField,
    TextArea,
    CurrencyField,
    DropDown,
    Tooltip,
    ReusableModal,
    DescriptionTemplates,
    ResponsabilitiesTemplates,
    RequirementsTemplates,
    WorkFlowFields,
    Button,
    Bullets,
    Chips,
    CheckBox,
    AutocompleteLocation,
  },
  props: {
    jobData: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const arrayRequiredValue = ref(10);
    const arrayNiceHaveValue = ref(10);
    const store = useStore();
    const route = useRoute();
    const industries = ref([]);
    const jobFunctions = ref([]);
    const responsabilities = ref("<li></li>");
    const requirements = ref("<li></li>");
    const job_benefits = ref("<li></li>");
    const requiredSkillSearch = ref("");
    const nicetohaveSkillSearch = ref("");
    const defaultSkills: Ref<any> = ref([]);
    const requiredSkills: Ref<any> = ref([]);
    const niceToHaveSkills: Ref<any> = ref([]);
    const status = ref("");
    const count = ref(0);
    const place_id = ref("");
    const optionCurrencies = ref([]);
    const salaryMonthly = ref(false);
    const jobLocationName = ref("");
    const visibleSkillsRequired: Ref<any> = ref([]);
    const visibleSkillsRequiredSelected: Ref<any> = ref([]);
    const visibleSkillsNiceToHave: Ref<any> = ref([]);
    const visibleSkillsNicetohaveSelected: Ref<any> = ref([]);
    const showMoreSkillsR: Ref<boolean> = ref(false);
    const showMoreSkillsNTH: Ref<boolean> = ref(false);
    const [salaryType, setSalaryType] = useState("neto");
    const companyIndustryId = computed(
      () => store.state.profile.data.company.company_industry?.id
    );
    const schema = yup.object({
      title: yup.string().required().label("Job title"),
      is_remote: yup.boolean().label("Remote").nullable(),
      salary_min: yup
        .number()
        .required()
        .typeError("Min is a required field")
        .nullable()
        .moreThan(0, "Must be greater than 0")
        .transform((_value, originalValue) => {
          if (typeof originalValue == "string") {
            return parseFloat(originalValue?.replace(/,/g, ""));
          }

          return originalValue;
        }),
      salary_max: yup
        .number()
        .nullable()
        .required()
        .typeError("Max is a required field")
        .moreThan(yup.ref("salary_min"), "Must be greater than the min range")
        .transform((_value, originalValue) => {
          if (typeof originalValue == "string") {
            return parseFloat(originalValue?.replace(/,/g, ""));
          }

          return originalValue;
        }),
      description: yup.string().label("Description").nullable(),
      department: yup.string().label("Department").nullable(),
      company_industry_id: yup.string().label("Industry").nullable(),
      job_function_id: yup.string().label("Job function").nullable(),
      employment_type: yup.string().label("Employment type").nullable(),
      education: yup.string().label("Education").nullable(),
      country: yup.string().label("Country").nullable(),
      experience: yup.string().label("Experience").nullable(),
      currency_salary: yup.string().label("Currency").required().nullable(),
      department_code: yup.string().nullable().label("Internal code"),
      location: yup.string().label("Region").nullable(),
      preferred_work: yup.string().label("Preferred work").nullable(),
    });
    const { value: toSalary } = useField("salary_max", undefined, {
      initialValue: 0,
    });
    const { value: fromSalary } = useField("salary_min", undefined, {
      initialValue: 0,
    });
    const descriptionState = computed(() => store.state.templates.descriptions);
    const responsibilitiesState = computed(
      () => store.state.templates.responsibilities
    );
    const requirementsState = computed(
      () => store.state.templates.requirements
    );
    const showModalTemplate = ref(false);
    const showModal = ref(false);
    const renderOption = ref("");
    const { value: jobTitle } = useField("title");
    const { value: department } = useField("department");
    const { value: internalCode } = useField("department_code");
    const { value: region } = useField("location");
    const { value: companyIndustry } = useField("company_industry_id");
    const { value: jobFunction } = useField("job_function_id");
    const { value: employmentType } = useField("employment_type");
    const { value: education } = useField("education");
    const { value: experience } = useField("experience");
    const { value: currencySalary } = useField("currency_salary");
    const { value: description } = useField("description");
    const { value: country } = useField("country");
    const { value: preferredWork } = useField("preferred_work");
    const { meta, handleSubmit, setFieldValue, setValues, submitCount } =
      useForm({
        validationSchema: schema,
      });
    const textAreaData = ref([
      {
        name: "responsabilities",
        label: "Responsibilities",
        model: responsabilities,
        placeholder:
          "Enter the job responsibilities here; include key areas of responsibility and what the candidate might do on a typical day.",
        buttonText: "Import from templates",
        render: "ResponsabilitiesTemplates",
        default: (responsabilities.value = "<li></li>"),
      },
      {
        name: "requirements",
        label: "Requirements",
        model: requirements,
        placeholder:
          "Enter the job requirements here; from soft skills to the specific qualifications needed to perform this role.",
        buttonText: "Import from templates",
        render: "RequirementsTemplates",
        default: (requirements.value = "<li></li>"),
      },
      {
        name: "job_benefits",
        label: "Benefits",
        model: job_benefits,
        placeholder:
          "Enter the benefits here; include not just salary details but the perks that make your company unique.",
        default: (requirements.value = "<li></li>"),
      },
    ]);
    const onSubmit = handleSubmit((values) => {
      const buildResponse = {
        ...values,
        salary_type: salaryType.value,
        currency_id: currencySalary.value,
        show_monthly_salary: salaryMonthly.value,
        place_id: place_id.value,
        responsabilities: responsabilities.value,
        requirements: requirements.value,
        required_skills: requiredSkills.value,
        optional_skills: niceToHaveSkills.value,
        job_benefits: job_benefits.value,
        salary_min: Number(
          values?.salary_min?.toString()?.replace(/,/g, "") || ""
        ),
        salary_max: Number(
          values?.salary_max?.toString()?.replace(/,/g, "") || ""
        ),
      };
      return buildResponse;
    });
    const openModal = () => (showModal.value = true);
    const openTemplates = (option: string) => {
      renderOption.value = option;
      showModalTemplate.value = true;
      switch (renderOption.value) {
        case "DescriptionTemplates":
          setFieldValue("description", "");
          break;
        case "RequirementsTemplates":
          requirements.value = "";
          break;
        case "ResponsabilitiesTemplates":
          responsabilities.value = "";
          break;
        default:
          break;
      }
    };
    const closeModalTemplate = () => {
      showModalTemplate.value = false;
    };
    const addToInput = () => {
      switch (renderOption.value) {
        case "DescriptionTemplates":
          setFieldValue("description", descriptionState.value);
          break;
        case "ResponsabilitiesTemplates":
          responsabilities.value = responsibilitiesState.value.join("");
          break;
        case "RequirementsTemplates":
          requirements.value = requirementsState.value.join("");
          break;
        default:
          break;
      }
    };
    const updateHandler = (data: string) => {
      place_id.value = data;
    };
    const onDraft = () => context.emit("onDraft");
    const onPublish = () => context.emit("onPublish");
    const openWorkFlow = () => context.emit("openWorkFlow");
    const setRequiredSkills = () =>
      context.emit("setRequiredSkills", requiredSkills);
    const setNiceToHaveSkills = () =>
      context.emit("setNiceToHaveSkills", niceToHaveSkills);

    watch(requiredSkills, () => {
      setRequiredSkills();
    });

    watch(niceToHaveSkills, () => {
      setNiceToHaveSkills();
    });

    async function getSkills() {
      await JobsAPI.getSkills().then((res: any) => {
        visibleSkillsRequired.value = res.data.skills;
        visibleSkillsNiceToHave.value = res.data.skills;
        visibleSkillsRequiredSelected.value = visibleSkillsRequired.value;
        visibleSkillsNicetohaveSelected.value = visibleSkillsNiceToHave.value;
      });
    }

    const filterSkillsRequired = () => {
      if (requiredSkillSearch.value.length > 0) {
        visibleSkillsRequiredSelected.value =
          visibleSkillsRequired.value.filter((skill: any) =>
            skill.name.includes(requiredSkillSearch.value)
          );
      } else {
        visibleSkillsRequiredSelected.value = visibleSkillsRequired.value;
      }
    };

    const filterSkillsNicetohave = () => {
      if (nicetohaveSkillSearch.value.length > 0) {
        visibleSkillsNicetohaveSelected.value =
          visibleSkillsNiceToHave.value.filter((skill: any) =>
            skill.name.includes(nicetohaveSkillSearch.value)
          );
      } else {
        visibleSkillsNicetohaveSelected.value = visibleSkillsNiceToHave.value;
      }
    };

    const showMoreRequiredSkills = () => {
      arrayRequiredValue.value =
        visibleSkillsRequired.value.length >= arrayRequiredValue.value
          ? arrayRequiredValue.value + 20
          : 10;
    };

    const showMoreNiceHaveSkills = () => {
      arrayNiceHaveValue.value =
        visibleSkillsNiceToHave.value.length >= arrayNiceHaveValue.value
          ? arrayNiceHaveValue.value + 20
          : 10;
    };

    function pushToRequired(name: string) {
      const unique = [...requiredSkills.value];
      unique.push(name);
      requiredSkills.value = unique.filter((v, i, a) => a.indexOf(v) === i);
    }

    function pushToNiceToHave(name: string) {
      const unique = [...niceToHaveSkills.value];
      unique.push(name);
      niceToHaveSkills.value = unique.filter((v, i, a) => a.indexOf(v) === i);
      niceToHaveSkills.value = niceToHaveSkills.value.reverse();
    }

    onMounted(async () => {
      await getCurrencies();
      getSkills();
      store.commit("RESET_TITLE");
      store.commit("RESET_TEMPLATES");

      const getJobFunctions = JobsAPI.getJobFunctions();
      const getIndustries = JobsAPI.getCompanyIndustries();

      await Promise.all([getJobFunctions, getIndustries]).then((res: any) => {
        jobFunctions.value = res[0].data;
        industries.value = res[1].data;
      });
    });

    const getCurrencies = () => {
      JobsAPI.getCurrencies()
        .then((response: any) => {
          optionCurrencies.value = response?.data.currencies;
        })
        .catch((error) => {
          createToastAxelerate(
            "Get currencies",
            "An error has ocurred",
            "danger"
          );
        });
    };

    const getCurrencyCode = (id: string) => {
      var index = optionCurrencies.value.findIndex(
        (currency: any) => currency.id === id
      );
      return optionCurrencies.value[index]["code"];
    };

    watch(
      () => props.jobData.id,
      () => {
        jobLocationName.value = props.jobData.job_location?.location?.place_id
          ? props.jobData.job_location?.location?.street_address
          : null;
        setValues({
          title: props.jobData.title,
          is_remote: props.jobData.is_remote,
          department: props.jobData.department || "",
          department_code: props.jobData.department_code || "",
          location: props.jobData.location,
          job_function_id: props.jobData.job_function_id,
          employment_type: props.jobData.employment_type,
          education: props.jobData.education,
          experience: props.jobData.experience,
          currency_salary: props.jobData.currency_id,
          preferred_work: props.jobData.work_type,
          description: props.jobData.description,
          country: props.jobData.country,
          salary_min:
            props.jobData.salary_min &&
            props.jobData.salary_min.annual.toString(),
          salary_max:
            props.jobData.salary_max &&
            props.jobData.salary_max.annual.toString(),
        });
        setSalaryType(props.jobData.salary_type);
        currencySalary.value = props.jobData.currency_id;
        fromSalary.value = props.jobData.salary_min.annual.toString();
        toSalary.value = props.jobData.salary_max.annual.toString();
        salaryMonthly.value = props.jobData.show_monthly_salary;
        responsabilities.value = props.jobData.responsabilities;
        requirements.value = props.jobData.requirements;
        job_benefits.value = props.jobData.job_benefits;
        requiredSkills.value = props.jobData.required_skills;
        niceToHaveSkills.value = props.jobData.optional_skills;
        place_id.value = props.jobData.job_location?.location?.place_id;
      }
    );

    watchEffect(() => {
      if (jobTitle.value !== undefined) {
        store.commit("WATCH_TITLE_FULLFILED", jobTitle.value);
      }
      if (responsabilities.value === "" || !responsabilities.value) {
        responsabilities.value = "<li></li>";
      }
      if (requirements.value === "" || !requirements.value) {
        requirements.value = "<li></li>";
      }
      if (job_benefits.value === "" || !job_benefits.value) {
        job_benefits.value = "<li></li>";
      }
    });

    return {
      onDraft,
      onPublish,
      openWorkFlow,
      onSubmit,
      handleSubmit,
      openTemplates,
      updateHandler,
      renderOption,
      textAreaData,
      meta,
      jobTitle,
      department,
      description,
      job_benefits,
      internalCode,
      region,
      companyIndustry,
      jobFunction,
      employmentType,
      experience,
      currencySalary,
      education,
      toSalary,
      fromSalary,
      country,
      optionCountry,
      optionDepartament,
      optionsEmployment,
      optionsExperience,
      optionsEducation,
      optionCurrencies,
      showModal,
      openModal,
      showModalTemplate,
      closeModalTemplate,
      addToInput,
      industries,
      jobFunctions,
      route,
      submitCount,
      status,
      count,
      labelsTitle,
      labelsLocation,
      labelsCompany,
      labelsDetails,
      labelSkills,
      labelSalary,
      place_id,
      jobLocationName,
      companyIndustryId,
      defaultSkills,
      requiredSkills,
      niceToHaveSkills,
      pushToRequired,
      pushToNiceToHave,
      showMoreSkillsR,
      showMoreSkillsNTH,
      visibleSkillsRequired,
      visibleSkillsNiceToHave,
      requiredSkillSearch,
      visibleSkillsRequiredSelected,
      visibleSkillsNicetohaveSelected,
      filterSkillsRequired,
      nicetohaveSkillSearch,
      filterSkillsNicetohave,
      arrayRequiredValue,
      arrayNiceHaveValue,
      showMoreRequiredSkills,
      showMoreNiceHaveSkills,
      numberFormattedWithCommas,
      salaryMonthly,
      preferredWork,
      preferredWorkType,
      getCurrencyCode,
      salaryType,
      setSalaryType,
    };
  },
});

export default CreateJobForm;
