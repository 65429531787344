export const mxPrices = {
  managed_job: "12,999",
  ai_service: "249",
  currency: "MXN",
};
export const usaPrices = {
  managed_job: "249",
  ai_service: "14.99",
  currency: "USD",
};
