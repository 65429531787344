
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useState } from "../../composables/useState";

const MenuJob = defineComponent({
  name: "MenuJob",
  emits: [
    "deleteJob",
    "openChallenge",
    "openEvaluation",
    "deactivateJob",
    "assignJob",
    "acceptManageJob",
  ],
  props: {
    jobId: {
      type: String,
      default: "",
    },
    hasChallenge: {
      type: Boolean,
      default: false,
    },
    hasEvaluation: {
      type: Boolean,
      default: false,
    },
    hasRecruiter: {
      type: Boolean,
      default: false,
    },
    showAssing: {
      type: Boolean,
      default: false,
    },
    isClosedJob: {
      type: Boolean,
      default: false,
    },
    isManaged: {
      type: Boolean,
      default: false,
    },
    jobStatus: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const router = useRouter();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const deleteJob = () => {
      context.emit("deleteJob");
      setIsMenuOpen(false);
    };
    const deactivateJob = () => {
      context.emit("deactivateJob");
      setIsMenuOpen(false);
    };
    const openChallenge = () => {
      setIsMenuOpen(false);
      context.emit("openChallenge");
    };
    const openEvaluation = () => {
      setIsMenuOpen(false);
      context.emit("openEvaluation");
    };
    const acceptManageJob = () => {
      setIsMenuOpen(false);
      context.emit("acceptManageJob");
    };
    const assignJob = () => {
      setIsMenuOpen(false);
      context.emit("assignJob");
    };
    const closeMenu = () => {
      setIsMenuOpen(false);
    };
    return {
      isMenuOpen,
      setIsMenuOpen,
      closeMenu,
      deleteJob,
      openChallenge,
      openEvaluation,
      deactivateJob,
      assignJob,
      acceptManageJob,
      router,
    };
  },
});
export default MenuJob;
