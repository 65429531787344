/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { IntAddCanidates } from "./types";
import { JobsAPI } from "../../services/jobs/index";

export const actions: ActionTree<IntAddCanidates, RootState> = {
  getJobState({ commit }, id) {
    return new Promise((resolve, reject) => {
      JobsAPI.getOneJob(id)
        .then((data: any) => {
          resolve(false);
          commit("ADD_CANDIDATES_FULLFILED", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("ADD_CANDIDATES_REJECTED", err);
        });
    });
  },
};
