
import { defineComponent, onMounted, ref, Ref } from "vue";
import { JobsAPI } from "@/services/jobs";
import ModalJobMembers from "@/components/modalJobMembers/index.vue";
import Avatar from "@/components/avatar/index.vue";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Job Members Section",
  components: {
    ModalJobMembers,
    Avatar,
  },
  props: {
    jobId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const openModal = ref(false);
    const members: Ref<any> = ref([]);

    function showModal() {
      openModal.value = true;
    }

    function getMembers() {
      JobsAPI.getJobMembers(props.jobId)
        .then((res: any) => {
          members.value = res.data.job.job_members;
        })
        .catch((err: any) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Get job members", errors[0], "danger");
        });
    }

    onMounted(() => {
      getMembers();
    });

    return {
      members,
      showModal,
      openModal,
      getMembers,
    };
  },
});
