/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceLoggedAPI, instanceFormAPI } from "../axiosConfig";

export default class NotificationsAPIService {
  private axiosInstance: AxiosInstance;
  private instanceForm: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.instanceForm = instanceFormAPI;
  }

  async getNotifications(
    page: number,
    category?: string,
    filters?: any
  ): Promise<unknown> {
    return this.instanceForm.get(
      `/notifications?platform=sourcing&category=${category}&page=${page}&${makeFilter(
        filters
      )}`
    );
  }

  async dashboardNotifications(): Promise<unknown> {
    return this.instanceForm.get(
      `/notifications?platform=sourcing&dashboard=true`
    );
  }

  async readNotification(idNotification: string): Promise<unknown> {
    return this.instanceForm.put(`/notifications/${idNotification}`);
  }

  async getJobsNotifications(idNotification: string): Promise<unknown> {
    return this.instanceForm.put(`/notifications/${idNotification}`);
  }

  async readAllNotifications(): Promise<unknown> {
    return this.instanceForm.put("/update_all_notifications");
  }
}
export const NotificationsAPI = new NotificationsAPIService();
