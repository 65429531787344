
import { defineComponent } from "vue";
import Button from "../button/index.vue";
//import SimpleSelect from "../simpleSelect/index.vue";

const WarningModal = defineComponent({
  name: "DeleteCandidate",
  components: {
    Button,
    //SimpleSelect,
  },
  emits: ["closeModal", "emitAction", "update:modelValue"],
  props: {
    message: {
      type: String,
      default: "",
    },
    aditionalComponent: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const closeModal = () => {
      context.emit("closeModal");
    };
    const emitAction = () => {
      context.emit("emitAction");
    };
    return {
      closeModal,
      emitAction,
    };
  },
});

export default WarningModal;
