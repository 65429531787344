import { MutationTree } from "vuex";
import { AuthState } from "./types";

// Use enum to avoid hardcoded functions
export enum UserMutations {
  SET_USEREMAIL = "SET_USEREMAIL",
  lOGIN_FULLFILED = "lOGIN_FULLFILED",
  LOGIN_REJECTED = "lOGIN_FULLFILED",
}

export const mutations: MutationTree<AuthState> = {
  lOGIN_FULLFILED(state, payload: string) {
    state.token = payload;
  },
  LOGIN_REJECTED(state, payload: string) {
    state.error = payload;
  },
  LOGOUT(state) {
    state.token = "";
  },
  LOGOUT_REJECTED(state, payload) {
    state.error = payload;
  },
};
