<template>
  <FieldArray name="evaluation_categories" v-slot="{ fields }">
    <div
      class="flex flex-col py-2 mb-3"
      v-for="(field, idx) in fields"
      :key="field.key"
    >
      <div
        class="flex flex-row space-x-4 w-full bg-primary px-3 py-2 rounded-t-3xl justify-between"
      >
        <div class="w-11/12">
          <Field
            disabled
            :name="`evaluation_categories[${idx}].name`"
            class="bg-primary text-white w-full px-3 text-xl"
          />
        </div>
      </div>
      <GetQuestionArrayField
        :name="`evaluation_categories[${idx}].evaluation_questions`"
        :errors="errors"
      />
    </div>
  </FieldArray>
</template>

<script>
import { defineComponent } from "vue";
import { FieldArray, Field } from "vee-validate";
import { useState } from "../../composables/useState";
import GetQuestionArrayField from "../getQuestionArrayField/index.vue";

const GetCategoryArrayField = defineComponent({
  name: "GetCategoryArrayField",
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    FieldArray,
    Field,
    GetQuestionArrayField,
  },
  setup(props) {
    const [expand, setExpand] = useState(false);
    const [current, setCurrent] = useState(false);
    const existError =
      props.errors &&
      props.errors.categories &&
      Object.keys(props.errors.categories);
    const clickExpand = (index) => {
      setCurrent(index);
    };
    return { expand, setExpand, clickExpand, current, existError };
  },
});

export default GetCategoryArrayField;
</script>
