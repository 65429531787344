import { Module } from "vuex";
import { RootState } from "../types";
import { JobState } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  title: "New Job",
  data: [],
} as JobState;

export const createJob: Module<JobState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
