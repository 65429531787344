import { MutationTree } from "vuex";
import { MembersInt } from "./types";

export const mutations: MutationTree<MembersInt> = {
  MEMBERS_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  RESET_MEMBERS(state) {
    state.data = [];
  },
  MEMBERS_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
