
import { defineComponent } from "vue";
import { timeAgo } from "../../utils/functions";
import MenuEvaluation from "../menuEvaluation/index.vue";

const TalentEvaluationCard = defineComponent({
  name: "TalentEvaluationCard",
  emits: ["archiveTemplate", "copyTemplate"],
  components: {
    MenuEvaluation,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const archiveTemplate = (id: string) => {
      context.emit("archiveTemplate", id);
    };

    const copyTemplate = (id: any) => {
      const template = props.data.find((item: any) => item.id === id);
      context.emit("copyTemplate", template);
    };

    return { timeAgo, archiveTemplate, copyTemplate };
  },
});

export default TalentEvaluationCard;
