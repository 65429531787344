
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Tooltip from "../helpTooltip/index.vue";
const LastSubscription = defineComponent({
  name: "LastSubscription",
  components: {
    Tooltip,
  },
  setup() {
    const store = useStore();
    const data = computed(
      () => store.state.profile.data.company?.last_subscription
    );
    return { data };
  },
});

export default LastSubscription;
