
/* eslint-disable @typescript-eslint/no-explicit-any */
import { userAPI } from "@/services/user";
import { defineComponent, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useState } from "../../composables/useState";
import Loading from "vue-loading-overlay";
import "mosha-vue-toastify/dist/style.css";

const ConfirmEmail = defineComponent({
  name: "ConfirmEmail",
  components: {
    Loading,
  },
  setup() {
    const route = useRoute();
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    onBeforeMount(() => {
      setIsLoading(true);
      userAPI
        .updateToken(route.query.token)
        .then((res: any) => {
          setIsLoading(false);
          setMessage(res.data.message);
        })
        .catch((err: any) => {
          setIsLoading(false);
          setError(true);
          setMessage(err?.response?.data?.errors[0]);
        });
    });
    return {
      route,
      message,
      isLoading,
      error,
    };
  },
});

export default ConfirmEmail;
