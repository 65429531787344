export const checkStage = [
  { value: "Talent Pool", label: "Talent Pool" },
  { value: "Screening", label: "Screening" },
  { value: "Technical Interview", label: "Technical Interview" },
  { value: "Challenge", label: "Challenge" },
  { value: "2nd Interview", label: "2nd Interview" },
  { value: "Offer", label: "Offer" },
  { value: "Hired", label: "Hired" },
];

export const appDateValues = [
  { value: "all_app", label: "All" },
  { value: "last_month", label: "Last month" },
  { value: "last_three_months", label: "Last 3 months" },
  { value: "last_year", label: "Last year" },
];

export const selectStages = [
  { id: "Talent Pool", name: "Talent Pool" },
  { id: "Screening", name: "Screening" },
  { id: "Technical Interview", name: "Technical Interview" },
  { id: "Challenge", name: "Challenge" },
  { id: "2nd Interview", name: "2nd Interview" },
  { id: "Offer", name: "Offer" },
  { id: "Hired", name: "Hired" },
  { id: "Rejected", name: "Rejected" },
];

export const validStages = [
  "Screening",
  "Technical Interview",
  "Challenge",
  "2nd Interview",
  "Offer",
  "Hired",
  "Hiring",
];

export const validStagesChallenge = [
  "Technical Interview",
  "Challenge",
  "2nd Interview",
  "Offer",
  "Hired",
  "Hiring",
];

export const optionCurrencies = [
  { id: "USD", name: "Dollar (USD)" },
  { id: "MXN", name: "Mexican Peso (MXN)" },
];

export const optionSearch = [
  { id: "name", name: "Name" },
  { id: "skills", name: "Skills" },
  { id: "position", name: "Position" },
];
