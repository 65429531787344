
import { useState } from "@/composables/useState";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import CommentsForm from "../commentsForm/index.vue";
import TimeLine from "../timeLine/index.vue";
import Skeleton from "../skeleton/index.vue";
import ScheduleMeetingSection from "../scheduleMeetingTimeline/index.vue";

const TimeLineSection = defineComponent({
  name: "TimeLineSection",
  components: {
    CommentsForm,
    TimeLine,
    Skeleton,
    ScheduleMeetingSection,
  },
  props: {
    userEmail: {
      type: String,
      default: "",
    },
  },
  emits: ["openEdit"],
  setup(props, context) {
    const store = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const candidate = computed(() => store.state.candidateInfo.data.candidate);
    const jobCandidate = computed(() => store.state.candidateInfo.data);
    const candidateId = computed(() => store.state.candidateInfo.data?.id);
    const timeLine = computed(
      () => store.state.timeLine.data.job_candidate_timeline
    );
    const option = ref(1);
    const getTimeLine = () => {
      setIsLoading(true);
      store.commit("RESET_TIMELINE");
      store.dispatch("getTimeLine", candidateId.value).then(() => {
        setIsLoading(false);
      });
    };
    onMounted(() => {
      getTimeLine();
    });
    watchEffect(() => {
      if (candidateId.value) {
        getTimeLine();
      }
    });

    const openEdit = () => {
      context.emit("openEdit");
    };
    return {
      openEdit,
      candidateId,
      timeLine,
      isLoading,
      option,
      candidate,
      jobCandidate,
    };
  },
});

export default TimeLineSection;
