<template>
  <form @submit="onSubmit">
    <div class="flex flex-col mb-6">
      <span class="text-xl font-bold py-3">Add candidate</span>
    </div>
    <div v-for="(item, index) in userFields" :key="index" class="w-full mb-4">
      <div class="relative">
        <TextField
          v-model="item.model"
          :label="item.label"
          :typeInput="item.type"
          :name="item.name"
        />
      </div>
    </div>
    <div class="flex flex-col mb-6 w-full" v-if="path === '/candidates'">
      <Chips :chips="skills" placeholder="Add skill" />
    </div>
    <div class="flex">
      <div class="flex flex-row items-end justify-end w-full">
        <button
          type="submit"
          :disabled="!meta.dirty || !meta.valid || isLoading"
          class="rounded-full border-primary text-primary py-2 px-6 bg-primary"
          :class="{
            disabled: isLoading,
          }"
        >
          <span class="text-white">{{
            isLoading ? "Adding candidate" : "Create candidate"
          }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import TextField from "../../components/textField/index.vue";
import { JobsAPI } from "@/services/jobs";
import { useRoute } from "vue-router";
import { createToastAxelerate } from "../../utils/toast";
import { CandidatesAPI } from "../../services/candidates/index";
import Chips from "../chips/index.vue";

const AddCandidatesForm = defineComponent({
  name: "AddCandidatesForm",
  emits: ["closeModal", "getCandidates"],
  components: {
    TextField,
    Chips,
  },
  setup(props, context) {
    const route = useRoute();
    const path = route.path;
    const idJob = route.params.id;
    const isLoading = ref(false);
    const skills = ref([]);
    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
      position: yup.string().required().label("Position"),
      email: yup.string().email().label("Email"),
      phone: yup.string().label("Phone"),
    });

    const { value: firstName } = useField("first_name");
    const { value: lastName } = useField("last_name");
    const { value: position } = useField("position");
    const { value: email } = useField("email");
    const { value: phone } = useField("phone");
    const { meta, handleSubmit, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        first_name: "",
        last_name: "",
        position: "",
        email: "",
        phone: "",
      },
    });
    const userFields = ref([
      {
        name: "first_name",
        model: firstName,
        label: "First name*",
        type: "text",
      },
      { name: "last_name", model: lastName, label: "Last name*", type: "text" },
      { name: "position", model: position, label: "Position*", type: "text" },
      { name: "email", model: email, label: "Email", type: "email" },
      { name: "phone", model: phone, label: "Phone number", type: "text" },
    ]);
    const resetFormOutside = () => {
      resetForm();
    };
    const closeModal = () => {
      context.emit("closeModal");
    };
    const getCandidates = () => {
      context.emit("getCandidates");
    };
    const onSubmit = handleSubmit((values) => {
      isLoading.value = true;
      let builResponse = {};
      if (route.params.id) {
        builResponse = {
          job_candidate: {
            ...values,
          },
        };
      } else {
        builResponse = {
          candidate: {
            ...values,
            skills: skills.value,
          },
        };
      }

      if (route.params.id) {
        JobsAPI.postJobCandidate(builResponse, idJob)
          .then(() => {
            isLoading.value = false;
            createToastAxelerate(
              "Add candidates",
              "The candidate has been added.",
              "success"
            );
            resetForm();
            closeModal();
            getCandidates();
          })
          .catch((err) => {
            isLoading.value = false;
            const {
              response: {
                data: { errors },
              },
            } = err;
            createToastAxelerate("Add candidates", errors[0], "danger");
            closeModal();
            getCandidates();
          });
      } else {
        CandidatesAPI.createCandidate(builResponse)
          .then(() => {
            isLoading.value = false;
            createToastAxelerate(
              "Add candidates",
              "The candidate has been created",
              "success"
            );
            resetForm();
            closeModal();
            getCandidates();
          })
          .catch((err) => {
            isLoading.value = false;
            const {
              response: {
                data: { errors },
              },
            } = err;
            createToastAxelerate("Add candidates", errors[0], "danger");
            closeModal();
            getCandidates();
          });
      }
    });

    return {
      onSubmit,
      userFields,
      meta,
      resetFormOutside,
      skills,
      path,
      isLoading,
    };
  },
});
export default AddCandidatesForm;
</script>

<style src="./styles.css" scoped></style>
