/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";

export default class SubscriptionsAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getCompanySubscriptions(page = 1): Promise<unknown> {
    return this.axiosInstance.get(`/company_subscriptions?page=${page}`);
  }
  async getPayments(): Promise<unknown> {
    return this.axiosInstance.get("/payment_methods");
  }
}
export const SubscriptionsAPI = new SubscriptionsAPIService();
