export const optionsRejected = [
  {
    out_of_process: "drop_off",
    name: "Drop off from the challenge stage",
    id: "Drop off from the challenge stage",
  },
  {
    out_of_process: "rejected",
    name: "Did not show up to the hiring manager interview",
    id: "Did not show up to the hiring manager interview",
  },
  {
    out_of_process: "rejected",
    name: "Did Not show up to screen interview",
    id: "Did Not show up to screen interview",
  },
  {
    out_of_process: "rejected",
    name: "Out of salary range",
    id: "Out of salary range",
  },
  {
    out_of_process: "rejected",
    name: "Lack of technical skills/experience",
    id: "Lack of technical skills/experience",
  },
  {
    out_of_process: "rejected",
    name: "Behavior / Attitude during the Interview",
    id: "Behavior / Attitude during the Interview",
  },
  {
    out_of_process: "rejected",
    name: "Lack of years of experience",
    id: "Lack of years of experience",
  },
  {
    out_of_process: "drop_off",
    name: "Lack of interest from candidate",
    id: "Lack of interest from candidate",
  },
  {
    out_of_process: "rejected",
    name: "Lack of experience in industry / Roles/projects",
    id: "Lack of experience in industry / Roles/projects",
  },
  {
    out_of_process: "rejected",
    name: "Candidate known by the company",
    id: "Candidate known by the company",
  },
  {
    out_of_process: "rejected",
    name: "Candidate overqualified for the role",
    id: "Candidate overqualified for the role",
  },
  {
    out_of_process: "rejected",
    name: "Not cultural fit",
    id: "Not cultural fit",
  },
  {
    out_of_process: "rejected",
    name: "Candidate seeks fully remote jobs",
    id: "Candidate seeks fully remote jobs",
  },
  {
    out_of_process: "rejected",
    name: "Candidate seeks hybrid jobs",
    id: "Candidate seeks hybrid jobs",
  },
  {
    out_of_process: "rejected",
    name: "Candidate out of range location",
    id: "Candidate out of range location",
  },
  {
    out_of_process: "drop_off",
    name: "Received a counteroffer",
    id: "Received a counteroffer",
  },
  {
    out_of_process: "rejected",
    name: `The offer did not meet the candidate's expectation`,
    id: `The offer did not meet the candidate's expectation`,
  },
  {
    out_of_process: "drop_off",
    name: `Other reason (drop off)`,
    id: `Other reason (drop off)`,
  },
  {
    out_of_process: "rejected",
    name: `Other reason (rejected)`,
    id: `Other reason (rejected)`,
  },
];
