
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useState } from "../../composables/useState";

const MenuEvaluation = defineComponent({
  name: "MenuEvaluation",
  emits: ["archiveTemplate", "copyTemplate"],
  props: {
    templateId: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const router = useRouter();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const archiveTemplate = (templateId: string) => {
      context.emit("archiveTemplate", templateId);
      setIsMenuOpen(false);
    };

    const copyTemplate = (templateId: string) => {
      context.emit("copyTemplate", templateId);
      setIsMenuOpen(false);
    };

    const closeMenu = () => {
      setIsMenuOpen(false);
    };
    return {
      isMenuOpen,
      setIsMenuOpen,
      closeMenu,
      archiveTemplate,
      copyTemplate,
      router,
    };
  },
});
export default MenuEvaluation;
