
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, ref, watchEffect } from "vue";
import { useState } from "@/composables/useState";
import Skeleton from "../skeleton/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import { ChallengesAPI } from "../../services/challenges/index";
import TextField from "@/components/textField/index.vue";
import Button from "@/components/button/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import Avatar from "../avatar/index.vue";

const AddChallengeToJob = defineComponent({
  name: "AddChallengeToJob",
  emits: ["reload", "close"],
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    Skeleton,
    TextField,
    Button,
    Avatar,
  },
  setup(props, context) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const schema = yup.object({
      title: yup.string().label("Title").required(),
      challenge_url: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter a valid url"
        )
        .required(),
    });

    const { value: title } = useField("title");
    const { value: challenge_url } = useField("challenge_url");

    const { values, meta, handleSubmit, errors, validate, setValues } = useForm(
      {
        validationSchema: schema,
        initialValues: {
          title: "",
          challenge_url: "",
        },
      }
    );
    const jobChallengeData = ref();
    const isEditing = ref(false);

    onBeforeMount(() => {
      setIsLoading(true);
      getJobChallenges();
    });

    const getJobChallenges = async () => {
      ChallengesAPI.getJobChallenges(props.id)
        .then((response: any) => {
          jobChallengeData.value = response.data;
          setIsLoading(false);
        })
        .catch((err: any) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Get job challenges",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const onSubmit = handleSubmit((values: any) => {
      setIsLoading(true);

      const payload = {
        ...values,
      };

      if (isEditing.value) {
        ChallengesAPI.updateChallengeToJob(
          props.id,
          payload,
          jobChallengeData.value.id
        )
          .then(async () => {
            context.emit("reload");
            await getJobChallenges();
            setIsLoading(false);
            await createToastAxelerate(
              "Code challenge updated",
              "The challenge has been updated",
              "success"
            );
            isEditing.value = false;
          })
          .catch((err: any) => {
            const {
              response: {
                data: { errors },
              },
            } = err;
            setIsLoading(false);
            createToastAxelerate(
              "Assign challenge",
              errors && errors[0] ? errors[0] : "An error has ocurred",
              "danger"
            );
          });
      } else {
        ChallengesAPI.assignChallengeToJob(props.id, payload)
          .then(async () => {
            context.emit("reload");
            context.emit("close");
            setIsLoading(false);
            await createToastAxelerate(
              "Assign challenge",
              "The challenge has been assigned",
              "success"
            );
          })
          .catch((err: any) => {
            const {
              response: {
                data: { errors },
              },
            } = err;
            setIsLoading(false);
            createToastAxelerate(
              "Assign challenge",
              errors && errors[0] ? errors[0] : "An error has ocurred",
              "danger"
            );
          });
      }
    });

    const editJobChallenge = (data: any) => {
      setValues({
        title: data.title,
        challenge_url: data.challenge_url,
      });
      isEditing.value = true;
    };

    return {
      data,
      isLoading,
      editJobChallenge,
      jobChallengeData,
      isEditing,
      Button,
      onSubmit,
      meta,
    };
  },
});

export default AddChallengeToJob;
