/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";

export default class CommentsAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async postComment(candidateId: string, content: any): Promise<unknown> {
    const formData = new FormData();
    formData.append("comment[content]", content.comment.content);
    if (content.comment.images) {
      for (let i = 0; i < content.comment.images.length; i++) {
        formData.append("comment[images][]", content.comment.images[i]);
      }
    }
    if (content.comment.mentions) {
      for (let i = 0; i < content.comment.mentions.length; i++) {
        formData.append("comment[mentions][]", content.comment.mentions[i]);
      }
    }

    return this.axiosInstance.post(`/add_comment/${candidateId}`, formData);
  }
}
export const CommentsAPI = new CommentsAPIService();
