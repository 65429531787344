
import { defineComponent, ref, watchEffect } from 'vue';

const StageBar = defineComponent({
	name: 'StageBar',
	props: {
		stages: {
			type: Array,
			default: () => [],
		},
		totalCandidates: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const rejected = ref();

		watchEffect(() => {
			rejected.value = props.stages.reduce((total: any, stage: any) => {
				return total + stage.drop_off_count + stage.rejected_count;
			}, 0);
		});

		return {
			rejected,
		};
	},
});
export default StageBar;
