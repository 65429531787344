/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export const createToastAxelerate = (
  title: string,
  description: string,
  type: any,
  closeFunc?: any,
  time = 4000,
  position?: any,
  color?: string
): any => {
  return createToast(
    {
      title,
      description,
    },
    {
      type,
      hideProgressBar: true,
      showIcon: true,
      timeout: time,
      onClose: closeFunc,
      position: position,
      toastBackgroundColor: color,
    }
  );
};
