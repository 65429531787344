
import { computed, defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import PasswordField from "../passwordField/index.vue";
import Tooltip from "../helpTooltip/index.vue";
import { useStore } from "vuex";
import { userAPI } from "../../services/user/index";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";

const ChangePasswordForm = defineComponent({
  name: "ChangePasswordForm",
  components: {
    PasswordField,
    Tooltip,
    Button,
  },
  setup() {
    const store = useStore();
    const userRes = computed(() => store.state.profile.data[0]);
    const schema = yup.object({
      current_password: yup
        .string()
        .required()
        .matches(
          /[a-z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[A-Z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[@$!%*#?&."'<>+-]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /\d+/,
          "Must contain at least one uppercase, special character and number"
        )
        .min(8)
        .label("Current password"),
      new_password: yup
        .string()
        .required()
        .matches(
          /[a-z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[A-Z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[@$!%*#?&."'<>+-]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /\d+/,
          "Must contain at least one uppercase, special character and number"
        )
        .min(8)
        .label("New password"),
      confirm: yup
        .string()
        .oneOf([yup.ref("new_password"), null], "Passwords must match")
        .label("Confirm password"),
    });

    const { value: current } = useField("current_password");
    const { value: newPassword } = useField("new_password");
    const { value: confirm } = useField("confirm");
    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        current_password: "",
        new_password: "",
        confirm: "",
      },
    });
    const optionsLabels = [
      "A minimum of 10 characters",
      "Uppercase and lowercase letters",
      "A number",
    ];
    const userFields = ref([
      {
        name: "current_password",
        model: current,
        label: "Current password",
      },
      {
        name: "new_password",
        model: newPassword,
        label: "New password",
      },
      {
        name: "confirm",
        model: confirm,
        label: "Confirm new password",
      },
    ]);
    const onSubmit = handleSubmit(async (values) => {
      const data = {
        user: {
          ...values,
          password: values.new_password,
        },
      };
      userAPI
        .updateUser(data, userRes.value?.id)
        .then(async () => {
          createToastAxelerate(
            "Password",
            "The password has been updated.",
            "success"
          );

          await store.dispatch("getProfile");
        })
        .catch(() => {
          createToastAxelerate("Password", "An error has occurred", "danger");
        });
    });

    return { onSubmit, userFields, meta, optionsLabels };
  },
});
export default ChangePasswordForm;
