
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BaseModal from "@/components/baseModal/index.vue";
import { optionsRejected } from "../../constants/rejectedOptions";
import Button from "../button/index.vue";
import { JobsAPI } from "@/services/jobs";
import { createToastAxelerate } from "@/utils/toast";
import { CommentsAPI } from "@/services/comments";
import { usePromisedModal } from "@/composables/usePromisedModal";

const modalRejectCandidate = defineComponent({
  name: "ModalRejectCandidate",
  components: { BaseModal, Button },
  emits: ["closeModal", "emitAction", "update:modelValue", "reloadCandidates"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    candidateId: {
      type: String,
      required: true,
    },
    stageId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const reasonSelected: any = ref(null);
    const disabled = ref(true);
    const isLoading = ref(false);
    const otherReason = ref(false);
    const reason = ref("");
    const confirmationModal = usePromisedModal<boolean>();
    const showConfirmationModal = ref(false);
    const isVisible = computed(() => props.showModal);
    const candidateId = computed(() => store.state.candidateInfo.data?.id);

    watch(reasonSelected, (newVal) => {
      if (newVal) {
        if (
          reasonSelected.value.id === "Other reason (drop off)" ||
          reasonSelected.value.id === "Other reason (rejected)"
        ) {
          otherReason.value = true;
          disabled.value =
            reason.value.length > 0 && otherReason.value ? false : true;
        } else {
          otherReason.value = false;
          disabled.value = false;
        }
      } else {
        disabled.value = true;
        otherReason.value = false;
        reason.value = "";
      }
    });

    watch(reason, (newVal) => {
      disabled.value =
        reason.value.length > 0 && otherReason.value ? false : true;
    });

    const closeModal = () => {
      context.emit("closeModal");
    };

    watch(isVisible, async (val) => {
      if (val) {
        const confirmed = await confirmationModal.ask();
        if (confirmed) {
          handleRejected();
        } else {
          context.emit("closeModal");
        }
      } else {
        reasonSelected.value = null;
        disabled.value = true;
      }
    });

    const handleRejected = async () => {
      isLoading.value = true;
      const _reason: any = reasonSelected.value;
      const reasonText = otherReason.value ? reason.value : _reason.name;
      const payload = {
        comment: {
          content: `Reason for rejection: ${reasonText}`,
          mentions: null,
        },
      };

      JobsAPI.updateCandidateStage(props.stageId, _reason.out_of_process)
        .then(async () => {
          await createToastAxelerate(
            "Stage Candidate",
            "The candidate has been rejected successfully",
            "success"
          );
          await CommentsAPI.postComment(candidateId.value, payload);
          await context.emit("reloadCandidates");
          isLoading.value = true;
          closeModal();
        })
        .catch((err) => {
          isLoading.value = true;
          closeModal();
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Stage Candidate",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    };

    return {
      optionsRejected,
      reasonSelected,
      disabled,
      otherReason,
      reason,
      isLoading,
      closeModal,
      handleRejected,
      showConfirmationModal,
      confirmationModal,
    };
  },
});

export default modalRejectCandidate;
