/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from "vuex";
import { answersInt } from "./types";

export const mutations: MutationTree<answersInt> = {
  SCORE_FULLFILED(state, payload: any) {
    state.score = payload;
  },
  TITLE_FULLFILED(state, payload: string) {
    state.title = payload;
  },
  ANSWERED_FULLFILED(state, payload: boolean) {
    state.isAnswered = payload;
  },
};
