import { MutationTree } from "vuex";
import { JobsInterface } from "./types";

export const mutations: MutationTree<JobsInterface> = {
  JOBS_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  JOBS_REJECTED(state, payload: string) {
    state.error = payload;
  },
  INFO_COMMENTS(state, payload: []) {
    state.info_comments = payload;
  },
  FUNCTION_COMMENTS(state, payload: []) {
    state.function_comments = payload;
  },
  DETAILS_COMMENTS(state, payload: []) {
    state.details_comments = payload;
  },
  SALARY_COMMENTS(state, payload: []) {
    state.salary_comments = payload;
  },
};
