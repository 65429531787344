<template>
  <div class="chip-container w-full flex flex-wrap content-center space-y-3">
      <div class="flex flex-row  mr-3">
        <input
      :placeholder="placeholder"
      class="w-40"
      :class="isInput ? 'block' : 'hidden'"
      v-model="currentInput"
      @keypress="addInput"
    />
    <button
      :class="isInput ? 'block' : 'hidden'"
      type="button"
      class="material-icons text-lg text-primary"
      @click="saveChip"
    >
      <i
        class="material-icons text-primary-dark text-base fa fa-plus opacity-70 cursor-pointer ml-2"
      />
    </button>
      </div>
    <div
      class="chip flex items-center m-1 rounded-lg px-2 py-1 bg-primary-light text-primary font-normal"
      v-for="(chip, i) in chips"
      :key="chip"
    >
      <span v-if="!chip.name">{{ chip }}</span>
      <span v-else>{{ chip.name }}</span>
      <i
        :class="isInput ? 'block' : 'hidden'"
        class="text-primary-dark text-base fa fa-times cursor-pointer opacity-70 ml-2"
        @click="deleteChip(i)"
      />
    </div>
  
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { defineComponent, ref } from "vue";

const Chips = defineComponent({
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    set: {
      type: Boolean,
      default: true,
    },
    chips: {
      type: Array,
      required: true,
    },
    isInput: {
      type: Boolean,
      default: true,
    },
    skills: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const currentInput = ref("");
    const saveChip = () => {
      ((props.set && props.chips.indexOf(currentInput.value) === -1) ||
        !props.set) &&
        props.chips.push(currentInput.value);
      currentInput.value = "";
    //  props.chips = props.chips.reverse();
    };
    const deleteChip = (index) => {
      props.chips.splice(index, 1);
    };
    const disabledEnter = (e) => {
      if (e.which === 13) {
        e.preventDefault();
      }
    };
    const backspaceDelete = ({ which }) => {
      which == 8 &&
        currentInput.value === "" &&
        props.chips.splice(this.chips.length - 1);
    };
    const addInput = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        saveChip();
      }
    };
    return {
      saveChip,
      deleteChip,
      disabledEnter,
      backspaceDelete,
      currentInput,
      addInput,
    };
  },
});
export default Chips;
</script>
<style scoped>
.chip-container {
  min-height: 34px;
}
input {
  border: 0;
  outline: 0;
  padding: 4px;
  border-bottom: 2px solid #8908f9;
}
input:focus {
  border-color: #f5ebfe;
}
</style>
