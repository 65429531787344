/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";
export default class BillingAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getPaymentMethods(): Promise<unknown> {
    return this.axiosInstance.get("/payment_methods");
  }
  async getStripeCustomer(): Promise<unknown> {
    return this.axiosInstance.get("/stripe/customer");
  }
  async stripeAddCard(): Promise<unknown> {
    return this.axiosInstance.get("/stripe/add_card?platform=sourcing");
  }
  async stripeRemoveCard(cardId: string): Promise<unknown> {
    return this.axiosInstance.get(`/stripe/remove_card/${cardId}`);
  }
  async stripeUpdateDefaultCard(cardId: string): Promise<unknown> {
    return this.axiosInstance.get(`/stripe/update_default_card/${cardId}`);
  }
}
export const BillingAPI = new BillingAPIService();
