<template>
  <FieldArray name="evaluation_categories" v-slot="{ fields, push, remove }">
    <div
      class="flex flex-col py-2 mb-3"
      v-for="(field, idx) in fields"
      :key="field.key"
    >
      <div
        class="
          flex flex-row
          space-x-4
          w-full
          bg-primary-light
          py-2
          px-3
          rounded-t-3xl
          justify-between
        "
      >
        <div class="w-11/12">
          <TextField
            label="Please fill a title for this category of questions... "
            custom
            required
            colorFocusLabel="text-black"
            customClass="bg-primary-light"
            customLabel="text-gray-300"
            :isError="
              errors && Boolean(errors[`evaluation_categories[${idx}].name`])
            "
            :name="`evaluation_categories[${idx}].name`"
          />
        </div>
        <div class="flex flex-row w-1/12 space-x-3 justify-center items-center">
          <button type="button" @click="remove(idx)" v-if="!field.isFirst">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-red-500 hover:bg-gray-100"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>
      </div>
      <EditQuestionArrayField
        :name="`evaluation_categories[${idx}].evaluation_questions`"
        :errors="errors"
      />
      <div class="flex flex-row justify-end w-full pt-1">
        <Button
          label="Add new category"
          @click="
            push({
              name: '',
              evaluation_questions: [{ content: '' }],
            })
          "
        >
          <template v-slot:startIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </template>
        </Button>
      </div>
    </div>
  </FieldArray>
</template>

<script>
import { defineComponent } from "vue";
import { FieldArray } from "vee-validate";
import TextField from "../textField/index.vue";
import { useState } from "../../composables/useState";
import EditQuestionArrayField from "../editQuestionArrayField/index.vue";
import Button from "../button/index.vue";

const EditCategoryArrayField = defineComponent({
  name: "EditCategoryArrayField",
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    FieldArray,
    TextField,
    Button,

    EditQuestionArrayField,
  },
  setup(props) {
    const [expand, setExpand] = useState(true);
    const [current, setCurrent] = useState(false);
    const existError =
      props.errors &&
      props.errors.categories &&
      Object.keys(props.errors.categories);
    const clickExpand = (index) => {
      setCurrent(index);
    };
    return { expand, setExpand, clickExpand, current, existError };
  },
});

export default EditCategoryArrayField;
</script>
