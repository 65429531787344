
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref } from "vue";
import { createToastAxelerate } from "../../utils/toast";
import { JobsAPI } from "@/services/jobs";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Button from "../button/index.vue";

const FilesForm = defineComponent({
  name: "FilesForm",
  emits: ["closeInForm"],
  components: {
    Button,
  },
  props: {
    candidateId: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, context) {
    const files: any = ref([]);
    const store = useStore();
    const id = computed(() => store.state.candidateInfo.data?.id);
    const route = useRoute();
    const closeInForm = () => {
      context.emit("closeInForm");
    };

    const addFile = (event: any) => {
      files.value.push(event.target.files[0]);
    };

    const getFileExtension = (file: any) => {
      let auxFileName = file.substring(file.lastIndexOf("/") + 1);
      return auxFileName.split(".").pop();
    };

    const getFileIcon = (type: string) => {
      switch (type) {
        case "pdf":
          return require("@/assets/svgs/pdf.png");
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
          return require("@/assets/svgs/img.png");
        case "xls":
        case "xlsx":
          return require("@/assets/svgs/xls.png");
        case "doc":
        case "docx":
          return require("@/assets/svgs/doc.png");
        default:
          return require("@/assets/svgs/file.png");
      }
    };

    const deleteFile = (index: number) => {
      files.value.splice(index, 1);
    };

    const uploadFiles = () => {
      JobsAPI.uploadFile(props.candidateId, files.value)
        .then(async () => {
          const buildParams = {
            jobId: route.query.id,
            userId: id.value,
          };
          await store.dispatch("getCandidateState", buildParams);
          await closeInForm();
          await createToastAxelerate(
            "Files",
            "The file has been uploaded successfully.",
            "success"
          );
        })
        .catch(() => {
          createToastAxelerate("Files", "An error has ocurred.", "danger");
        });
    };
    return {
      files,
      deleteFile,
      addFile,
      uploadFiles,
      closeInForm,
      getFileExtension,
      getFileIcon,
    };
  },
});
export default FilesForm;
