
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, watchEffect } from "vue";
import { getInitials, numberWithCommas } from "../../utils/functions";
import Chips from "../../components/chips/index.vue";
import { useRoute, useRouter } from "vue-router";
import { CandidatesAPI } from "../../services/candidates/index";
import { useState } from "../../composables/useState";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const PublicProfile = defineComponent({
  name: "PublicProfile",
  components: {
    Chips,
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const [candidate, setCandidate] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const buildOverYears = (year: any, isCurrent: any) => {
      if (isCurrent) {
        return `(Now)`;
      }
      const formatYear = new Date().getFullYear();
      return `(Over ${formatYear - year} Years)`;
    };
    const buildNow = (year: any, isCurrent: any) => {
      if (isCurrent) {
        return `Now`;
      }
      return `${year}`;
    };
    watchEffect(() => {
      if (route.query.id) {
        setIsLoading(true);
        CandidatesAPI.getPublicProfile(route.query.id).then((res: any) => {
          setCandidate(res.data);
          setIsLoading(false);
        });
      }
    });
    onBeforeMount(() => {
      setIsLoading(true);
      CandidatesAPI.getPublicProfile(route.query.id).then((res: any) => {
        setCandidate(res.data);
        setIsLoading(false);
      });
    });
    return {
      getInitials,
      buildOverYears,
      buildNow,
      numberWithCommas,
      candidate,
      router,
      isLoading,
    };
  },
});

export default PublicProfile;
