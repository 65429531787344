/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFormatStringDate } from "./functions";
import { numberWithCommas } from "./../utils/functions";

export const buildBillingTable = (data: any) =>
  data &&
  data.map((obj: any) => ({
    service: obj.transaction_type ? obj.transaction_type : "-",
    date: getFormatStringDate(obj.created_at),
    invoice: obj.invoice_url,
    amount: obj.amount / 100,
    currency: obj.currency,
  }));
