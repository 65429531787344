
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useState } from "@/composables/useState";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CandidateInfo from "../../components/candidateInfo/index.vue";
import AddCandidateGeneralForm from "@/components/addCandidateGeneral/index.vue";
import DeleteCandidate from "../../components/deleteCandidateAlert/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";

const JobCandidateShow = defineComponent({
  name: "JobCandidateShow",
  emits: ["reloadCandidates", "reloadCandidate"],
  components: {
    CandidateInfo,
    AddCandidateGeneralForm,
    ReusableModal,
    DeleteCandidate,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const candidate = computed(() => store.state.candidateInfo.data);
    const [showDelete, setShowDelete] = useState(false);
    const formOption = ref(false);

    const reloadCandidate = async (option: string) => {
      await store.dispatch("getJobCandidatesState", {
        jobId: route.params.id,
        filters: {},
      });
      if (option === "edit" || option === "changeState") {
        reloadCandidateState();
      } else if (option === "delete") {
        router.push({
          name: "JobDetails",
          params: { id: route.params.id },
        });
      }
    };

    const reloadCandidateState = () => {
      store.dispatch("getCandidateState", {
        jobId: route.params.id,
        userId: route.params.job_candidate_id,
      });
    };

    const onShowInfo = () => {
      formOption.value = false;
      reloadCandidate("edit");
    };

    onMounted(() => {
      reloadCandidateState();
      if (!route.params.tab) {
        router.replace({ params: { tab: "Timeline" } });
      }
    });

    watch(
      () => route.params.job_candidate_id,
      () => {
        // reseting the candidate to show loading otherwise the id persists
        if (route.name === "JobCandidateShow") {
          store.commit("RESET_CANDIDATE");
          reloadCandidateState();
        }
      }
    );

    return {
      onShowInfo,
      candidate,
      router,
      route,
      formOption,
      reloadCandidate,
      setShowDelete,
      showDelete,
      reloadCandidateState,
    };
  },
});

export default JobCandidateShow;
