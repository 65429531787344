
import { defineComponent } from "vue";

export default defineComponent({
  name: "Divider",
  props: {
    color: {
      type: String,
      default: "gray-300",
    },
    width: {
      type: String,
      default: "full",
    },
    height: {
      type: String,
      default: "px",
    },
    marginY: {
      type: String,
      default: "2",
    },
    marginX: {
      type: String,
      default: "0",
    },
  },
});
