
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import Header from "../../components/header/index.vue";
import Button from "../../components/button/index.vue";
import { useRoute, useRouter } from "vue-router";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { createToastAxelerate } from "../../utils/toast";
import EditTalentEvaluationForm from "../../components/editTalentEvaluationForm/index.vue";

const EditTalentEvaluation = defineComponent({
  name: "EditTalentEvaluation",
  components: {
    Header,
    Button,
    EditTalentEvaluationForm,
  },
  setup() {
    const router = useRouter();
    const formRef = ref<InstanceType<typeof EditTalentEvaluationForm>>();
    const route = useRoute();
    const id = route?.query?.id;
    const onSubmit = () => formRef.value?.onSubmit();
    const submitData = async () => {
      const res = await formRef.value?.onSubmit();
      const payload: any = {
        evaluation: {
          ...res,
        },
      };
      ["updated_at", "created_at", "id"].forEach(
        (e) => delete payload.evaluation[e]
      );
      payload?.evaluation?.evaluation_categories?.forEach((object: any) => {
        delete object["id"];
      });
      TalentEvaluationAPI.updateTemplate(id, payload).then(() => {
        createToastAxelerate(
          "Talent Evaluation",
          "The evaluation has been edited",
          "success",
          () => router.push("/talent-evaluation")
        );
      });
    };
    return { router, route, onSubmit, formRef, submitData };
  },
});

export default EditTalentEvaluation;
