import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a68bd55"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: _normalizeClass(["space-x-3", _ctx.buildStyleByVariant(_ctx.variant, _ctx.sizeLabel)])
  }, [
    _renderSlot(_ctx.$slots, "startIcon", {}, undefined, true),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.labelSize)
    }, _toDisplayString(_ctx.label), 3),
    _renderSlot(_ctx.$slots, "endIcon", {}, undefined, true)
  ], 10, _hoisted_1))
}