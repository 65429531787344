
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import Avatar from "../avatar/index.vue";
import MenuJob from "../menuJob/index.vue";
import { timeAgo, numberWithCommas } from "../../utils/functions";
import { format } from "date-fns";
//import Link from "../link/index.vue";
import Button from "../button/index.vue";
import { useStore } from "vuex";
import DateFormatter from "@/components/dateFormatter/index.vue";

const JobCard = defineComponent({
  name: "JobCard",
  emits: [
    "openTemplate",
    "openChallenge",
    "deactivateJob",
    "assignJob",
    "acceptManageJob",
  ],
  components: {
    Avatar,
    MenuJob,
    // Link,
    Button,
    DateFormatter,
  },
  props: {
    jobs: {
      type: Array,
      required: true,
      default: () => [],
    },
    showAssing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const activeItem = ref<string | number | boolean>("");
    const companyId = computed(() => store.state.profile.data.company.id);

    const openTemplate = (id: string) => {
      context.emit("openTemplate", id);
    };
    const deactivateJob = (job: any) => {
      context.emit("deactivateJob", job);
    };
    const openChallenge = (id: string) => {
      context.emit("openChallenge", id);
    };

    const acceptManageJob = (job: any) => {
      context.emit("acceptManageJob", {
        id: job.id,
        title: job.title,
      });
    };
    const assignJob = (item: any) => {
      context.emit("assignJob", {
        company: companyId.value,
        job_id: item.id,
      });
    };
    const salaryRange = (
      minSalary: any,
      maxSalary: any,
      currency: string,
      showMonthly: boolean
    ) => {
      if (showMonthly) {
        return `$${numberWithCommas(minSalary.to_show)} - $${numberWithCommas(
          maxSalary.to_show
        )} ${currency} Monthly`;
      } else {
        return `$${numberWithCommas(minSalary.annual)} - $${numberWithCommas(
          maxSalary.annual
        )} ${currency ? currency : ""} Annual`;
      }
    };

    const expandCard = (index: string) => {
      // Show current item. If already opened, hide current item
      activeItem.value = index === activeItem.value ? false : index;
    };
    return {
      router,
      activeItem,
      timeAgo,
      openTemplate,
      openChallenge,
      format,
      numberWithCommas,
      deactivateJob,
      expandCard,
      assignJob,
      companyId,
      salaryRange,
      acceptManageJob,
    };
  },
});

export default JobCard;
