import { Module } from "vuex";
import { RootState } from "../types";
import { TemplatesInt } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state = {
  descriptions: "",
  responsibilities: [],
  requirements: [],
  benefits: [],
} as TemplatesInt;

export const templates: Module<TemplatesInt, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
