
import { useStore } from "vuex";
import Button from "../button/index.vue";
import Skeleton from "../skeleton/index.vue";
import { useState } from "@/composables/useState";
import { createToastAxelerate } from "@/utils/toast";
import { TimeLineAPI } from "../../services/timeline/index";
import { computed, defineComponent, onMounted, ref } from "vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import ModalScheduleMeeting from "../modalScheduleMeeting/index.vue";

const ScheduleMeetingSection = defineComponent({
  name: "ScheduleMeetingSection",
  inheritAttrs: false,
  components: {
    Button,
    ReusableModal,
    ModalScheduleMeeting,
    Skeleton,
  },
  props: {
    jobCandidate: {
      type: Object,
      require: true,
    },
  },

  setup(props) {
    const store = useStore();
    const activeMenu = ref("");
    const [meetingId, setMeetingId] = useState("");
    const [meetings, setMeetings] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const userId = computed(() => store.state.profile?.data?.id);
    const [loadingMeetings, setLoadingMeetings] = useState(true);
    const [deletingMeeting, setDeletingMeeting] = useState(false);

    onMounted(() => {
      getCandidateMeetings();
    });

    const getCandidateMeetings = () => {
      setLoadingMeetings(true);
      TimeLineAPI.getCandidateMeetings(
        props.jobCandidate?.job_id,
        props.jobCandidate?.id
      )
        .then((response: any) => {
          setMeetings(response.data.meeting_urls);
          setLoadingMeetings(false);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Get meetings",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const showMenu = (id: string) => {
      activeMenu.value = id;
    };

    const closeMenu = () => {
      activeMenu.value = "";
    };

    const deleteMeeting = (meetingId: string) => {
      setDeletingMeeting(true);
      TimeLineAPI.deleteCandidateMeeting(
        props.jobCandidate?.job_id,
        props.jobCandidate?.id,
        meetingId
      )
        .then(() => {
          setDeletingMeeting(false);
          closeMenu();
          getCandidateMeetings();
          createToastAxelerate(
            "Delete meeting",
            "The meeting has been deleted",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Delete meeting",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const createMeeting = () => {
      setMeetingId("");
      setshowModal(true);
    };

    const editMeeting = (id: string) => {
      setMeetingId(id);
      setshowModal(true);
      closeMenu();
    };

    const reSendMeeting = (id: string) => {
      closeMenu();
      TimeLineAPI.reSendCandidateMeeting(
        props.jobCandidate?.job_id,
        props.jobCandidate?.id,
        id
      )
        .then(() => {
          createToastAxelerate(
            "Re-send meeting",
            "Meeting URL has been resent",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Resent meeting",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    return {
      userId,
      meetings,
      showMenu,
      closeMenu,
      meetingId,
      showModal,
      activeMenu,
      editMeeting,
      setMeetings,
      setMeetingId,
      setshowModal,
      deleteMeeting,
      createMeeting,
      reSendMeeting,
      deletingMeeting,
      loadingMeetings,
      setDeletingMeeting,
      setLoadingMeetings,
      getCandidateMeetings,
    };
  },
});
export default ScheduleMeetingSection;
