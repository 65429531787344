
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, onBeforeMount, ref } from "vue";
//import TalentPricingCard from "../../components/talentPricingCard/index.vue";
import { CheckoutAPI } from "../../services/checkout/index";
//import WorkForcePricingTable from "../../components/workforcePricingCard/index.vue";
import Button from "../../components/button/index.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { createToastAxelerate } from "../../utils/toast";
import { useState } from "../../composables/useState";
import Loading from "vue-loading-overlay";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const Pricing = defineComponent({
  name: "Pricing",
  components: {
    //TalentPricingCard,
    // WorkForcePricingTable,
    Button,
    Loading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const token = cookies.get("token");
    const groupDataPrices = ref([{}]);
    const currency = ref("");
    const profile = computed(() => store.state.profile.data);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState("");
    const logOut = () => {
      store
        .dispatch("logout", { token: token })
        .then(() => {
          window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
        })
        .catch(() => {
          createToastAxelerate("Log out", "An Error has ocurred.", "danger");
        });
    };
    onBeforeMount(async () => {
      setIsLoading(true);
      await store.dispatch("getProfile");
      if (profile?.value?.company?.subscription_status === "active") {
        await router.push("/");
      } else {
        await CheckoutAPI.getPrices()
          .then((res: any) => {
            setIsLoading(false);
            currency.value = res.data[0].country;
            groupDataPrices.value = groupData(res.data[1].prices[0]);
          })
          .catch(() => setIsLoading(false));
      }
    });

    function groupData(array: any) {
      const groups = [
        {
          name: "pay_as_you_go",
          label: "Pay-as-you-go",
          data: [],
          description: `Ideal for startups with occasional hiring needs.`,
          pill: "Best for occasional hires",
        },
        {
          name: "scale_up",
          label: "Scaleup",
          data: [],
          description: `Our most popular plan, ideal for scaleups growing their teams.`,
          pill: "Best for 5+ annual hires",
        },
        {
          name: "growth",
          label: "Growth",
          data: [],
          description: `Ideal for businesses with constant open positions looking for a top-tier service.`,
          pill: "Best for 12+ annual hires",
        },
      ];
      array = Object.entries(array);
      array.map((item: any) => {
        groups.map((price: any) => {
          if (price.name === item[0]) {
            price.data = item[1];
          }
        });
      });

      groups.map((group: any) => {
        if (group.data.length > 1) {
          group.labelSelected = "";
          group.idSelected = "";
          group.monthlySelected = false;
          group.typePayment = "Yearly";

          group.data.forEach((data: any, index: number) => {
            if (index == 0) {
              data.isActive = true;
              group.monthlySelected = true;
              group.labelSelected = data.metadata.price;
              group.typePayment = "Monthly";
              group.idSelected = data.id;
            }
          });
        }
      });

      return groups;
    }

    function selectPricing(data: any, index: number) {
      let isActive = data.isActive;

      groupDataPrices.value.map((dataMap: any, i) => {
        if (i === index) {
          if (!isActive) {
            dataMap.labelSelected = data.metadata.price;
            dataMap.idSelected = data.id;
            dataMap.monthlySelected = false;
            dataMap.typePayment = "Yearly";
          } else {
            dataMap.monthlySelected = true;
            dataMap.labelSelected = data.metadata.price;
            dataMap.idSelected = data.id;
            dataMap.typePayment = "Monthly";
          }
        }
      });
    }

    function getAmount(number: string) {
      return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function choosePlan(plan: any) {
      let priceSelected =
        plan.data.length > 1 ? plan.idSelected : plan.data[0].id;

      let payload = {
        product: {
          price: priceSelected,
          quantity: 1,
        },
      };

      CheckoutAPI.postCheckout(payload)
        .then((response: any) => {
          window.location.replace(response.data.url);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Pricing",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    }
    return {
      logOut,
      data,
      isLoading,
      country,
      groupData,
      groupDataPrices,
      getAmount,
      selectPricing,
      choosePlan,
      currency,
    };
  },
});

export default Pricing;
