<template>
  <FieldArray :name="name" v-slot="{ fields }">
    <div
      class="space-y-4 flex flex-col"
      v-for="(field, idx) in fields"
      :key="field.key"
    >
      <div
        class="flex flex-row space-x-4 w-full bg-white justify-between px-3 py-2 border-l border-r border-b border-gray-400"
      >
        <div class="w-11/12">
          <Field
            :name="`${name}[${idx}].content`"
            disabled
            class="bg-white text-black px-3 w-full"
          />
        </div>
      </div>
    </div>
  </FieldArray>
</template>

<script>
import { defineComponent } from "vue";
import { FieldArray, Field } from "vee-validate";

const GetQuestionArrayField = defineComponent({
  name: "GetQuestionArrayField",
  props: {
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    FieldArray,
    Field,
  },
});

export default GetQuestionArrayField;
</script>
