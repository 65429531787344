/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";
import { ObjectCheckout } from "./types";
export default class CheckoutAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getPrices(): Promise<unknown> {
    return this.axiosInstance.get("/prices");
  }
  async postCheckout(product: ObjectCheckout): Promise<unknown> {
    return this.axiosInstance.post("/checkouts", product);
  }
  async updateCheckout(data: any): Promise<unknown> {
    return this.axiosInstance.post("/update_checkout", data);
  }
}
export const CheckoutAPI = new CheckoutAPIService();
