/* eslint-disable @typescript-eslint/no-explicit-any */

import { formatDistanceStrict } from "date-fns";
import moment from "moment";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getInitials = (name: any): any => {
  let initials = name.split(" ");
  if (initials.length > 1) {
    initials = initials?.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }
  return initials.toUpperCase();
};

export const buildColorStep = (option: string) => {
  switch (option) {
    case "Talent Pool":
      return {
        backgroundColor: "#E5F8FF",
        color: "#00A7E5",
        border: "1px solid #00A7E5",
      };
    case "Offer":
      return {
        backgroundColor: "#FFF8F2",
        color: "#E56900",
        border: "1px solid #E56900",
      };
    case "Hired":
      return {
        backgroundColor: "#F2FFF9",
        color: "#00E574",
        border: "1px solid #00E574",
      };
    case "2nd Interview":
      return {
        backgroundColor: "#FEFAF1",
        color: "#CB911A",
        border: "1px solid #CB911A",
      };
    case "Screening":
      return {
        backgroundColor: "#FBF2FF",
        color: "#9D04E1",
        border: "1px solid #9D04E1",
      };
    case "Technical Interview":
      return {
        backgroundColor: "#FFF2FC",
        color: "#E500B5",
        border: "1px solid #E500B5",
      };
    case "Challenge":
      return {
        backgroundColor: "#FFF2F2",
        color: "#E50000",
        border: "1px solid #E50000",
      };
    case "Rejected":
      return {
        backgroundColor: "#FEE2E2",
        color: "#F87171",
        border: "1px solid #EF4444",
      };
    default:
      return {
        backgroundColor: "#E5F8FF",
        color: "#00A7E5",
        border: "1px solid #00A7E5",
      };
  }
};

export const getCompanyName = (str: string) => {
  return str.substring(str.lastIndexOf("@") + 1, str.lastIndexOf("."));
};

export const validateEmail = (value: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const numberWithCommas = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const numberFormattedWithCommas = (x: number) =>
  x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const timeAgo = (date: string) => {
  return formatDistanceStrict(new Date(date), new Date(), {
    addSuffix: true,
  });
};

export const getFormatStringDate = (d: string) => {
  const date = new Date(d);

  return date.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
};

export const formatedDateMoment = (date: string) => {
  return date ? moment(date).format("MMM Do YYYY, h:mm a") : "";
};

export const isEmptyOrNull = (value: string, type = "string") => {
  let val = true;
  if (!value) {
    val = false;
  } else if (typeof value !== type) {
    val = false;
  }
  return val;
};

export const isLater = (str1: string, str2: string) => {
  if (isEmptyOrNull(str1, "object") && isEmptyOrNull(str2, "object")) {
    return new Date(str1) > new Date(str2);
  }
  return false;
};
