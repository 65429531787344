/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { SubscriptionsInt } from "./types";
import { SubscriptionsAPI } from "../../services/subscriptions/index";

export const actions: ActionTree<SubscriptionsInt, RootState> = {
  getSubscriptions({ commit }, page) {
    return new Promise((resolve, reject) => {
      SubscriptionsAPI.getCompanySubscriptions(page)
        .then((res: any) => {
          resolve(false);
          commit("SUBSCRIPTIONS_FULLFILED", res.data);
        })
        .catch((err) => {
          reject(true);
          commit("SUBSCRIPTIONS_REJECTED", err);
        });
    });
  },
  getPayments({ commit }) {
    return new Promise((resolve, reject) => {
      SubscriptionsAPI.getPayments()
        .then((res: any) => {
          resolve(false);
          commit("PAYMENTS_FULLFILED", res.data.data);
        })
        .catch((err) => {
          reject(true);
          commit("PAYMENTS_REJECTED", err);
        });
    });
  },
};
