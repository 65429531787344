export const optionCountry = [
  { id: "Mexico", name: "Mexico" },
  { id: "USA", name: "USA" },
];

export const optionDepartament = [
  { id: "engineering", name: "Engineering" },
  { id: "design", name: "Design" },
  { id: "marketing", name: "Marketing" },
  { id: "sales", name: "Sales" },
  { id: "talent", name: "Talent" },
  { id: "staff", name: "Staff" },
];

export const optionsEmployment = [
  { id: "full_time", name: "Full time" },
  { id: "contract", name: "Contract" },
  { id: "part_time", name: "Part time" },
  { id: "temporary", name: "Temporary" },
  { id: "other", name: "Other" },
];

export const optionsExperience = [
  { id: "internship", name: "Internship" },
  { id: "entry_level", name: "Entry level" },
  { id: "associate", name: "Associate" },
  { id: "mid_level", name: "Mid level" },
  { id: "mid_senior_level", name: "Mid senior level" },
  { id: "senior_level", name: "Senior level" },
  { id: "director", name: "Director" },
  { id: "executive", name: "Executive" },
];

export const optionsEducation = [
  { id: "bachelors_degree", name: "Bachelor’s Degree" },
  { id: "unspecified", name: "Unspecified" },
  { id: "high_school", name: "High School" },
  { id: "certification", name: "Certification" },
  { id: "associate_degree", name: "Associate degree" },
  { id: "masters_degree", name: "Masters Degree" },
  { id: "doctorate", name: "Doctorate" },
  { id: "professional", name: "Professional" },
];

export const optionCurrencies = [
  { id: "USD", name: "Dollar (USD)" },
  { id: "MXN", name: "Mexican Peso (MXN)" },
];

export const jobStatus = [
  { id: "archived", name: "Archived" },
  { id: "draft", name: "Draft" },
  { id: "pending_approval", name: "Pending Approval" },
  { id: "published", name: "Published" },
];

export const preferredWorkType = [
  { id: "hybrid", name: "Hybrid" },
  { id: "on_site", name: "On site" },
  { id: "remote", name: "Remote" },
];
