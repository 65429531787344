
import { defineComponent } from "vue";

const Skeleton = defineComponent({
  name: "Skeleton",
  props: {
    option: {
      type: String,
      default: "card",
    },
  },
});
export default Skeleton;
