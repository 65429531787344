<template>
  <div class="flex flex-col pt-3">
    <div
      v-for="(item, i) in timeline"
      :key="`timeline-item-${i + 1}`"
      class="w-full break-words"
    >
      <!-- apply job -->
      <div
        v-if="item.event_type === 'candidate_applied_to_job'"
        class="flex flex-row items-center py-2"
        :class="item.sourceable.stage_name === 'Rejected' && 'bg-red-50'"
      >
        <div class="flex items-center justify-center px-3">
          <img
            :src="buildImageStage(item?.sourceable?.stage_name)"
            alt="icon stage"
          />
        </div>
        <div class="flex flex-row items-center">
          <div class="inline">
            <div
              class="
                flex
                items-center
                justify-center
                bg-primary
                text-white
                rounded-full
                w-8
                h-8
                text-sm
              "
            >
              {{
                getInitials(
                  `${item.sourceable?.candidate.first_name} ${item.sourceable?.candidate?.last_name}`
                )
              }}
            </div>
          </div>
          <div class="flex flex-row items-center text-xs pl-4">
            <span>Applied via job board </span>

            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- move stage -->
      <div
        v-if="item.event_type === 'job_candidate_stage_change'"
        class="flex flex-row items-center py-2"
        :class="item.sourceable.stage_name === 'Rejected' && 'bg-red-50'"
      >
        <div class="flex items-center justify-center px-3">
          <img
            :src="buildImageStage(item.sourceable.stage_name)"
            alt="icon stage"
          />
        </div>
        <div class="flex flex-row items-center">
          <div class="inline">
            <div
              class="
                flex
                items-center
                justify-center
                bg-primary
                text-white
                rounded-full
                w-8
                h-8
                text-sm
              "
            >
              {{
                getInitials(`${item.staff.first_name} ${item.staff.last_name}`)
              }}
            </div>
          </div>
          <div class="flex flex-row items-center text-xs pl-4">
            <span>Moved to {{ item.sourceable.stage_name }} </span>
            <span class="pl-1">
              by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- hired -->
      <div
        v-if="item.event_type === 'job_candidate_hired'"
        class="flex flex-row items-center py-2 bg-green-100"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/hiring.svg" alt="icon stage" />
        </div>
        <div class="flex flex-row items-center">
          <div class="inline">
            <div
              class="
                flex
                items-center
                justify-center
                bg-primary
                text-white
                rounded-full
                w-8
                h-8
                text-sm
              "
            >
              {{
                getInitials(`${item.staff.first_name} ${item.staff.last_name}`)
              }}
            </div>
          </div>
          <div class="flex flex-row items-center text-xs pl-4">
            <span class="font-bold"
              >{{
                `${item.sourceable.first_name} ${item.sourceable.last_name}`
              }}
            </span>
            <span class="pl-1">
              was Hired by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- Added -->
      <div
        v-if="item.event_type === 'job_candidate_added'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/arrow.svg" alt="icon creation" />
        </div>
        <div class="flex flex-row items-center">
          <div class="inline">
            <div
              class="
                flex
                items-center
                justify-center
                bg-primary
                text-white
                rounded-full
                w-8
                h-8
                text-sm
              "
            >
              {{
                getInitials(`${item.staff.first_name} ${item.staff.last_name}`)
              }}
            </div>
          </div>
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              Uploaded by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- Creation -->
      <div
        v-if="item.event_type === 'creation'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/arrow.svg" alt="icon creation" />
        </div>
        <div class="flex flex-row items-center">
          <div class="inline">
            <div
              class="
                flex
                items-center
                justify-center
                bg-primary
                text-white
                rounded-full
                w-8
                h-8
                text-sm
              "
            >
              {{
                getInitials(`${item.staff.first_name} ${item.staff.last_name}`)
              }}
            </div>
          </div>
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              Uploaded by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- edit -->
      <div
        v-if="item.event_type === 'update'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/edit.svg" alt="icon stage" />
        </div>
        <div class="flex flex-row items-center">
          <div class="inline">
            <div
              class="
                flex
                items-center
                justify-center
                bg-primary
                text-white
                rounded-full
                w-8
                h-8
                text-sm
              "
            >
              {{
                getInitials(`${item.staff.first_name} ${item.staff.last_name}`)
              }}
            </div>
          </div>
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              Edited candidate information by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!--  job closed-->
      <div
        v-if="item.event_type === 'job_closed'"
        class="flex flex-row items-center py-2 bg-yellow-50"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/target.svg" alt="icon creation" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar
            :name="`${item.staff.first_name} ${item.staff.last_name}`"
            variant="xs"
          />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              Job closed by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>

      <!-- job reopened--->
      <div
        v-if="item.event_type === 'job_reopened'"
        class="flex flex-row items-center py-2 bg-green-50"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/target.svg" alt="icon creation" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar
            :name="`${item.staff.first_name} ${item.staff.last_name}`"
            variant="xs"
          />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              Job re-opened by
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- code challenge sent -->
      <div
        v-if="item.event_type === 'job_candidate_code_challenge_sent'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/callenge.svg" alt="icon challenge" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar
            :name="`${item.staff.first_name} ${item.staff.last_name}`"
            variant="xs"
          />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              <span class="font-bold pl-1">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
              sent the
              <span class="font-bold">{{
                `${item.sourceable.job_challenge_title}`
              }}</span
              >code challenge to
              <span class="font-bold">{{ `${candidateName}` }}.</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- code challenge started -->
      <div
        v-if="item.event_type === 'job_candidate_code_challenge_started'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/callenge.svg" alt="icon challenge" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar :name="`${candidateName}`" variant="xs" />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              <span class="font-bold pl-1">{{ `${candidateName}` }}</span>
              started the
              <span class="font-bold">{{
                `${item.sourceable.job_challenge_title}`
              }}</span>
              code challenge.
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- code challenge hasnt started -->
      <div
        v-if="
          item.event_type ===
          'job_candidate_code_challenge_hasnt_started_reminder'
        "
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/callenge.svg" alt="icon challenge" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar :name="`${candidateName}`" variant="xs" />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              The code challenge
              <span class="font-bold pl-1">{{
                `${item.sourceable.job_challenge_title}`
              }}</span>
              has not started by
              <span class="font-bold pl-1">{{ `${candidateName}` }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>

      <!-- code challenge result  -->
      <div
        v-if="item.event_type === 'job_candidate_code_challenge_result'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/callenge.svg" alt="icon challenge" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar :name="`${candidateName}`" variant="xs" />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              <span class="font-bold pl-1">{{ `${candidateName}` }}</span>
              finished the
              <span class="font-bold">{{
                `${item.sourceable.job_challenge_title}`
              }}</span>
              code challenge.
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- code challenge resent  -->
      <div
        v-if="item.event_type === 'job_candidate_code_challenge_resent'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/callenge.svg" alt="icon challenge" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar
            :name="`${item.sourceable.job_challenge_title}`"
            variant="xs"
          />
          <div class="flex flex-row items-center text-xs pl-4">
            <span>
              <span class="font-bold">{{
                `${item.staff.first_name} ${item.staff.last_name}`
              }}</span>
              re-sent the
              <span class="font-bold">{{
                `${item.sourceable.job_challenge_title}`
              }}</span>
              code challenge to
              <span class="font-bold">{{ candidateName }}</span>
            </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>
      <!-- Upload by AI Talent Matching-->
      <div
        v-if="item.event_type === 'ai_sourcing_job_candidate_added'"
        class="flex flex-row items-center py-2"
      >
        <div class="flex items-center justify-center px-3">
          <img src="@/assets/timeline/arrow.svg" alt="icon creation" />
        </div>
        <div class="flex flex-row items-center">
          <Avatar name="Artificial Intelligence" variant="xs" />
          <div class="flex flex-row items-center text-xs pl-4">
            <span> Uploaded by Axelerate AI </span>
            <span class="text-gray-500 italic pl-2">•</span>
            <span class="text-gray-500 italic pl-2">
              {{ formatedDateMoment(item.updated_at) }}
            </span>
          </div>
        </div>
      </div>

      <!-- Conversations -->
      <div v-if="item.event_type === 'job_candidate_conversation'">
        <div v-if="item.sourceable?.replies?.messages?.length">
          <div
            v-for="(message, msg_idx) in item.sourceable?.replies?.messages"
            :key="message.id"
          >
            <div v-if="msg_idx < 1">
              <div class="flex flex-row items-center py-2">
                <div class="flex items-center justify-center pl-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    style="color: #a0a3bd"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    />
                  </svg>
                  <div class="pl-3">
                    <Avatar
                      :name="`${message.from.first_name} ${message.from.last_name}`"
                      variant="xs"
                    />
                  </div>
                </div>
                <div class="flex flex-row items-center">
                  <div
                    class="flex flex-row items-center text-xs pl-3 space-x-2"
                  >
                    <span>
                      <span class="font-bold text-xs">Email</span>
                      sent by
                      <span class="font-bold text-xs">
                        {{
                          `${message.from.first_name} ${message.from.last_name}`
                        }}
                      </span>
                    </span>
                    <span class="text-gray-500 text-xs italic">•</span>
                    <span class="text-gray-500 text-xs italic">
                      {{ formatedDateMoment(message?.date) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="
                  w-full
                  flex flex-row
                  space-x-3
                  items-center
                  my-1
                  justify-end
                "
              >
                <span
                  class="
                    overflow-ellipsis
                    whitespace-pre-line
                    text-xs text-left
                    w-11/12
                    pl-8
                  "
                >
                  <template v-if="!refsReplies[msg_idx][message.id]">
                    {{ message.snippet }}...
                    <button
                      @click="showFullMessage(msg_idx, message.id, true)"
                      class="text-primary"
                    >
                      View full message
                    </button>
                  </template>
                  <template v-if="refsReplies[msg_idx][message.id]">
                    <span v-html="message.body"> </span>
                    <button
                      @click="showFullMessage(msg_idx, message.id, false)"
                      class="text-primary"
                    >
                      Hide message
                    </button>
                  </template>
                </span>
              </div>
            </div>
            <div v-if="msg_idx > 0 && refsConversations[i][item.id]">
              <div class="flex flex-row items-center py-2">
                <div
                  class="flex items-center justify-center"
                  :class="'pl-' + (3 + msg_idx)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    style="color: #a0a3bd"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    />
                  </svg>
                  <div class="pl-3">
                    <Avatar
                      :name="`${message.from.first_name} ${message.from.last_name}`"
                      variant="xs"
                    />
                  </div>
                </div>
                <div class="flex flex-row items-center">
                  <div
                    class="flex flex-row items-center text-xs pl-3 space-x-2"
                  >
                    <span>
                      <span class="font-bold text-xs">Email</span>
                      sent by
                      <span class="font-bold text-xs">
                        {{
                          `${message.from.first_name} ${message.from.last_name}`
                        }}
                      </span>
                    </span>
                    <span class="text-gray-500 text-xs italic">•</span>
                    <span class="text-gray-500 text-xs italic">
                      {{ formatedDateMoment(message?.date) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="
                  w-full
                  flex flex-row
                  space-x-3
                  items-center
                  my-1
                  justify-end
                "
              >
                <span
                  class="
                    overflow-ellipsis
                    whitespace-pre-line
                    text-xs text-left
                    w-11/12
                    pl-8
                  "
                >
                  <template v-if="!refsReplies[msg_idx][message.id]">
                    {{ message.snippet }}...
                    <button
                      @click="showFullMessage(msg_idx, message.id, true)"
                      class="text-primary"
                    >
                      View full message
                    </button>
                  </template>
                  <template v-if="refsReplies[msg_idx][message.id]">
                    <span v-html="message.body"> </span>
                    <button
                      @click="showFullMessage(msg_idx, message.id, false)"
                      class="text-primary"
                    >
                      Hide message
                    </button>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between mb-3">
            <button
              v-if="
                item.sourceable?.replies?.messages?.length > 1 &&
                !refsConversations[i][item.id]
              "
              @click="moreEmailReplies(i, item.id, true, item)"
              class="text-primary"
            >
              View replies ({{
                item.sourceable?.replies?.messages?.length - 1
              }})
            </button>
            <button
              v-if="refsConversations[i][item.id]"
              @click="moreEmailReplies(i, item.id, false)"
              class="text-primary"
            >
              Hide replies
            </button>
            <button
              v-if="refsConversations[i][item.id]"
              class="bg-primary rounded text-white p-1"
              @click="createReplyComposer(i, item.id, true)"
            >
              Reply
            </button>
          </div>

          <EmailComposer
            v-if="refsConversationsReplyComposer[i][item.id]"
            :threadId="item?.sourceable?.replies?.id"
            :cc="item?.sourceable?.replies?.participants_cc"
            :subject="item?.sourceable?.replies?.subject"
            :body="item.sourceable.replies.messages.slice(-1)[0].body"
          />
        </div>
      </div>

      <!-- job comment -->
      <div v-if="ValidateJobComment(item.event_type)">
        <div class="flex flex-row items-center py-2">
          <div class="flex items-center justify-center px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              style="color: #a0a3bd"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
          </div>
          <div class="flex flex-row items-center">
            <Avatar
              :name="`${item.staff.first_name} ${item.staff.last_name}`"
              variant="xs"
            />
            <div class="flex flex-row items-center text-xs pl-4">
              <span>
                <span class="font-bold pl-1">
                  {{ `${item.staff.first_name} ${item.staff.last_name} ` }}
                </span>
                <span class="pl-1">added a comment</span>
              </span>
              <span class="text-gray-500 italic pl-2">•</span>
              <span class="text-gray-500 italic pl-2">
                {{ formatedDateMoment(item.updated_at) }}
              </span>
            </div>
          </div>
        </div>
        <div class="p-4 bg-purple-50 rounded-lg my-1">
          <span class="overflow-ellipsis whitespace-pre-line text-xs">
            <Highlighter
              class="my-highlight"
              highlightClassName="text-primary-dark font-semibold bg-white"
              :searchWords="
                item?.sourceable?.content?.match(
                  /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                ) || []
              "
              :autoEscape="true"
              :textToHighlight="item.sourceable.content"
            />
          </span>
        </div>
      </div>
      <div
        v-if="ValidateJobComment(item.event_type)"
        class="flex flex-row items-center py-2"
      ></div>
      <div
        v-if="!ValidateJobComment(item.event_type)"
        class="flex flex-row items-center py-2"
      ></div>
      <!-- comment -->
      <div v-if="item.event_type === 'comment_creation'">
        <div class="flex flex-row items-center py-2">
          <div class="flex items-center justify-center pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              style="color: #a0a3bd"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>

            <div class="pl-3">
              <Avatar
                :name="`${item.staff.first_name} ${item.staff.last_name}`"
                variant="xs"
              />
            </div>
          </div>
          <div class="flex flex-row items-center">
            <div class="flex flex-row items-center text-xs pl-3 space-x-2">
              <span class="block">
                <span class="font-bold text-xs">
                  {{ `${item.staff.first_name} ${item.staff.last_name} ` }}
                </span>
                added a comment
              </span>
              <span class="text-gray-500 text-xs italic">•</span>
              <span class="text-gray-500 text-xs italic">
                {{ formatedDateMoment(item.updated_at) }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="w-full flex flex-col space-x-3 items-center justify-end -mt-2"
        >
          <span
            class="
              overflow-ellipsis
              whitespace-pre-line
              text-xs text-left
              w-11/12
              pl-7
              mb-3
              mt-2
            "
          >
            <Highlighter
              class="my-highlight"
              highlightClassName="text-primary-dark font-semibold bg-white"
              :searchWords="
                item?.sourceable?.content?.match(
                  /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                ) || []
              "
              :autoEscape="true"
              :textToHighlight="item.sourceable.content"
            />
          </span>
          <div
            class="p-2 w-11/12 flex-row grid grid-cols-4 gap-2"
            v-if="item?.sourceable.images"
          >
            <div
              class="w-40 mt-4"
              v-for="(image, i) in item?.sourceable.images"
              :key="`timeline-item-${i + 1}`"
            >
              <a :href="image.url" target="_blank" class="flex w-40 h-44">
                <img
                  class="w-40 h-44 object-cover rounded-lg border"
                  :src="image.url"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <!----- job candidate meeting----->
      <div
        v-if="item.event_type === 'job_candidate_meeting_url_sent'"
        class="flex flex-col"
      >
        <div class="flex flex-row items-center py-2">
          <div class="flex items-center justify-center px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-gray-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          </div>
          <div class="flex flex-row items-center">
            <Avatar
              :name="`${item.staff.first_name} ${item.staff.last_name}`"
              variant="xs"
            />
            <div class="flex flex-row items-center text-xs pl-4">
              <span>
                The meeting invite
                <a
                  :href="item.sourceable?.url"
                  class="text-primary hover:underline"
                  target="_blank"
                  >link</a
                >
                for the {{ item.sourceable.title }} was sent to the candidate by
                <span class="font-bold pl-1">{{
                  `${item.staff.first_name} ${item.staff.last_name}`
                }}</span>
              </span>
              <span class="text-gray-500 italic pl-2">•</span>
              <span class="text-gray-500 italic pl-2">
                {{ formatedDateMoment(item.updated_at) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--- end---->
      <div
        v-if="item.event_type === 'job_candidate_meeting_scheduled'"
        class="flex flex-col"
      >
        <div class="flex flex-row items-center py-2">
          <div class="flex items-center justify-center px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-gray-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          </div>
          <div class="flex flex-row items-center">
            <Avatar
              :name="`${item.staff.first_name} ${item.staff.last_name}`"
              variant="xs"
            />
            <div class="flex flex-row items-center text-xs pl-4">
              <span>
                Meeting scheduled by
                <span class="font-bold pl-1">{{
                  `${item.staff.first_name} ${item.staff.last_name}`
                }}</span>
              </span>
              <span class="text-gray-500 italic pl-2">•</span>
              <span class="text-gray-500 italic pl-2">
                {{ formatedDateMoment(item.updated_at) }}
              </span>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col items-center my-1 pl-24 text-xs">
          <div class="grid grid-cols-2 w-full">
            <div class="flex flex-row">
              <span class="text-gray-500">Date</span>
              <span class="ml-4">
                {{ getDate(new Date(item.sourceable.start_time * 1000)) }}
              </span>
            </div>
            <div class="flex flex-row">
              <span class="text-gray-500">Time</span>
              <span class="ml-4">
                {{
                  getTimes(
                    new Date(item.sourceable.start_time * 1000),
                    new Date(item.sourceable.end_time * 1000)
                  )
                }}
              </span>
            </div>
          </div>
          <div class="grid grid-cols-2 w-full mt-2">
            <div class="flex flex-row justify-start items-start">
              <span class="text-gray-500">Duration</span>
              <span class="ml-4">
                {{
                  (item.sourceable.end_time * 1000 -
                    item.sourceable.start_time * 1000) /
                  60000
                }}
                minutes
              </span>
            </div>
            <div class="flex flex-row">
              <span class="text-gray-500">Organizer</span>
              <span class="ml-4">
                {{ `${item.staff.first_name} ${item.staff.last_name}` }}
              </span>
            </div>
          </div>
          <div class="flex flex-row w-full mt-2">
            <span class="text-gray-500">Attendees</span>
            <div class="grid grid-cols-3 gap-2 ml-4">
              <div
                v-for="(attendee, i) in item.sourceable?.attendees?.attendees"
                :key="i"
                class="flex flex-row items-center"
              >
                <Avatar :name="Object.keys(attendee)[0]" variant="xs" />
                <div class="flex flex-col ml-2">
                  <span>{{ Object.keys(attendee)[0] }}</span>
                  <span class="text-gray-500">
                    {{ attendee[Object.keys(attendee)[0]][0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row w-full mt-2">
            <span class="text-gray-500 mr-4">Meeting URL</span>
            <a
              v-if="item.sourceable.meeting_url"
              :href="item.sourceable.meeting_url"
            >
              <span class="text-primary">Meeting link</span>
            </a>
            <button
              v-if="item.sourceable.meeting_url"
              @click="copyUrlToClipboard(item.sourceable.meeting_url)"
              class="text-primary ml-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                />
              </svg>
            </button>
            <button
              v-else
              @click="reload"
              class="flex flex-row items-center text-primary"
            >
              <span class="mr-2">Refresh for meeting url</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="bg-gray-300 h-px w-full" />
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Highlighter from "vue-highlight-words";
import { computed, ref } from "vue";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { defineComponent } from "vue";
import { formatedDateMoment, getInitials } from "../../utils/functions";
import Avatar from "../avatar/index.vue";
import { createToastAxelerate } from "@/utils/toast";
import EmailComposer from "@/components/emailComposer/index.vue";

const TimeLine = defineComponent({
  name: "Timeline",
  components: {
    Avatar,
    Highlighter,
    EmailComposer,
  },
  props: {
    timeline: {
      type: Array,
      required: false,
    },
    reloadTimeline: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emit: ["update:reloadTimeline"],
  setup(props, { emit }) {
    const candidateName = computed(
      () => props.candidate.first_name + " " + props.candidate.last_name
    );
    const refsConversations = ref([]);
    const refsConversationsReplyComposer = ref([]);
    const refsReplies = ref([]);

    props?.timeline
      ?.filter((x) => x.event_type === "job_candidate_conversation")
      .forEach((x) => {
        // Adds all conversation(id) items to this ref
        refsConversations.value.push({ [x.id]: false });
        refsConversationsReplyComposer.value.push({ [x.id]: false });
        x.sourceable.replies.messages.forEach((y) => {
          // Adds all replies(id) items to this ref
          refsReplies.value.push({ [y.id]: false });
        });
      });

    function moreEmailReplies(conversationIdx, conversationId, val) {
      refsConversations.value[conversationIdx][conversationId] = val;
    }

    const buildImageStage = (option) => {
      switch (option) {
        case "Talent Pool":
          return require("@/assets/timeline/arrow.svg");
        case "Screening":
          return require("@/assets/timeline/call.svg");
        case "Tech Interview":
          return require("@/assets/timeline/interview.svg");
        case "Challenge":
          return require("@/assets/timeline/callenge.svg");
        case "2nd Interview":
          return require("@/assets/timeline/interview.svg");
        case "Offer":
          return require("@/assets/timeline/document.svg");
        case "Hiring":
          return require("@/assets/timeline/hired.svg");
        default:
          return require("@/assets/timeline/arrow.svg");
      }
    };

    const ValidateJobComment = (event_type) => {
      let event_allowed = [
        "job_function_comment",
        "job_info_comment",
        "job_details_comment",
        "job_salary_comment",
      ];
      if (event_allowed.includes(event_type)) {
        return true;
      } else {
        return false;
      }
    };

    const parseContent = (content) => {
      const newContent = content ? JSON.parse(content) : {};
      const converter = new QuillDeltaToHtmlConverter(newContent?.ops, {});
      return converter.convert();
    };

    function getTimes(startTime, endTime) {
      const startHour = String(startTime.getHours());
      const startMinutes = String(startTime.getMinutes());
      const endHour = String(endTime.getHours());
      const endMinutes = String(endTime.getMinutes());
      return `${startHour.padStart(2, "0")}:${startMinutes.padStart(
        2,
        "0"
      )} - ${endHour.padStart(2, "0")}:${endMinutes.padStart(2, "0")}`;
    }

    function getDate(date) {
      const original = new Date(date);
      const day = original.getDate();
      const weekday = original.getDay();
      let weekdayText = "";
      const month = original.getMonth();
      let monthText = "";
      const year = original.getFullYear();

      switch (weekday) {
        case 0:
          weekdayText = "Sunday";
          break;
        case 1:
          weekdayText = "Monday";
          break;
        case 2:
          weekdayText = "Tuesday";
          break;
        case 3:
          weekdayText = "Wednesday";
          break;
        case 4:
          weekdayText = "Thursday";
          break;
        case 5:
          weekdayText = "Friday";
          break;
        case 6:
          weekdayText = "Saturday";
          break;
      }

      switch (month) {
        case 0:
          monthText = "Jan";
          break;
        case 1:
          monthText = "Feb";
          break;
        case 2:
          monthText = "Mar";
          break;
        case 3:
          monthText = "Apr";
          break;
        case 4:
          monthText = "May";
          break;
        case 5:
          monthText = "Jun";
          break;
        case 6:
          monthText = "Jul";
          break;
        case 7:
          monthText = "Aug";
          break;
        case 8:
          monthText = "Sep";
          break;
        case 9:
          monthText = "Oct";
          break;
        case 10:
          monthText = "Nov";
          break;
        case 11:
          monthText = "Dec";
          break;
      }

      return `${weekdayText} ${monthText} ${day}, ${year}`;
    }

    function copyUrlToClipboard(url) {
      navigator.clipboard.writeText(url);
      createToastAxelerate("Link copied to clipboard", "", "success");
    }

    function showFullMessage(msgIdx, messageId, val) {
      refsReplies.value[msgIdx][messageId] = val;
    }

    function createReplyComposer(conversationIdx, conversationId, val) {
      refsConversationsReplyComposer.value[conversationIdx][conversationId] =
        val;
    }

    function reload() {
      emit("update:reloadTimeline", true);
    }

    return {
      formatedDateMoment,
      buildImageStage,
      getInitials,
      parseContent,
      getTimes,
      getDate,
      copyUrlToClipboard,
      reload,
      candidateName,
      ValidateJobComment,
      moreEmailReplies,
      refsConversations,
      refsConversationsReplyComposer,
      refsReplies,
      showFullMessage,
      createReplyComposer,
    };
  },
});
export default TimeLine;
</script>
