/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { InfoCandidate } from "./types";
import { JobsAPI } from "../../services/jobs/index";

export const actions: ActionTree<InfoCandidate, RootState> = {
  getCandidateState({ commit }, data) {
    return new Promise((resolve, reject) => {
      JobsAPI.getJobCandidate(data.jobId, data.userId)
        .then((data: any) => {
          resolve(false);
          commit("CANDIDATE_FULLFILED", data.data);
        })
        .catch((err) => {
          reject(true);
          commit("CANDIDATE_REJECTED", err);
        });
    });
  },
};
