
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import TalentEvaluationCard from "../../components/talentEvaluationCard/index.vue";
import Header from "@/components/header/index.vue";
import Button from "../../components/button/index.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useState } from "../../composables/useState";
import Loading from "vue-loading-overlay";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import Paginator from "../../components/paginator/index.vue";
import { createToastAxelerate } from "@/utils/toast";
import BaseModal from "@/components/baseModal/index.vue";
import TextField from "@/components/textField/index.vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import { usePromisedModal } from "@/composables/usePromisedModal";

const TalentEvaluation = defineComponent({
  name: "TalentEvaluation",
  components: {
    TalentEvaluationCard,
    Header,
    Button,
    Loading,
    BaseModal,
    Paginator,
    TextField,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const showModal = ref(false);
    const evaluationsData = computed(
      () => store.state.talentEvaluation.data?.evaluations
    );
    const confirmationModal = usePromisedModal<boolean>();
    const schema = yup.object({
      template_name: yup.string().label("Template name").nullable(),
    });
    const { value: templateName } = useField("template_name");
    const paginator = computed(() => store.state.talentEvaluation.data?.meta);

    onBeforeMount(() => {
      getEvaluations();
    });

    const archiveTemplate = (templateId: string) => {
      TalentEvaluationAPI.archiveTemplate(templateId)
        .then((response) => {
          createToastAxelerate(
            "Archive Talent Evaluation",
            "The talent evaluation has been archived.",
            "success"
          );
          getEvaluations();
        })
        .catch((err) => {
          createToastAxelerate(
            "Archive Talent Evaluation",
            "An error has ocurred",
            "danger"
          );
        });
    };

    const copyTemplate = async (template: any) => {
      showModal.value = true;
      templateName.value = "Copy of " + template.title;

      const confirmed = await confirmationModal.ask();
      if (confirmed) {
        const payload = {
          evaluation: {
            title: templateName.value,
          },
        };

        TalentEvaluationAPI.copyTemplate(template.id, payload)
          .then((response) => {
            createToastAxelerate(
              "Copy Talent Evaluation",
              "The talent evaluation has been copied.",
              "success"
            );
            showModal.value = false;
            getEvaluations();
          })
          .catch((err) => {
            createToastAxelerate(
              "Copy Talent Evaluation",
              "An error has ocurred",
              "danger"
            );
          });
      } else {
        showModal.value = false;
      }
    };

    const getEvaluations = () => {
      setIsLoading(true);
      store
        .dispatch("getAllEvaluations", {
          page: page.value,
          filters: {},
        })
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    };

    const updateHandler = (e: number) => {
      setPage(e);
      getEvaluations();
    };
    return {
      getEvaluations,
      router,
      route,
      evaluationsData,
      isLoading,
      updateHandler,
      page,
      paginator,
      archiveTemplate,
      copyTemplate,
      showModal,
      templateName,
      confirmationModal,
    };
  },
});

export default TalentEvaluation;
