
import { defineComponent } from "vue";

const Tabs = defineComponent({
  name: "Tabs",
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    elements: {
      type: Array,
      default: () => [],
    },
  },
});
export default Tabs;
