/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";

export default class StripesAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getStripes(): Promise<unknown> {
    return this.axiosInstance.get("/stripe_transactions");
  }

  async attachPaymentMethod(job_id: string, source: string): Promise<unknown> {
    return this.axiosInstance.get(
      `/stripe/add_card?platform=sourcing&source=${source}&job_id=${job_id}`
    );
  }
}
export const StripesAPI = new StripesAPIService();
