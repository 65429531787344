
import { defineComponent, ref } from "vue";
import Header from "../../components/header/index.vue";
import Button from "../../components/button/index.vue";
import { useRoute, useRouter } from "vue-router";
import CreateTalentEvaluationForm from "../../components/createTalentEvaluationForm/index.vue";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { createToastAxelerate } from "../../utils/toast";

const CreateTalentEvaluation = defineComponent({
  name: "CreateTalentEvaluation",
  components: {
    Header,
    Button,
    CreateTalentEvaluationForm,
  },
  setup() {
    const router = useRouter();
    const formRef = ref<InstanceType<typeof CreateTalentEvaluationForm>>();
    const route = useRoute();
    const onSubmit = () => formRef.value?.onSubmit();
    const submitData = async () => {
      const res = await formRef.value?.onSubmit();
      const payload = {
        evaluation: {
          evaluatee: 0,
          ...res,
        },
      };
      TalentEvaluationAPI.createEvaluation(payload).then(() => {
        createToastAxelerate(
          "Talent Evaluation",
          "The evaluation has been created",
          "success",
          () => router.push("/talent-evaluation")
        );
      });
    };
    return { router, route, onSubmit, formRef, submitData };
  },
});

export default CreateTalentEvaluation;
