
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";
import Header from "../../components/header/index.vue";
import Button from "../../components/button/index.vue";
import { useRoute, useRouter } from "vue-router";
import GetTalentEvaluationForm from "../../components/getTalentEvaluation/index.vue";

const GetTalentEvaluation = defineComponent({
  name: "GetTalentEvaluation",
  components: {
    Header,
    Button,
    GetTalentEvaluationForm,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route?.query?.id;
    return { router, route, id };
  },
});

export default GetTalentEvaluation;
