
import { defineComponent } from "vue";
import { FieldArray } from "vee-validate";
import TextField from "@/components/textField/index.vue";
import RenderCheckBox from "../renderCheckbox/index.vue";
import CustomDatePicker from "../datePicker/index.vue";
import DropDown from "../selectDropdown/index.vue";

const WorkExperienceArrayField = defineComponent({
  name: "WorkExperienceArrayField",
  props: {
    primaryColor: {
      type: String,
      default: "#7E24ED",
    },
    industries: {
      type: Array,
      required: true,
    },
  },
  components: {
    DropDown,
    FieldArray,
    TextField,
    RenderCheckBox,
    CustomDatePicker,
  },
  setup() {
    return {};
  },
});

export default WorkExperienceArrayField;
