
import { defineComponent } from "vue";

const EmptyState = defineComponent({
  name: "EmptyState",
  props: {
    message: {
      type: String,
      default: "Empty screen",
    },
  },
});

export default EmptyState;
