import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login/index.vue";
import SignUp from "../views/signUp/index.vue";
import RecoveryPassword from "../views/recoveryPassword/index.vue";
import ConfirmAccount from "../views/confirmAccount/index.vue";
import Jobs from "../views/jobs/index.vue";
import ResetPassword from "../views/resetPassword/index.vue";
import AddCandidates from "../views/addCandidates/index.vue";
import Candidates from "../views/candidates/index.vue";
import Settings from "../views/settings/index.vue";
import SuccessPayment from "../views/sucess-payment/index.vue";
import CancelPayment from "../views/cancel-payment/index.vue";
import CompleteAccount from "../views/completeAccount/index.vue";
import EditJob from "../views/editJob/index.vue";
import PublicProfile from "../views/publicProfile/index.vue";
import ConfirmEmail from "../views/confirmEmail/index.vue";
import JobDetails from "../views/jobDetails/index.vue";
import CandidateShow from "../views/internalProfile/index.vue";
import CandidateDetail from "../views/candidateDetail/index.vue";
import NotificationsCenter from "../views/notifications/index.vue";
import TalentEvaluation from "../views/talentEvaluation/index.vue";
import CreateTalentEvaluation from "../views/createTalentEvaluation/index.vue";
import EditTalentEvaluation from "../views/editTalentEvaluation/index.vue";
import GetTalentEvaluation from "../views/getTalentEvaluation/index.vue";
import Pricing from "../views/pricing/index.vue";
import JobBrowser from "../views/jobBrowser/index.vue";
import JobEvents from "../views/jobEvents/index.vue";
import JobCandidateShow from "../views/jobCandidateShow/index.vue";
import CreateCandidate from "../views/createCandidate/index.vue";
import CreateJob from "../components/jobStepper/index.vue";
import ConfirmManagedJob from "../views/confirmManagedJob/index.vue";
import { useCookies } from "vue3-cookies";
import store from "@/store";

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/log-in",
    name: "Login",
    component: Login,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/recovery-password",
    name: "RecoveryPassword",
    component: RecoveryPassword,
  },
  {
    path: "/confirm-account",
    name: "ConfirmAccount",
    component: ConfirmAccount,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/jobs/:id",
    name: "JobCandidates",
    component: JobBrowser,
    children: [
      {
        path: "details",
        name: "JobDetails",
        component: JobDetails,
      },
      {
        path: "job-candidates/:job_candidate_id/:tab?",
        name: "JobCandidateShow",
        component: JobCandidateShow,
      },
      {
        path: "events",
        name: "Job Events",
        component: JobEvents,
      },
    ],
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/job-candidates",
    name: "AddCandidates",
    component: AddCandidates,
  },
  {
    path: "/candidates",
    name: "Candidates",
    component: Candidates,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Pricing,
  },
  {
    path: "/success-payment",
    name: "Sucess Payment",
    component: SuccessPayment,
  },
  {
    path: "/cancel-payment",
    name: "Cancel Payment",
    component: CancelPayment,
  },
  {
    path: "/complete-account",
    name: "Complete Account",
    component: CompleteAccount,
  },
  {
    path: "/edit-job/:id",
    name: "Edit Job",
    component: EditJob,
  },
  {
    path: "/public-profile",
    name: "Public Profile",
    component: PublicProfile,
  },
  {
    path: "/confirm-email",
    name: "Confirm Email",
    component: ConfirmEmail,
  },
  {
    path: "/job-details",
    name: "Job Details",
    component: JobDetails,
  },
  {
    path: "/candidates/:id",
    name: "Candidate Show",
    component: CandidateShow,
  },
  {
    path: "/candidate-detail",
    name: "Candidate Detail",
    component: CandidateDetail,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsCenter,
  },
  {
    path: "/talent-evaluation",
    name: "Talent Evaluation",
    component: TalentEvaluation,
  },
  {
    path: "/create-talent-evaluation",
    name: "Create Talent Evaluation",
    component: CreateTalentEvaluation,
  },
  {
    path: "/edit-talent-evaluation",
    name: "Edit Talent Evaluation",
    component: EditTalentEvaluation,
  },
  {
    path: "/get-talent-evaluation",
    name: "Get Talent Evaluation",
    component: GetTalentEvaluation,
  },
  {
    path: "/create-job",
    name: "CreateJob",
    component: CreateJob,
  },
  {
    path: "/create-candidate/:id?",
    name: "CreateCandidate",
    component: CreateCandidate,
  },
  {
    path: "/jobs/:jobId/managed-job/:confirm",
    name: "Confirm Managed Job",
    component: ConfirmManagedJob,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = cookies.get("token");
  localStorage.setItem("token", token);
  await store.commit("lOGIN_FULLFILED", token);
  const route = router.resolve({ path: to.fullPath });
  const absoluteURL = new URL(route.href, window.location.origin).href;

  const publicPages = [
    "/log-in",
    "/register",
    "/sign-up",
    "/confirm-account",
    "/confirm-email",
    "/recovery-password",
    "/reset-password",
    "/complete-account",
  ];
  const authRequired = !publicPages.includes(to.path);
  //const loggedIn = token; //localStorage.getItem("token");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (token && !authRequired) {
    await store.dispatch("getProfile");
  }
  if (!publicPages.includes(to.path) && !token) {
    window.location.replace(
      `${process.env.VUE_APP_GLOBAL_LOGIN_URL}?redirect_path=${absoluteURL}`
    );
  } else if (publicPages.includes(to.path) && token) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
