import { MutationTree } from "vuex";
import { DraftsInterface } from "./types";

export const mutations: MutationTree<DraftsInterface> = {
  DRAFTS_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  DRAFTS_REJECTED(state, payload: string) {
    state.error = payload;
  },
};
