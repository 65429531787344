
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, ref, watch } from "vue";
import CheckBox from "../simpleCheckbox/index.vue";

import "vue-loading-overlay/dist/vue-loading.css";

const JobWorkFlowStep = defineComponent({
  name: "JobWorkFlowStep",
  emits: ["closeWorkflow", "setChallenge"],
  components: {
    CheckBox,
  },
  props: {
    countSubmit: {
      type: Number,
      default: 0,
    },
    jobData: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const hasChallenge = ref(false);

    onBeforeMount(() => {
      hasChallenge.value = props?.jobData;
    });

    watch(
      () => props.jobData,
      () => {
        hasChallenge.value = props?.jobData;
      }
    );

    watch(
      () => hasChallenge.value,
      () => {
        context.emit("setChallenge", hasChallenge);
      }
    );
    return { hasChallenge };
  },
});

export default JobWorkFlowStep;
