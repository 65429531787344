import { MutationTree } from "vuex";
import { IEmailComposerState } from "./types";

export enum EmailComposerMutations {
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  SET_IS_CALENDAR_LINKED = "SET_IS_CALENDAR_LINKED",
  SET_IS_TEAMS_LINKED = "SET_IS_TEAMS_LINKED",
}

export const mutations: MutationTree<IEmailComposerState> = {};
