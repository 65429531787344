
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref } from "vue";
import AddCandidateGeneralForm from "@/components/addCandidateGeneral/index.vue";
import { useStore } from "vuex";
import Header from "../../components/header/index.vue";
import Loading from "vue-loading-overlay";
import { useRoute } from "vue-router";

const CreateCandidate = defineComponent({
  name: "CreateCandidate",
  components: {
    Header,
    AddCandidateGeneralForm,
    //   Loading,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const route = useRoute();
    const jobId = computed(() => route?.params?.id);
    const typeForm = jobId.value ? "createJobCandidate" : "createCandidate";

    return {
      isLoading,
      typeForm,
    };
  },
});

export default CreateCandidate;
