
import { defineComponent, ref, watchEffect } from "vue";
import Button from "../button/index.vue";
import TextField from "../textField/index.vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import { TimeLineAPI } from "../../services/timeline/index";
import { createToastAxelerate } from "@/utils/toast";

const ModalScheduleMeeting = defineComponent({
  name: "ModalScheduleMeeting",
  inheritAttrs: false,
  components: {
    Button,
    TextField,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      default: "",
    },
    candidateId: {
      type: String,
      default: "",
    },
    meetingId: {
      type: String,
      default: "",
    },
  },
  emits: ["closeModal", "reloadMeetings"],
  setup(props, context) {
    const isLoading = ref(false);
    const schema = yup.object({
      title: yup.string().label("Title").required(),
      meeting_url: yup.string().required(),
    });

    const { value: title } = useField("title");
    const { value: meeting_url } = useField("challenge_url");

    const { values, meta, handleSubmit, errors, validate, setValues } = useForm(
      {
        validationSchema: schema,
        initialValues: {
          title: "",
          meeting_url: "",
        },
      }
    );

    const onSubmit = handleSubmit((values: any) => {
      const meeting_url = {
        title: values.title,
        url: values.meeting_url,
      };
      isLoading.value = true;
      if (props.meetingId) {
        editMeeting(meeting_url);
      } else {
        createMeeting(meeting_url);
      }
    });

    const editMeeting = (payload: any) => {
      TimeLineAPI.updateCandidateMeeting(
        props.jobId,
        props.candidateId,
        props.meetingId,
        payload
      )
        .then(async (response) => {
          await context.emit("reloadMeetings");
          context.emit("closeModal");
          createToastAxelerate(
            "Edit Meeting",
            "The meeting has been edited successfully",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Edit meeting",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const createMeeting = (payload: any) => {
      TimeLineAPI.createCandidateMeetings(
        props.jobId,
        props.candidateId,
        payload
      )
        .then(async (response) => {
          await context.emit("reloadMeetings");
          context.emit("closeModal");
          createToastAxelerate(
            "Schedule Meeting",
            "The meeting has been created successfully",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create meeting",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const closeModal = () => {
      context.emit("closeModal");
    };

    const getMeeting = () => {
      TimeLineAPI.getCandidateMeeting(
        props.jobId,
        props.candidateId,
        props.meetingId
      )
        .then((response: any) => {
          setValues({
            title: response.data.title,
            meeting_url: response.data.url,
          });
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Get Meeting",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    watchEffect(() => {
      if (props.meetingId) {
        getMeeting();
      }
    });

    return {
      closeModal,
      values,
      meta,
      handleSubmit,
      errors,
      validate,
      setValues,
      onSubmit,
      isLoading,
      title,
      meeting_url,
    };
  },
});
export default ModalScheduleMeeting;
