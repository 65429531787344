import { Module } from "vuex";
import { RootState } from "../types";
import { INylasState } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state: INylasState = {
  accessToken: "",
  isCalendarLinked: false,
  isTeamsLinked: false,
} as INylasState;

export const nylas: Module<INylasState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
