
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount } from "vue";
import { useState } from "../../composables/useState";
import { BillingAPI } from "../../services/billing/index";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import Skeleton from "../skeleton/index.vue";
import Tooltip from "../helpTooltip/index.vue";

const PaymentMethods = defineComponent({
  name: "PaymentMethods",
  components: {
    Button,
    Skeleton,
    Tooltip,
  },
  setup() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const sortCards = (cards: any) => {
      const fingerprints = [] as Array<string>;
      for (let i = 0; i < cards.length; i++) {
        if (!fingerprints.includes(cards[i].card?.fingerprint)) {
          fingerprints.push(cards[i].card?.fingerprint);
          setPaymentMethods([...paymentMethods.value, cards[i]]);
        }
      }
    };
    const addCard = async () => {
      BillingAPI.stripeAddCard().then((res: any) => {
        window.location.href = res.data.url;
      });
    };
    const getDefaultPaymentMethod = async () => {
      BillingAPI.getStripeCustomer().then((res: any) => {
        setDefaultPaymentMethod(
          res.data.customer.invoice_settings?.default_payment_method
        );
      });
    };

    const removeCard = async (card: any) => {
      BillingAPI.stripeRemoveCard(card.id).then(async () => {
        await setPaymentMethods(
          paymentMethods.value.filter((payment: any) => payment.id !== card.id)
        );
        await getDefaultPaymentMethod();
        await createToastAxelerate(
          "Payment Methods",
          "The card has been removed",
          "success"
        );
        if (paymentMethods.value.length) {
          setDefaultCard(paymentMethods.value[0]);
        }
      });
    };
    const setDefaultCard = async (card: any) => {
      await setLoading(true);
      BillingAPI.stripeUpdateDefaultCard(card.id).then(async (res: any) => {
        await setDefaultPaymentMethod(res.data.default_payment_method);
        await setPaymentMethods([]);
        await BillingAPI.getPaymentMethods().then(async (res: any) => {
          sortCards(res.data.data);
        });
        await setLoading(false);
        await createToastAxelerate(
          "Payment Methods",
          "The default payment method has been changed",
          "success"
        );
      });
    };
    onBeforeMount(async () => {
      setLoading(true);
      await BillingAPI.getPaymentMethods().then(async (res: any) => {
        setLoading(false);
        await getDefaultPaymentMethod();
        sortCards(res.data.data);
      });
    });
    const buildLogo = (option: string) => {
      switch (option) {
        case "amex":
          return require("../../assets/svgs/amex.svg");
        case "visa":
          return require("../../assets/svgs/visa.svg");
        case "mastercard":
          return require("../../assets/svgs/mastercard.svg");
        case "us_bank_account":
          return require("../../assets/svgs/ach.svg");
        default:
          return require("../../assets/svgs/generic.svg");
      }
    };
    return {
      sortCards,
      paymentMethods,
      setPaymentMethods,
      addCard,
      getDefaultPaymentMethod,
      defaultPaymentMethod,
      removeCard,
      buildLogo,
      setDefaultCard,
      loading,
    };
  },
});

export default PaymentMethods;
